.KCL_gjaldmidlar-page {
    &.main {
        .section-before-charts {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin-bottom: 33px;
            padding-left: 13px;
            padding-right: 13px;

            @include breakpoint-down(mobile) {
                flex-direction: column;
                align-items: start;
            }

            h2 {
                margin-bottom: 0;
            }
        }
    }
}