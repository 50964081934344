/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


.summary-paragraph {
    font-size: 20px;
    line-height: 38px;
    font-weight: 300;
    color: $c-dark-23;

    @include breakpoint-down(mobile) {
        font-size: 18px;
        line-height: 30px;
    }
}

.paragraph,
.paragraph--bold,
.paragraph--italic {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: $c-dark-39;

    @include breakpoint-down(mobile) {
        font-size: 16px;
        line-height: 24px;
    }
}

.paragraph-xsmall {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $c-dark-39;

    @include breakpoint-down(mobile) {
        font-size: 12px;
        line-height: 18px;
    }
}
.paragraph-small,
.paragraph-small--bold,
.paragraph-small--italic {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: $c-dark-39;

    @include breakpoint-down(mobile) {
        font-size: 14px;
        line-height: 22px;
    }
}

.paragraph--bold,
.paragraph-small--bold {
    font-weight: 700 !important;
}

.paragraph--italic,
.paragraph-small--italic {
    font-style: italic;
}

.extra-info {
    font-size: 13px;
    font-style: italic;
    color: $c-grey-4f;
    margin-bottom: 10px;
}

.italic-text {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    font-style: italic;
    color: $c-grey-4f;

    @include breakpoint-down(mobile) {
        font-size: 13px;
        line-height: 15px;
    }
}

.date {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: $c-dark-23;
}

.date--bold {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: $c-dark-23;
}

.news-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
}