.KCL_Fasteignir {
    padding-bottom: 175px;

    .KCL_property-search-form {
        h3 {
            display: flex;
            align-items: center;
        }

        .search-form-description {
            margin: {
                top: 60px;
                bottom: 13px;
            }
        }

        .property-form-description {
            margin-bottom: 50px;
            max-width: 946px;

            p {
                margin-bottom: 0px;
                
                &.bold-section {
                    font-weight: bold;
                }
            }
        }

        .KCL_search-results {
            margin-bottom: -175px;

            .back-link {
                margin-bottom: 20px;
            }
        }

        &.KCL_kaupsamningar,
        &.KCL_leigusamningar {
            .search__box {
                margin-bottom: 40px;

                .extra-info {
                    margin-top: 10px;
                }
            }
        }
    }
}