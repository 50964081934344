.KCL_ViewCompanyReport {
    .display-box__head {
        align-items: flex-start;
    }
    
    .KCL_table {
        .tab-switcher-row {
            padding: 0px;

            td {
                border: none;
                padding: {
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                }

                .tabs-wrapper {
                    // push tabs
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: flex-end;

                    .KCL_tabs {
                        width: 100%;
                        
                        .tabs__body {
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
    }
}