.KCL_gangverd-map {
    .marker {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        opacity: 0.6;
        border: 2px solid $c-white;
    }

    .mapboxgl-popup {
        .mapboxgl-popup-content {
            border-radius: 6px;
            box-shadow: 0 1px 15px rgba(34, 53, 223, 0.3);
        }
        .mapboxgl-popup-close-button {
            top: 5px;
            right: 10px;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .legend-ctrl {
        margin-right: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 5px;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 #888888;

        .range {
            background: linear-gradient(
                rgba(165, 0, 38, 0.6),
                rgba(255, 255, 255, 0.6),
                rgba(49, 54, 149, 0.6)
            );
            text-align: center;
            font-weight: 700;
            padding: 5px;
        }
    }

    .limit-circle {
        fill: $c-black;
        fill-opacity: 0.1;
    }
}