.KCL_gray-accordion {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .accordion__section {
        background: $c-grey-f1;
        padding: 10px 20px;
        border-radius: 6px;
        display: flex;

        &.is-active {
            padding: 20px;
        }

        button {
            appearance: none;
            border: none;
            background: transparent;
            width: 100%;
        }
        
        .accordion__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .section-title {
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                display: flex;
                align-items: flex-start;
                gap: 5px;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .accordion__body {
            text-align: left;
            display: block;
            margin-top: 5px;
        }
    }
}