.KCL_small-column-chart {
    height: 25px;
    display: flex;
    justify-content: flex-end;

    .column {
        width: 7px;
        margin-left: 3px;
        position: relative;

        &.negative {
            background: $c-red-db;
        }
        
        &.positive {
            background: $c-blue-45;
        }
    }
}