.report-aside-component {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    padding-right: 10px;
    gap: 10px;

    .link-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        white-space: nowrap;
        gap: 10px;
    }

    .italic-text {
        text-align: right;
    }
}