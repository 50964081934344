.KCL_hagtolur {
    .icon-and-title {
        display: flex;

        .icon {
            color: $c-blue-1d;
            margin-right: 8px;
        }

        button {
            background: none;
            border: none;
        }
    }

    /* ------------------------------------------------------------ *\
        Chart Box
    \* ------------------------------------------------------------ */

    .chart-box {
        margin-bottom: 10px;
        
        .chart-box__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            p {
                margin: 0px;
            }
        }

        .chart-box__body {
            width: 100%;
        }
    }
}