/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after {
	content: '';
	line-height: 0;
	display: table;
	clear: both;
}
/*  Hidden  */

[hidden],
.hidden {
	display: none;
}

/*  Disabled  */
[disabled],
.disabled {
	cursor: default;
}

.align-right {
	text-align: right;

	.flex {
		justify-content: flex-end;
	}
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

/* Is Success*/
.is-success {
	color: $c-green-03;
}

/* Is Error*/
.is-error {
	color: $c-red-db;
}

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}

@include breakpoint-down(mobile) {
	.hidden-xs {
		display: none !important;
	}
	.visible-xs-block {
		display: block !important;
	}
	.visible-xs-inline {
		display: inline !important;
	}
	.visible-xs-inline-block {
		display: inline-block !important;
	}
}

@include breakpoint-between(mobile,tablet) {
	.hidden-sm {
		display: none !important;
	}
	.visible-sm-block {
		display: block !important;
	}
	.visible-sm-inline {
		display: inline !important;
	}
	.visible-sm-inline-block {
		display: inline-block !important;
	}
}

@include breakpoint-between(tablet,small-desktop) {
	.hidden-md {
		display: none !important;
	}
	.visible-md-block {
		display: block !important;
	}
	.visible-md-inline {
		display: inline !important;
	}
	.visible-md-inline-block {
		display: inline-block !important;
	}
}

@include breakpoint-up(small-desktop) {
	.hidden-lg {
		display: none !important;
	}
	.visible-lg-block {
		display: block !important;
	}
	.visible-lg-inline {
		display: inline !important;
	}
	.visible-lg-inline-block {
		display: inline-block !important;
	}
}
