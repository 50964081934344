ul,
ol {
    padding-left: 0;
}

.KCL_bullet-list {
    padding-left: 20px;

    &.lg {
        .bullet-list-item:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &.xsm {
        .bullet-list-item {
            &.black-marker {
                &::marker {
                    font-size: 14px;
                }
            }
            
        }
    }

    .bullet-list-item {
        &::marker {
            color: $c-teal-6c;
            font-size: 20px;
        }
        
        &.black-marker {
            &::marker {
                color: $c-black;
            }
        }
    }
}

.KCL_check-list {
    .check-list-item {
        display: flex;
        align-items: flex-start;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        .icon-fa {
            margin-right: 13px;
            margin-top: 4px;
        }
    }
}

/* ------------------------------------------------------------ *\
    List Files
\* ------------------------------------------------------------ */

.list-files {
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 13px;
        }
    }
}
