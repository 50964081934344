.KCL_subscription-banner {
    position: absolute;
    left: 0;
    right: 0;
    background: $c-dark-07;
    color: $c-white;
    display: flex;

    &.sm {
        padding: {
            top: 46px;
            bottom: 50px;
            left: 40px;
            right: 40px;
        }
        flex-direction: column;
        position: unset;
        width: calc(100% + 40px);
        margin-left: -20px;

        @include breakpoint-down(mobile) {
            width: calc(100% + 30px);
            margin-left: -15px;
        }

        .head-and-description {
            margin-bottom: 37px;
            
            .head {
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 18px;

                .KCL_logo {
                    margin-right: 33px;

                    a .icons-container svg {
                        height: 24px;
                    }
                }
            }

            .description {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .price-and-button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include breakpoint-down(mobile) {
                flex-direction: column;
                align-items: flex-start;
            }
            
            .KCL_button {
                height: fit-content;
            }

            .price-wrapper {
                display: flex;
                flex-direction: column;

                .price-head {
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 8px;
                }

                .price {
                    font-size: 14px;
                    line-height: 16px;
        
                    .price-value {
                        font-size: 36px;
                        line-height: 42px;
                    }
                }
            }
        }
    }

    &.lg {
        padding: {
            top: 60px;
            bottom: 60px;
            left: 70px;
        }
        flex-direction: row;
        top: 285px;

        &.raised {
            top: 205px;
        }
        

        @include breakpoint-down(mobile) {
            padding: {
                top: 45px;
                bottom: 45px;
                left: 52px;
            }
            flex-direction: column;
        }

        .head-and-description {
            margin-right: 10%;
            width: 52%;

            .head {
                font-size: 32px;
                line-height: 38px;
                margin-bottom: 25px;

                .KCL_logo {
                    a .icons-container svg {
                        height: 30px;
                    }
                }
            }
            
            .description {
                font-size: 20px;
                line-height: 32px;
            }

            @include breakpoint-down(mobile) {
                width: auto;

                .head {
                    font-size: 22px;
                    line-height: 32px;
    
                    .KCL_logo {
                        margin-right: 20px;
    
                        a .icons-container svg {
                            height: 20px;
                        }
                    }
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .price-and-button {
            width: 30%;
            max-width: 210px;
            flex-direction: column;

            @include breakpoint-down(mobile) {
                width: auto;
                max-width: 95%;
            }

            .price-wrapper {
                display: flex;
                flex-direction: column;

                .price-head {
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 8px;
                }

                .price {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 30px;
        
                    .price-value {
                        font-size: 36px;
                        line-height: 42px;
                    }
                }
            }

            .KCL_button {
                width: fit-content;
            }
        }
    }

    .head-and-description {
        @include breakpoint-down(mobile) {
            width: auto;
            margin-right: 0;
            margin-bottom: 30px !important;

            .head {
                .KCL_logo {
                    margin-right: 20px;

                    a .icons-container svg {
                        height: 20px;
                    }
                }
            }
        }

        .head {
            display: flex;
            font-weight: 300;
            align-items: flex-end;

            .KCL_logo {
                margin-right: 30px;

                a .icons-container svg.letters {
                    @include breakpoint-down(small-desktop) {
                        display: block;
                    }
                }
            }
        }
        .description {
            font-weight: 300;
        }
    }

    .price-and-button {
        display: flex;

        @include breakpoint-down(mobile) {
            width: auto;

            .price {
                margin-bottom: 10px;
            }
        }

        .price-head {
            font-weight: 700;
        }
        
        .price {
            font-weight: 500;

            .price-value {
                font-weight: 300;
            }
        }
    }
}