/* ------------------------------------------------------------ *\
    Funds Datepicker
\* ------------------------------------------------------------ */

.funds-datepicker {
    display: flex;
    align-items: center;

    .datepicker {
        max-width: 120px;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}