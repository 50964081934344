.KCL_foreignPepModal {
    column-count: 2;
    column-gap: 20px;
    column-width: auto;

    @include breakpoint-down(tablet) {
        column-count: 1;
    }

    .item_container {
        break-inside: avoid;
        display: flex;
        min-height: 50px;
        align-items: center;
        text-transform: capitalize;
        border-bottom: 1px solid $c-grey-e9;
        padding: 10px 20px 10px 0;

        .item_key {
            width: 50%;
            flex:1;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .item_value {
            width: 50%;
            flex: 1;
            color: $c-dark-23;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;

            span.image_link {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre;
                max-width: 200px;
            }
        }
    }

    .individualRow {
        transition: background 0.3s ease;
        align-items: flex-start;
        &:not(.notExpandable) {
            cursor: pointer;
        }

        // &:not(.expanded) {
        //     background: linear-gradient(180deg, transparent 80%, rgba(1,1,1,0.1) 100%);
        //     &:hover {
        //         background: linear-gradient(180deg, transparent 80%, $c-blue-d4 100%);
        //     }
        // }

        .item_value {
            gap: 10px;
            display: flex;
            flex-direction: column;
        }
    }
}