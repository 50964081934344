.KCL_keldan-markadir-page {
    .desktop-screenshot-section {
        .KCL_slider.slider-screenshots {
            .swiper-container {
                padding: 0;
                
                .swiper-slide {
                    min-width: 300px;
                }
            }
        }
    }

    .slide-image {
        border-radius: 22px !important;
    }

    img.desktopScreenshots {
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
        border-radius: 6px;

        &:hover {
            //hover styles
            cursor: pointer;
            box-shadow: 0px 2px 13px 3px rgba(34, 53, 223, 0.5);
        }

        @include breakpoint-down(small-desktop) {
            width: 100%;
            height: auto;
            min-height: 168px;
        }
    }
}