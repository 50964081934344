.KCL_key-figures {
    .box-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 5px;

        .grey-box {
            background: $c-grey-f1;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            padding: 12px 5px;
            min-height: 93px;

            &.large-box {
                width: 100%;
                margin-bottom: 3px;
            }

            &.small-box {
                width: calc(50% - 1px);
            }

            .grey-box__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: auto;

                .title {
                    font-size: 12px;
                    font-weight: 500;
                    color: $c-dark-39;
                }
                
                .value {
                    font-size: 20px;
                    font-weight: 700;
                    color: $c-dark-23;
                }
            }
        }
    }
}