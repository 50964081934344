.KCL__Watch {
    .KCL_watch-tab {
        padding-bottom: 130px;

        .upper-section {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @include breakpoint-down(small-desktop) {
                flex-direction: column;
            }

            .left-side {
                flex: 2;
                max-width: 945px;
                margin-right: 25px;

                .description {
                    margin-bottom: 30px;
                }

                .KCL_watch-form {
                    .form__body {
                        margin-bottom: 0px;

                        .form__section {
                            display: flex;
                            flex-direction: column;
    
                            .checkboxes {
                                display: flex;
                                flex-wrap: wrap;
                                margin-top: 30px;
                                gap: 20px 45px;
                            }
                            
                        }

                        span.checkBox-alert-msg {
                            position: absolute;
                            margin-top: 10px;
                            font-size: 14px;
                            color: red;
                        }

                        .form__actions {
                            button {
                                margin-left: 0px;
                                margin-top: 40px;
                            }
                        }
                    }
                }
            }

            .KCL_alert {
                &.info {
                    max-width: 530px;
                    flex: 1;

                    @include breakpoint-down(small-desktop) {
                        margin-top: 20px;
                    }
                }
            }
        }


        .middle-section {
            margin: 100px auto 30px auto;
            display: flex;
            justify-content: space-between;

            &.marginTop75px {
                margin-top: 75px;
            }

            h3.table-title {
                margin-bottom: 0px;
            }
        }

        .KCL_table {
            .KCL_checkbox:not(.statusCheckbox) {
                display: flex;
                justify-content: center;
            }

            td i {
                margin-right: 0px;
            }
        }
    }
}