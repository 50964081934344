.KCL_calendar-date {
    text-transform: lowercase;
    float: right;

    .today_date {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: $c-white;
        text-transform: uppercase;
        background: $c-blue-45;
        border-radius: 30px;
        padding: 3px 6px;
    }
}