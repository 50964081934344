.KCL_single-bond-page {
    &.main {
        .KCL_chart {
            &.display-box.lg {
                .KCL_chart-loading {
                    @include breakpoint-down(mobile) {
                        margin-left: 15px;
                    }

                    @include breakpoint-between(mobile-medium, mobile) {
                        margin-top: 39px;
                    }

                    @include breakpoint-up(mobile) {
                        margin-top: 43px;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}