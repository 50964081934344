.KCL_search-results {
    padding-top: 50px;
    padding-bottom: 100px;

    .result_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            margin-bottom: 0;
        }

        .icon-fa {
            font-size: 24px;
            cursor: pointer;
        }
    }
}