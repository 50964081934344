.KCL_GEMMAQ-chart {
    position: relative;
    margin-top: 10px;

    .GEMMAQ-chart__legend {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 14px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 7px 0 27px;

        @include breakpoint-down(medium-desktop) {
            font-size: 12px;
            padding-bottom: 0;
        }

        p {
            margin: 0;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        li {
            width: 50%;
            display: flex;
            flex-direction: column;

            span {
                width: 8px;
                height: 18px;
                border-radius: 2px;
                display: inline-block;
                vertical-align: middle;
            }

            p {
                white-space: break-spaces;
            }
        }

        li:nth-child(odd) {
            text-align: right;
            padding-right: 106px;

            @include breakpoint-down(medium-desktop) {
                padding-right: 80px;
            }

            @include breakpoint-down(mobile) {
                padding-right: 70px;
            }

            p {
                justify-content: flex-end;
            }

            span {
                margin-left: 5px;
            }
        }

        li:nth-child(even) {
            text-align: left;
            padding-left: 106px;

            @include breakpoint-down(medium-desktop) {
                padding-left: 80px;
            }

            @include breakpoint-down(mobile) {
                padding-left: 70px;
            }

            span {
                order: -1;
                margin-right: 5px;
            }
        }

        li:nth-last-child(-n+2) {
            p:not(:last-child) {
                margin-top: auto;
            }
        }
    }
}