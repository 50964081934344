.KCL_opening-hours {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;
    background: $c-white;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.11);
    font-size: 15px;
    line-height: 27px;
    color: $c-dark-39;
    display: flex;
    align-items: flex-start; 

    .icon-fa {
        font-size: 30px;
        color: $c-green-48;
        margin-right: 20px;
    }

    @include breakpoint-down(tablet) {
        margin-top: 0;
    }
    
    @include breakpoint-down(mobile-medium) {
        width: 100%;
        padding: 15px;
    }
}
