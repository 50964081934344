.KCL_vertical-table {
    flex: 1;

    .lineContainer {
        display: flex;
        //min height = 50px but border takes 1px so have to add 1px to account for that
        min-height: 51px;
        border-bottom: $c-grey-e9 solid 1px;
        justify-content: space-between;

        &:hover {
            background: $c-teal-fc;
        }

        th, td {
            flex: 1;
            text-align: left;
            padding: 15px 0;
        }

        td {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;

            div.text-wrapper {
                margin: 5px 0;
            }
        }
    }

    &.sm {
        th, td {
            font-size: 13px;

            @include breakpoint-down(mobile-medium) {
                font-size: 11px;
                overflow: auto;
            }
        }

        thead {
            th {
                font-size: 11px;
            }
        }
    }

    &.lg {
        th, td {
            font-size: 15px;

            @include breakpoint-down(mobile-medium) {
                font-size: 13px;
                overflow: auto;
            }
        }

        thead {
            th {
                font-size: 13px;

                @include breakpoint-down(mobile-medium) {
                    font-size: 11px;
                }
            }
        }
    }

    thead {
        th {
            font-weight: 700;
            text-transform: uppercase;
            color: $c-grey-4f;
            white-space: nowrap;
            position: relative;
            text-align: right;
        }
    }

    .KCL_button {
        height: fit-content;
    }
}

.vertical-table-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;

    @include breakpoint-down(tablet) {
        grid-template-columns: repeat(1, 1fr);
    }
}