.KCL_funds-page {
    &.main {
        .all-funds-table {
            .table__body {
                table {
                    td, th {
                        &:last-child {
                            max-width: 100px;

                            .form-wrapper {
                                input {
                                    min-width: 50px;
                                    max-width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .KCL_chart {
            .table__body {
                max-height: 500px;
                overflow-y: auto;
                
                &::-webkit-scrollbar {
                    width: 20px; /* width of the entire scrollbar */
                }

                &::-webkit-scrollbar-track {
                    background: $c-teal-f2; /* color of the tracking area */
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $c-blue-1d; /* color of the scroll thumb */
                    border-radius: 5px; /* roundness of the scroll thumb */
                    // border: 3px solid $c-blue-1d; /* creates padding around scroll thumb */
                }

                table {
                    thead {
                        tr {
                            display: none;
                        }
                    }

                    tbody {
                        tr {
                            td {
                                div {
                                    &.selected {
                                        font-weight: 500;
                                    }
                                    
                                    span {
                                        display: flex;
                                        font-size: 13px;
                                        line-height: 15px;
                                        align-items: center;
                                        color: #232530;

                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
