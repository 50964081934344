.KCL_company-reports-list {
    .line-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid $c-grey-e9;

        .line-text {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;

            >:last-child {
                margin-left: 5px;
            }
        }
    }
}