.KCL_calendar-item {
    a.link {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        color: $c-blue-1d;
        text-decoration: none;
        @include breakpoint-down(mobile) {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }
        div.link_background {
            background: $c-teal-f2;
            border-radius: 6px;
            padding: 4px 5px;
            span {
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }

        }

        &:hover {
            div.link_background {
                background: $c-blue-d4;
            }
        }
    }

    .time-and-source {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        em {
            color: $c-grey-4f;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 18px;

            @include breakpoint-down(mobile) {
                margin-top: 0;
            }
        }
    }

}