.KCL_nav-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 100%;
    align-items: center;

    // Remove all bullet points from all links and sub-links.
    li {
        list-style: none;
    }

    // Parent element of the main links
    .nav__main {
        display: flex;
        margin-bottom: 0px;
        padding: 0 20px;
        gap: 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        height: fit-content;

        // Mobile: change from row to columns.
        @include breakpoint-down(tablet) {
            flex-direction: column;
            padding: 0px;
            gap: 0;
        }

        .call-to-action-wrapper {
            padding: {
                left: 0px;
                right: 0px;
                bottom: 0px;
            }

            // Mobile: change from row to columns.
            @include breakpoint-down(tablet) {
                display: flex;
                flex-direction: column;
                padding-top: 20px;

                .KCL_button {
                    width: fit-content;
                }
            }
        }

        a + a.KCL_button {
            margin-top: 16px;
        }

        // Main link li elements.
        .nav__main-list {
            position: relative;
            z-index: 6;
            color: $c-white;

            &.highlighted {
                > a,
                > button {
                    color: $c-teal-6c;
                    font-weight: 500;
                }
            }

            @include breakpoint-down(tablet) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                &.is-open {
                    > a,
                    > button {
                        color: $c-teal-6c;
                    }
                
                    > .is-disabled {
                        color: #fff;
                    }
                    
                    .nav__sub {
                        display: block;
                    }
                
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            // The link itself.
            // <a> for links,
            // <button> for dropdown
            > a,
            > button {
                &:not(.KCL_button) {
                    appearance: none;
                    border: none;
                    border-radius: 0;
                    background: transparent;
                    display: inline-flex;
                    align-items: center;
                    padding: 0;
                    font-size: 16px;
                    line-height: 26px;
                    text-decoration: none;
                    transition: color 0.3s;

                    @include breakpoint-down(tablet) {
                        width: 100%;
                        padding: 16px 46px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        line-height: 30px;

                        .icon {
                            margin: 0;

                            svg {
                                scale: 1.1;
                            }
                        }
                    }
                }
            }
            
            &.mobile {
                padding: 0;

                .nav__sub {
                    padding: 0;

                    .nav__sub-col {
                        margin: 0;
                        padding: 0;

                        &:hover {
                            cursor: pointer;
                        }

                        //only for oddnumber
                        &:nth-child(odd) {
                            border-top: 1px solid $c-grey-e9;
                            border-bottom: 1px solid $c-grey-e9;
                        }

                        &.active {
                            .headWrapper {
                                background: $c-teal-f2;

                                .arrow-icon {
                                    transform: rotate(360deg);
                                }
                            }
                        }

                        .headWrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 16px 46px;

                            &:hover {
                                background: $c-teal-f2;
                            }

                            .arrow-icon {
                                transition: transform .3s;
                                transform: rotate(180deg);
                            }

                            .list-heading {
                                color: $c-blue-1d;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                /* 150% */
                                margin: 0;
                                text-transform: initial;
                            }
                        }


                        .list-wrapper {
                            border-top: 1px solid $c-grey-e9;
                            display: flex;
                            padding: 20px 61px;
                            flex-direction: column;
                            align-items: flex-start;
                            align-self: stretch;
                            cursor: default;

                            li:not(:last-child) {
                                margin-bottom: 15px;
                            }
                        }

                        .list-item__body {
                            background: $c-white;
                        }
                    }
                }
            }
            &:hover {
                @include breakpoint-up(tablet) {
                    > a,
                    > button {
                        color: $c-teal-6c;
                    }

                    > .is-disabled {
                        color: #fff;
                    }
                    
                    .nav__sub {
                        opacity: 1;
                        visibility: visible;
                    }

                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .icon {
                font-size: 0;
                margin-left: 6px;
                transition: transform .3s;
            }
        }

        .is-disabled {
            pointer-events: none;
            opacity: 0.6;
        }

        .icon-disabled {
            font-size: 9px;
            margin: 0 6px;
        }
    }

    // The dropdown panel of the main dropdown links.
    .nav__sub {
        display: flex;
        position: absolute;
        top: calc(100% + 12px);
        right: -40px;
        padding: {
            top: 30px;
            left: 0px;
            right: 0px;
            bottom: 40px;
        }
        background: #fff;
        color: $c-blue-1d;
        box-shadow: 0px 2px 30px 3px rgba(34, 53, 223, 0.5);
        border-radius: 6px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        font-size: 16px;
        line-height: 19px;
    
        @include breakpoint-down(tablet) {
            position: static;
            box-shadow: none;
            border-radius: 0;
            flex-direction: column;
            opacity: 1;
            visibility: visible;
            display: none;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        // Lock icon style
        .lock-icon {
            font-size: 9px;
            margin-left: 5px;
        }
    
        // Each column of the dropdown.
        .nav__sub-col {
            padding: 0 40px;
            @include breakpoint-down(tablet) {
                padding: 0 46px;
                min-width: 0;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        
            & + .nav__sub-col {
                border-left: 1px solid $c-grey-db;
        
                @include breakpoint-down(tablet) {
                    border-left: none;
                }
            }

            
            ul {
                padding-left: 0px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                
                .list-heading {
                    color: $c-grey-65;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    user-select: none;
                    margin-bottom: 20px;
                    display:block;
                }
                li {
                    white-space: nowrap;

                    &:not(:last-child) {
                        margin-bottom: 25px;

                        @include breakpoint-down(tablet) {
                            margin-bottom: 20px;
                        }
                    }

                    a {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        gap: 8px;
                        text-decoration: none;
                        font-size: 16px;
                        line-height: 19px;

                        @include breakpoint-down(tablet) {
                            line-height: 24px;
                        }
                        
                        &.is-disabled {
                            pointer-events: none;
                            opacity: 0.7;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0;
                            height: 1px;
                            background-color: $c-blue-22;
                            transition: width 0.3s;
                        }
                
                        &:hover {
                            color: $c-blue-22;
                            
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    // Mobile: Turn menu into whole page!
    @include breakpoint-down(tablet) {
        position: fixed;
        top: 115px;
        left: 0;
        min-height: calc(100vh - 115px);
        overflow-y: auto;
        right: 0;
        background: $c-dark-04;
        z-index: 10;
        transition: opacity .3s, visibility .3s;
        display: none;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        .nav__main {
            width: 100%;
            padding: {
                top: 0px;
                bottom: 0px;
            }
        }
    }
}
