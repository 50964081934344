.KCL_Reikningar {
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: column;

    .shell {
        .section {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
    
            .section__table {
                .main-table {
                    .section__table__sub-table {
                        &:hover {
                            background-color: white;
                        }
                    }
                }

                .section__table__sub-table {
                    .full-width-td {
                        padding: 0px;
                    }

                    .section__table__sub-table__inner {
                        border-left: 1px solid $c-grey-e9;
                        border-right: 1px solid $c-grey-e9;
                        box-sizing: border-box;    
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                        padding: {
                            top: 30px;
                            left: 20px;
                            right: 20px;
                            bottom: 30px;
                        };

                        tr:hover {
                            background: $c-teal-fc;
                        }
                        
                        .KCL_table {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
