.KCL_single-news-page {
    padding-bottom: 67px;

    .section__head--title {
        @include breakpoint-down(mobile) {
            margin-bottom: 20px;
        }
    }

    .button-wrapper {
        display: flex;
        gap: 25px;

        &.mobile {
            display: none;
        }

        @include breakpoint-down(tablet) {
            &.mobile {
                display: flex;
                flex-wrap: wrap;
                margin-top: 50px;
                gap: 15px;
            }

            &.desktop {
                display: none;
            }
        }

    }

    .title-and-source-wrapper {
        max-width: 890px;
        
        .time-and-source {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $c-grey-65;
            display: flex;
            align-items: center;
            gap: 15px;
            margin-top: 15px;
            
            > span {
                width: fit-content;
            }
        }
    }

    .description-wrapper {
        max-width: 886px;
        margin-bottom: 30px;
        text-align: left;
    }
    
    .news-item__entry {
        display: flex;
        flex-direction: column;

        * {
            max-width: 100% !important;
        }

        p {
            margin: 0px;
        }

        img {
            margin-bottom: 30px !important;
            min-width: 250px;
            width: 80%;
            max-width: 700px;
            clear: both;
        }
    }
}