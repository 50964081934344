.KCL_indices-page {
    .middle-section {
        display: flex;
        flex: 3;

        .first-item-middle-section {
            flex: 2;
            overflow-x: hidden;

            @include breakpoint-down(mobile) {
                overflow-x: visible;
            }
        }
    }
}