.KCL_sign-up-page {
    .KCL_price-card {
        p {
            line-height: 26px;
            
            &.price-subtext {
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}