/* ------------------------------------------------------------ *\
    Section
\* ------------------------------------------------------------ */

.section {
    padding: 60px 0;

    .section__head {
        text-align: center;
        padding-bottom: 60px;
        @include breakpoint-down(mobile) {
            padding-bottom: 30px;
        }
        &.left-align {
            text-align: left;
        }
    }
}

/* ------------------------------------------------------------ *\
    Section Primary
\* ------------------------------------------------------------ */

.section--primary {
    padding: {
        top: 60px;
        left: 0px;
        right: 0px;
        bottom: 100px;
    }

    @include breakpoint-down(mobile) {
        padding: 30px 0 60px;
    }

    .section__head {
        margin: 0 auto;

        &:not(.left-align) {
            max-width: 890px;
        }
        
        @include breakpoint-down(mobile) {
            h1 {
                margin-bottom: 14px;
            }
        }
    }
}

/* ------------------------------------------------------------ *\
    Section Gray
\* ------------------------------------------------------------ */

.section--gray {
    background: $c-grey-f8;
}

/* ------------------------------------------------------------ *\
    Section blue
\* ------------------------------------------------------------ */

.section--blue {
    background: $c-blue-gradient;
}

/* ------------------------------------------------------------ *\
    Section White
\* ------------------------------------------------------------ */

.section--white {
    background: $c-white;
}

/* ------------------------------------------------------------ *\
    Section Logos
\* ------------------------------------------------------------ */

.section--logos {
    .section__inner {
        display: flex;
        align-items: flex-start;

        @include breakpoint-down(large-desktop) {
            padding: 0 15px;
        }
        
        @include breakpoint-down(small-desktop) {
            flex-wrap: wrap;
        }

        @include breakpoint-down(mobile) {
            padding: 0;
        }
    }
    
    .section__head {
        text-align: left;
        width: 33.33%;
        padding-right: 55px;

        p:not(:last-child) {
            margin-bottom: 13px;
        }

        @include breakpoint-down(small-desktop) {
            width: 100%;
            padding-right: 0;
        }
    }

    .section__body {
        width: calc(66.67% + 14px);
        display: flex;
        margin-right: -14px;

        @include breakpoint-down(small-desktop) {
            width: calc(100% + 28px);
            margin: 0 -14px;
        }

        @include breakpoint-down(mobile) {
            flex-direction: column;
            margin: 0;
        }
    }
}

/* ------------------------------------------------------------ *\
    Section Contact
\* ------------------------------------------------------------ */

.section--contact {
    padding: 100px 0;

    @include breakpoint-down(mobile) {
        padding: 60px 0;
    }

    .section__inner {
        display: flex;

        @include breakpoint-down(tablet) {
            flex-direction: column;
        }
    }

    .section__head {
        width: 100%;
        text-align: left;
        padding: 0 40px 0 140px;

        @include breakpoint-down(large-desktop) {
            padding: 0 40px 0 15px;
            width: 50%;
        }

        @include breakpoint-down(tablet) {
            width: 100%;
            padding: 0;
            margin-bottom: 40px;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 60px;
        }
    }

    .section__body {
        width: 100%;
        max-width: 668px;
        flex-shrink: 0;

        @include breakpoint-down(large-desktop) {
            max-width: 50%;
            padding-right: 15px;
        }

        @include breakpoint-down(tablet) {
            max-width: 100%;
            padding-right: 0;
        }
    }
}

/* ------------------------------------------------------------ *\
    Section Signup
\* ------------------------------------------------------------ */

.section--signup {
    padding: 0 0 100px;
    max-width: 1392px !important;
    margin: 0 auto;

    @include breakpoint-down(mobile) {
        padding: 0 0 80px;
    }

    .section__head {
        text-align: left;
        margin-bottom: 30px;

        @include breakpoint-down(mobile) {
            text-align: center;
            margin-bottom: 50px;
        }
    }

    .section__body-inner {
        display: flex;
        align-items: flex-start;
        padding-right: 91px;
        
        @include breakpoint-down(medium-desktop) {
            padding-right: 0;
        }

        @include breakpoint-down(mobile) {
            display: block;
        }
    }

    .section__aside {
        order: 2;
        width: 438px;
        flex-shrink: 0;

        @include breakpoint-down(small-desktop) {
            width: 350px;
        }

        @include breakpoint-down(tablet) {
            width: 300px;
        }

        @include breakpoint-down(mobile) {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 40px;
        }
    
        > p {
            text-align: center;
            padding: 17px 0 0;
        }
    }

    .section__content {
        width: 100%;
        padding-right: 26px;

        @include breakpoint-down(medium-desktop) {
            padding-right: 20px;
        }

        @include breakpoint-down(mobile) {
            padding-right: 0;
        }
    }
}

/* ------------------------------------------------------------ *\
    Section Pricing
\* ------------------------------------------------------------ */

.section--pricing {
    padding: 0 0 100px;

    @include breakpoint-down(mobile) {
        padding: 30px 0 80px;
    }

    .section__head {
        max-width: 890px;
        margin: 0 auto 50px;  
        
        @include breakpoint-down(mobile) {
            margin-bottom: 24px;
            padding-bottom: 0;

            h1 {
                margin-bottom: 14px;
            }
        }
    }
    
    .section__inner {
        max-width: 1374px;
        margin: 0 auto;
    }

    .section__group {
        padding: 0 10px;

        @include breakpoint-down(small-desktop) {
            padding: 0;
        }

        > p {
            margin: 0;
            color: $c-grey-4f;
        }

        h2 {
            margin-bottom: 26px;

            @include breakpoint-down(mobile) {
                margin-bottom: 12px;
            }
        }
    }

    .section__group + .section__group {
        margin-top: 100px;

        @include breakpoint-down(mobile) {
            margin-top: 60px;
        }
    }

    .section__footnote {
        padding: 8px 0 0;

        p:not(:last-child) {
            margin-bottom: 18px;

            @include breakpoint-down(mobile) {
                margin-bottom: 7px;
            }
        }
    }
}