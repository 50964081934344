.KCL_news-announcements-page {
    background: $c-grey-f8;
    padding-bottom: 100px;

    @include breakpoint-down(mobile) {
        padding-bottom: 80px;
    }

    .search-box {
        background: $c-grey-f1;
        padding: 30px 20px 28px;
        margin: -22px -20px 20px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .KCL_small-search {
            max-width: none;
        }

        @include breakpoint-down(mobile) {
            padding: 20px 15px;
            margin: -20px -15px 10px;
            border-radius: 0;
        }
    }

    .KCL_table {
        tr {
            &:hover {
                background: $c-teal-f2;
            }
        }
        td {
            > a:not(.KCL_link) {
                text-decoration: none;
                width: 100%;
                height: 100%;
                display: inline-block;

                &:hover {
                    color: inherit;
                }

                &.clickableRow {
                    padding-top: 10px;
                }
            }

            .KCL_link {
                display: inline-block;
                overflow: hidden;
                max-width: 220px;
            }

            &.news-title {
                max-width: 1075px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                > a:not(.KCL_link).clickableRow {
                    padding-top: 5px;
                }
            }

            &.feed-name {
                padding: 12px 5px 7px;
            }
        }
    }

    .attachments-icon {
        font-size: 23px;
        color: $c-blue-45;
        position: relative;
        margin-right: 8px;

        .attachments-nr {
            font-size: 10px;
            font-weight: 700;
            color: $c-white;
            position: absolute;
            left: 6px;
            bottom: 4px;
        }
    }

    .load-more-button-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-top: 20px;
    }
}