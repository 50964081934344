.KCL_property-reports-list {
    margin-top: 50px;
    margin-bottom: 26px;
    height: fit-content;

    &.full {
        width: 100%;
    }

    &:not(.full) {
        width: calc(50% - 10px);
        
        @include breakpoint-down(mobile) {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .buy-current-row {
        display: flex;
        justify-content: space-between;
        height: 43px;
        padding: 0 5px;
        border-bottom: $c-grey-e9 1px solid;
        align-items: center;

        .buy-current-text {
            font-weight: 700;
            font-size: 15px;
            line-height: 30px;
        }
    }
}