/* ------------------------------------------------------------ *\
    Icons
\* ------------------------------------------------------------ */

.fa-file-pdf {
    color: $c-red-db;
}

.fa-file-image,
.fa-image {
    color: $c-blue-45;
}

.fa-file-excel {
    color: $c-green-03;
}

.fa-circle-info {
    color: $c-blue-45;
}

.fa-check {
    color: $c-blue-45;

    &.ok-icon {
        color: $c-green-03;
    }
}

.fa-X {
    color: $c-red-ff7;
}

.fa-mailbox,
.fa-phone {
    color: $c-blue-45;
}

.fa-circle-exclamation {
    color: $c-orange-f5;
}