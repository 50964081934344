.KCL_markhopalistar {
    .section--gray {
        padding-top: 70px;
        padding-bottom: 100px;

        @include breakpoint-down(mobile) {
            padding-top: 50px;
            padding-bottom: 80px;
        }

        h2 {
            margin-bottom: 25px;

            @include breakpoint-down(mobile) {
                margin-bottom: 30px;
            }
        }

        .display-box {
            margin-top: 15px;

            &.card {
                border: none;

                ul {
                    list-style-type: none;

                    li {
                        margin-bottom: 10px;

                        div.list_item-wrapper {
                            display: flex;
                            justify-content: space-between;

                            .marker {
                                display: inline-block;
                                width: 30px;
                                margin-right: 5px;
                                font-weight: 300;
                                font-size: 22px;
                                line-height: 26px;
                                color: $c-dark-23;
                            }

                            .value {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                color: $c-dark-23;
                            }

                            .currency {
                                margin-left: 5px;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 15px;
                                color: $c-grey-65;
                            }
                        }
                    }
                }
            }
        }
    }

    .section__body {
        padding-bottom: 0px;

        .section {
            padding-bottom: 0px;
        }

        h3 {
            margin-bottom: 25px;

            @include breakpoint-down(mobile) {
                margin-bottom: 20px;
            }
        }

        .shell {
            @include breakpoint-down(mobile) {
                padding-left: 15px;
                padding-right: 15px;
            }

            .article-overview {
                padding-top: 0;
                max-width: 100%;

                @include breakpoint-down(mobile) {
                    padding-bottom: 60px;
                }

                .article__body {
                    display: flow-root;

                    ul {
                        li {
                            float: left;
                            justify-content: unset;
                            width: 33%;
        
                            @include breakpoint-down(medium-desktop) {
                                width: 50%;
                            }
                            @include breakpoint-down(mobile) {
                                width: 100%;

                            }
                        }
                    }
                }
            }
        }
    }
}