.KCL_finance-ref-page {
    overflow: unset !important;

    .article-overview {
        padding: 0 0 100px;

        @include breakpoint-down(mobile) {
            padding: 0 0 83px;
        }

        h2 {
            @include breakpoint-down(mobile) {
                margin-bottom: 10px;
            }
        }

        .article__section {
            > p:not(:last-child) {
                margin-bottom: 30px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 18px;
                }
            }

            &:not(:last-child) {
                @include breakpoint-down(mobile) {
                    margin-bottom: 62px;
                }
            }

            ul:not([class]) {
                list-style: none;

                li {
                    display: flex;

                    &:not(:last-child) {
                        margin-bottom: 10px;

                        @include breakpoint-down(mobile) {
                            margin-bottom: 8px;
                        }
                    }
                }

                li:before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $c-teal-6c;
                    margin: 10px 15px 0 0;
                }
            }

            img {
                width: 50%;
                max-width: 1020px;
                height: auto;
                margin: 0 auto 25px;
                display: block;
            }

            .KCL_alert {
                margin-bottom: 30px;
            }

            mjx-container {
                font-size: 100% !important;
            }

            .table {
                table {
                    font-size: 13px;
                    text-align: center;
                    th {
                        font-size: 11px;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: $c-grey-4f;
                        white-space: nowrap;
                        height: 30px;
                        position: relative;
                    }

                    td {
                        height: 36px;
                        border: 1px solid $c-grey-e9;
                        border-top: none;
                        padding: 3px 5px;
                        position: relative;
                    }
                }
            }
        }

        .accordion:not(:last-child) {
            margin-bottom: 30px;
        }

        .formula-boxes {
            @include breakpoint-down(mobile) {
                margin-top: -10px;
            }
        }
    }
}