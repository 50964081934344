.KCL_new_password-page {
    background: $c-blue-gradient;
    // pink-ribbon
    // background: $c-pink-gradient;
    text-align: center;

    .new_password_container {
        max-width: 530px;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
        border-radius: 24px;
        padding: 50px 30px;

        .contactResponse {
            width: 100%;
            display: flex;
            justify-content: center;

            .KCL_alert {
                margin-bottom: 15px;
            }
        }

        h2 {
            margin-bottom: 30px;
        }

        @include breakpoint-down(small-desktop) {
            width: 97%;
            padding: 40px 20px;
        }

        .form__body,
        .form__actions {
            max-width: 304px;
            margin: 0 auto;
        }

        .form__actions {
            display: flex;
            
            .KCL_button {
                flex-grow: 1;
                margin: 0;
            }
        }

        .KCL_new_password-form {
            margin: 0 auto;

            @include breakpoint-down(mobile) {
                width: 257px;
            }

            .form__body {
                margin-bottom: 25px;
            }

            .input-error-message {
                float: left;
            }

            .new-password-input {
                margin-bottom: 25px;
            }
        }

        .note {
            margin-top: 40px;
            margin-bottom: 5px;
        }

        .keldan__links {
            display: flex;
            gap: 20px;
            justify-content: center;
        }
    }
}