.KCL_about-newswire {
    .distribution-block {
        .text-image-block__body {
            width: 100%;
        }
    }

    .text-image-block__content {
        .KCL_label {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            padding: 2px 6px 1px 6px;
            height: 20px;
        }
    }

    .section--contact {
        form {
            max-width: 560px;

            .form__body {
                margin-bottom: 30px;
            }
        }

        .KCL_price-card {
            max-width: 530px;

            .price-card__content {
                margin-top: 16px;

                > p {
                    margin-bottom: 10px;
                }

                .price-card__details {
                    p:last-child {
                        margin-top: 25px;
                    }
                }
            }
        }
    }
}