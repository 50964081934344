.KCL_about-subscription-page {
    .header_content {
        display: flex;
        flex-direction: row;
        padding-top: 70px;
        gap: 35px 20px;

        .um-askrift-title {
            margin-top: 50px;

            @include breakpoint-down(mobile) {
                margin-top: 0px;
            }

            &.desktop {
                display: block;
            }

            &.mobile {
                display: none;
            }
        }

        @include breakpoint-down(mobile-smedium) {
            flex-direction: column;
            padding-top: 40px;

            .um-askrift-title.desktop {
                display: none;
            }

            .um-askrift-title.mobile {
                display: block;
            }
        }
    }

    .section--blue {
        max-height: 390px;

        @include breakpoint-down(mobile-smedium) {
            max-height: 465px;
        }
    }

    .get-subscription {
        display: flex;
        flex-direction: row;
        gap: 25px;
        margin-top: 35px;
        margin-bottom: 67px;

        @include breakpoint-down(mobile-smedium) {
            margin-top: 50px;
            margin-bottom: 40px;
        }
        
        > span {
            max-width: 350px;
            color: $c-dark-23;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }

        .KCL_button {
            height: fit-content;
        }

        @include breakpoint-down(mobile-smedium) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            span {
                text-align: center;
            }
        }
    }

    .text_block-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 40px;
        row-gap: 40px;
        margin-bottom: 100px;

        .text_block {
            max-width: 390px;
            flex: 1 0 33%; /* grow | shrink | basis */
            row-gap: 10px;
            display: flex;
            flex-direction: column;

            @include breakpoint-down(medium-desktop) {
                max-width: unset;
            }

            @include breakpoint-down(mobile) {
                flex: 1 0 50%;
            }
        }

        @include breakpoint-down(mobile-smedium) {
            gap: 30px 0;

            .text_block {
                flex: 1 0 100%;
            }
        }
    }
}