.KCL_company-page {
    background-color: $c-grey-f8;

    .shell {
        padding-bottom: 100px;

        div.body {
            display: flex;

            >:first-child {
                flex: 2;
                height: fit-content;
            }

            >:last-child {
                flex: 1;

                .KCL_ad-rotator {
                    @include breakpoint-up(tablet) {
                        margin-top: 0px;
                    }
                }
            }
        }
    }

    @include breakpoint-down(tablet) {
        .body {
            flex-direction: column;
        }
    }
}