.KCL_company-legal {
    .KCL_label {
        text-transform: uppercase;
    }

    .company-legal-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .company-legal-line-text {
            font-size: 15px;
            line-height: 18px;
            color: $c-dark-23;
        }

        .company-legal-line-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: $c-grey-4f;
            margin-right: 20px;
        }
    }

    &:not(.KCL_company-legal_large) {
        .company-legal-line {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

    &.KCL_company-legal_large {
        .company-legal-line {
            min-height: 50px;
            border-bottom: 1px solid $c-grey-e9;
        }

        .middle-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
}