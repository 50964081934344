.KCL_view-company-compare {
    padding-bottom: 40px;

    .display-box__head-aside {
        display: flex;
        justify-content: center;
        align-items: center;

        .KCL_label {
            margin: {
                top: 0;
                right: 0;
                bottom: 0;
                left: 25px;
            }
        }
    }

    div.graph {
        margin-top: 60px;

        &.pieChart-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr); 
            grid-template-rows: 1fr;

            @include breakpoint-down(small-desktop) {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr); 
                gap: 40px
            }
        }
    }

    tr.thickerText {
        font-weight: 700;
    }
}