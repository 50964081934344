.KCL_company-search {
    position: relative;
    height: 52px;
    margin: 0px;
    max-width: 495px;
    padding-left: 39px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid $c-grey-bd;
    display: flex;

    &.lg {
        height: 52px;
    }

    &.sm {
        height: 30px;
        border-radius: 6px;

        form .field-control .search-field {
            height: 27px;
            font-size: 13px;
        }
    }

    &.KCL_compare-search {
        padding-left: 12px;

        .search__icon {
            transform: rotate(-90deg);
            margin-top: 5px;
            // Size of button.
            width: 39px;
            height: 100%;
            background: none;
            border: 0;
            // Center the magnifying glass icon.
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;

            span {
                // Fix center alignments
                padding-left: 5px;

                // Size of magnifying glass icon.
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
  
    #Company-Search-Form {
        display: flex;
        align-items: center;
        width: 100%;
    }
  
    &.is-search-response-open {
        // Remove the bottom border-radius since the input field is expanded.
        border-radius: 10px 10px 0px 0px;
    }

    &:hover form ::placeholder {
        color: $c-dark-23;
    }

    // The container of:
    // - magnifying glass icon button
    // - search field itself
    // - and the dropdown for search type
    form {
        display: flex;
        justify-content: space-between;
        width: stretch;
    
        ::placeholder {
            color: $c-grey-65;
            opacity: 1;
        }

        // Input field wrapper
        .field-control {
            flex: 1 1 auto;

            // The input field it self.
            .search-field {
                // Border radius
                border-radius: 10px;
                // Size
                height: 39px;
                width: 100%;
                // Font size.
                font-size: 16px;
                line-height: 19px;
                // Style
                border: 0;
                color: $c-dark-23;
        
                &::placeholder {
                    text-overflow: ellipsis;
                }

                &:focus {
                    &::placeholder {
                        color: $c-grey-bd;
                    }
                }
            }
        }
    
        // Search button (magnifying glass icon)
        .search__submit {
            // Align with form.
            position: absolute;
            left: 0;
            top: 0;
            // Size of button.
            width: 39px;
            height: 100%;
            background: none;
            border: 0;
            cursor: pointer;
            // Center the magnifying glass icon.
            display: flex;
            align-items: center;
            justify-content: center;
        
            span {
                // Fix center alignments
                padding-left: 5px;

                // Size of magnifying glass icon.
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &.is-disabled {
        background: $c-grey-f1 ;
        border: $c-grey-bd 1px solid;
        box-shadow: none;

        form ::placeholder {
            color: $c-grey-bd;
            background-color: $c-grey-f1;
            opacity: 1;
        }
    }

    input[disabled] {
        pointer-events: none;
    }
}

.KCL_fyrirtaekja-search {
    .KCL_head-section {
        .section__head--right {
            flex-grow: 2;

            .KCL_ad-rotator {
                margin-top: -76px;
                margin-bottom: -80px;
            }
        }
    }
    
    .section--gray {
        padding-top: 50px;
        padding-bottom: 100px;
    }
}

.KCL_company-samanburdur-tab, .KCL_company-verdmat-tab {
    h3.heading {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }
}

.KCL_company-verdmat-tab .shell {
    .paragraph {
        margin-bottom: calc(1.2em - 10px);
    }

    .KCL_link {
        margin-top: 10px;
        margin-right: 30px;
    }
}