.KCL_construction-profile {
    background-color: $c-grey-f8;

    .shell {
        padding-bottom: 120px;
    }
    
    .vertical-table-wrapper {
        margin-bottom: 60px;
    }
}