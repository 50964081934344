.KCL_search-dropdown {
    position: relative;

    @include breakpoint-down(mobile) {
        display: none;
    }

    // Add divider between search input and dropdown
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 30px;
        background-color: $c-grey-d3;
        transform: translateY(-50%);
    }

    // rotate arrow on dropdown
    &.is-dropdown-open {
        button {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    // The dropdown button that triggers the dropdown functionality.
    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // Size
        width: 170px;
        padding: {
            top: 13px;
            left: 15px;
            right: 16px;
            bottom: 10px;
        }
        // Font
        font-size: 14px;
        line-height: 16px;
        // Style
        background: none;
        border: 0;
        color: $c-dark-23;
        text-align: left;

        @include breakpoint-down(small-desktop) {
            // Shrink dropdown on mobile screens
            height: 35px;
            padding: 16px 25px 16px 10px;
            width: 146px;
            font-size: 11px;
        }
    }
    
    .dropdown__holder {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0 0 5px;
        background: #fff;
        border-radius: 0 0 6px 6px;
        box-shadow: 0px 10px 10px  rgba(34, 53, 223, 0.3);
        z-index: 2;

        &.is-dropdown-open {
            display: block;
        }

        ul {
            list-style: none;
            padding: 0px;

            a {
                display: block;
                font-size: 14px;
                line-height: 16px;
                padding: {
                    top: 8px;
                    left: 15px;
                    right: 15px;
                    bottom: 8px;
                }
                color: $c-blue-1d;
                text-decoration: none;
                transition: background .3s;

                &:hover {
                    background-color: $c-teal-f2;
                }
                
                &.is-disabled {
                    pointer-events: none;
                    opacity: 0.7;
                }

                @include breakpoint-down(small-desktop) {
                    font-size: 12px;
                    padding: 10px;
                }

                .lock-icon {
                    font-size: 9px;
                    margin-left: 5px;
                }
            }
        }
    }
}