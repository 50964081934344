.KCL_alert {
    border: 1px solid;
    border-radius: 6px;
    text-align: left;
    max-width: 946px;

    &.success {
        background: $c-green-ef;
        border-color: $c-green-c8;

        .icon {
            color: $c-green-03;
        }

        .close-button {
            color: $c-green-00;
        }
    }

    &.info {
        background: $c-teal-f2;
        border-color: $c-blue-d4;

        .icon {
            color: $c-blue-22;
        }

        .close-button {
            color: $c-blue-22;
        }
    }

    &.alert {
        background: $c-yellow-fe;
        border-color: $c-yellow-ff;

        .icon {
            color: $c-yellow-ec;
        }

        .close-button {
            color: $c-yellow-ec;
        }
    }

    &.error {
        background: $c-red-ff;
        border-color: $c-red-ffd;

        .icon {
            color: $c-red-db;
        }

        .close-button {
            color: $c-red-db;
        }
    }

    &.sm {
        padding: 20px;
    }

    &.lg {
        padding: 30px;
    }

    &.dismissed {
        display: none;
    }

    .alert-header {
        display: flex;
        justify-content: space-between;

        .icon-and-header {
            display: flex;

            .head-text {
                margin-top: 0;
                margin-left: 15px;
                margin-bottom: 0;
            }

            .icon {
                font-size: 24px;
            }
        }

        .close-button {
            background: none;
            border: none;
            display: flex;
            justify-content: flex-end;

            svg {
                font-size: 20px;
            }
        }
    }

    .main-text {
        margin-left: 39px;
        margin-top: 6px;
    }
}