.KCL_sjodir {
    // Minimize CLS
    min-height: 450px;
    
    .KCL_table > .table__body {
        overflow-y: hidden;
        
        th:not(.fund-name-column) {
            width: 58px;

            &.fund-logo-column,
            &.fund-currency-column,
            &.fund-type-column {
                width: 43px;
            }
        }
        
        .fund-name-column {
            width: -webkit-fill-available;
        }
        
        .KCL_link {
            max-width: 250px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            
            @include breakpoint-down(mobile-medium) {
                max-width: 186px;
            }

            @include breakpoint-between(tablet, small-desktop) {
                max-width: 160px;
            }

            @include breakpoint-between(medium-desktop, medium-large-desktop) {
                max-width: 135px;
            }

            @include breakpoint-between(medium-large-desktop, very-large-desktop) {
                max-width: 180px;
            }
        }
    }
}