.KCL_webservice {
    background: $c-grey-f8;
    
    .body-content {
        padding-top: 70px;
        padding-bottom: 100px;

        @include breakpoint-down(mobile) {
            padding-top: 50px;
            padding-bottom: 80px;
        }

        .webservice-title {
            margin-bottom: 50px;
            text-align: center;

            @include breakpoint-down(mobile) {
                margin-bottom: 30px;
            }
        }

        .card {
            height: 100%;
            border: none;

            &.middle-card {
                p {
                    margin-bottom: 8px;
                }

                ul {
                    margin-bottom: 24px;
                    column-count: 2;
                }
            }
        }

        .row > div {
            // Add space when the cards wrap.
            margin-bottom: 16px;
        }

        .bottom-disclaimer {
            // Spacing
            padding-top: 14px;
        }
    }
}