.KCL_link {
    // Always
    padding: 0;
    background: transparent;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
    transition: color .3s, box-shadow .3s;
    box-shadow: inset 0 -0.5em 0 $c-teal-f2;
    // Initial
    color: $c-blue-1d;

    &:not(.split-lines) {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.font-size--12 {
        font-size: 12px;
        line-height: 16px;

        > .icon-fa {
            font-size: 13px;
            margin-right: 4px;

            &.arrow-icon {
                font-size: 8px;
            }
        }
    }

    &.font-size--13 {
        font-size: 13px;
        line-height: 17px;

        > .icon-fa {
            font-size: 14px;
            margin-right: 5px;

            &.arrow-icon {
                font-size: 9px;
            }
        }
    }

    &.font-size--14 {
        font-size: 14px;
        line-height: 18px;

        > .icon-fa {
            font-size: 15px;
            margin-right: 6px;

            &.arrow-icon {
                font-size: 10px;
            }
        }

        @include breakpoint-down(mobile) {
            font-size: 12px;
            line-height: 16px;

            > .icon-fa {
                font-size: 13px;
                margin-right: 4px;
    
                &.arrow-icon {
                    font-size: 8px;
                }
            }
        }
    }

    &.font-size--15 {
        font-size: 15px;
        line-height: 19px;

        > .icon-fa {
            font-size: 16px;
            margin-right: 7px;

            &.arrow-icon {
                font-size: 11px;
            }
        }

        @include breakpoint-down(mobile) {
            font-size: 13px;
            line-height: 17px;

            > .icon-fa {
                font-size: 14px;
                margin-right: 5px;
    
                &.arrow-icon {
                    font-size: 9px;
                }
            }
        }
    }

    &.font-size--16 {
        font-size: 16px;
        line-height: 20px;

        > .icon-fa {
            font-size: 17px;
            margin-right: 8px;

            &.arrow-icon {
                font-size: 12px;
            }
        }

        @include breakpoint-down(mobile) {
            font-size: 14px;
            line-height: 18px;

            > .icon-fa {
                font-size: 15px;
                margin-right: 6px;
    
                &.arrow-icon {
                    font-size: 10px;
                }
            }
        }
    }

    &.font-size--18 {
        font-size: 18px;
        line-height: 22px;

        > .icon-fa {
            font-size: 19px;
            margin-right: 10px;

            &.arrow-icon {
                font-size: 14px;
            }
        }

        @include breakpoint-down(mobile) {
            font-size: 16px;
            line-height: 20px;

            > .icon-fa {
                font-size: 17px;
                margin-right: 8px;
    
                &.arrow-icon {
                    font-size: 12px;
                }
            }
        }
    }

    &.font-size--20 {
        font-size: 20px;
        line-height: 24px;

        > .icon-fa {
            font-size: 21px;
            margin-right: 10px;

            &.arrow-icon {
                font-size: 16px;
            }
        }

        @include breakpoint-down(mobile) {
            font-size: 18px;
            line-height: 22px;

            > .icon-fa {
                font-size: 19px;
    
                &.arrow-icon {
                    font-size: 4px;
                }
            }
        }
    }

    @include breakpoint-down(mobile-smedium) {
        &.wrap-text-for-mobile {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 72px;
        }
    }

    @include breakpoint-down(mobile-small) {
        &.wrap-text-for-mobile {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 65px;
        }
    }

    // Hover
    &:hover {
        color: $c-blue-22;
        box-shadow: inset 0 calc(-1em - 8px) $c-teal-f2;
    }

    // Focus
    &:focus {
        color: $c-blue-1d;
        box-shadow: inset 0 -0.5em $c-blue-d4;

        // Focus and hover
        &:hover {
            color: $c-blue-22;
            box-shadow: inset 0 calc(-1em - 8px) $c-blue-d4;
        }
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.7;
    }
}