.valuation-page {
    .return-on-equity {
        background: $c-yellow-fe;
    }

    .return-on-debt {
        background: $c-red-ff;
    }

    .fcf {
        background: $c-green-ef;
    }

    .wacc {
        background: $c-teal-f2;
    }

    .valuation,
    .ebitda-valuation {
        background: $c-purple-f7;
    }

    .equity-valuation-ebitda {
        background: $c-grey-f1;
    }

    .equity-valuation-large {
        background: $c-grey-f1;

        &.colored-box {
            padding-top: 45px;
            
            .value {
                font-size: 40px;
                line-height: normal;
            }
            
            .title {
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                color: $c-dark-23;
            }
            
            @include breakpoint-down(mobile) {
                padding-top: 20px;

                .value {
                    font-size: 30px;
                }

                .title {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
    }
    
    .colored-box {
        border-radius: 6px;
        text-align: center;
        height: calc(100% - 38px);
        position: relative;
        padding: {
            top: 20px;
            bottom: 10px;
        }
        
        .value {
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
            width: fit-content;
            margin: {
                right: auto;
                bottom: 0;
                left: auto;
            }

            > span {
                transition: background 2s;
            }
            
            p {
                margin-bottom: 0;
            }
        }
    
        .icon-fa {
            font-size: 15px;
            position: absolute;
            right: 15px;
        }
        
        .value-unit {
            font-size: 15px;
            font-weight: 700;
            margin-left: 5px;
        }
    
        .title {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: $c-grey-4f;
            margin-bottom: 35px;
        }
        
        .formula {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 0;
            
            &.hover-formula {
                display: none;
            }
    
            p {
                white-space: nowrap;
                display: inline-block;

                &:not(:last-child) {
                    margin-bottom: 9px;
                }
            }
            
            .math-icon {
                align-self: center;
                margin: {
                    left: 10px;
                    right: 10px;
                    bottom: 2px;
                }
    
                &.equals-icon {
                    margin-left: 0;
                }

                @include breakpoint-down(mobile) {
                    scale: 0.75;
                    margin: {
                        left: 6px;
                        right: 6px;
                    }
                }
            }
        }
        
        &:hover {
            cursor: pointer;
            box-shadow: 0px 4px 4px 0px $c-blue-22-light;

            .hover-formula {
                display: block;
    
                + .formula {
                    display: none;
                }
            }
        }

        @include breakpoint-down(mobile) {
            padding: {
                top: 10px;
            }

            .value {
                font-size: 18px;
            }

            .value-unit {
                font-size: 14px;
            }

            .title {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 14px;
            }

            .formula {
                font-size: 13px;
                line-height: 15px;
            }
        }
    }

    .head-wrapper {
        order: 1;
    }

    .table-wrapper {
        display: none;
        margin: {
            top: -16px;
            right: 20px;
            left: 20px;
        }
        order: 3;

        &.show {
            display: block;
        }

        &:hover {
            cursor: pointer;
        }

        @include breakpoint-down(small-desktop) {
            margin: {
                right: 0;
                left: 0;
            }
        }
        @include breakpoint-down(mobile) {
            order: 1;
        }

        td:first-child {
            text-wrap: wrap;

            .icon-fa {
                margin-left: 4px;
            }
        }

        td:last-child {
            font-weight: 700;

            > span {
                transition: background 2s;
            }
        }
    }
}