.KCL_heat-map {
    .time-period-buttons {
        button {
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            color: $c-blue-1d;
            padding: 3px 10px 2px 10px;
            background: $c-teal-f2;
            border-radius: 6px;
            border: none;

            &:not(:last-child) {
                margin-right: 5px;
            }

            &:hover {
                background: $c-blue-d4;
            }

            &:focus {
                border: 4px solid $c-blue-d4;
                border-radius: 10px;
            }
        
            &:not(:focus) {
                margin: 4px;
            }

            &.is-active {
                font-weight: 500;
                background: $c-blue-d4;

                &:hover {
                    background: $c-teal-f2;
                }

                &:focus {
                    border: 4px solid $c-teal-f2;
                    border-radius: 10px;
                }
            }

            @include breakpoint-down(mobile-medium) {
                font-size: 11px;
                padding: 2px 6px 1px;
            }
        }
    }
    
    .heat-map-body {
        .heat-map-container {
            display: flex;
            flex-flow: row wrap;
            gap: 8px;

            .heat-map-item {
                font-size: 15px;
                padding: 17px 20px 15px;
                border-radius: 6px;
                width: calc((100% - (2 * 8px)) / 3);
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                &.pos {
                    background: $c-green-c8;
                    color: $c-green-00;
    
                    &.first {
                        background: $c-green-03;
                        color: $c-white;
                    }
                }
    
                &.neutral {
                    background: $c-grey-e9;
                    color: $c-grey-4f;
                }
    
                &.neg {
                    background: $c-red-ffd;
                    color: $c-red-a7;
    
                    &.last {
                        background: $c-red-db;
                        color: $c-white;
                    }
                }
    
                .symbol {
                    font-weight: 700;
                }
                
                .values {
                    font-weight: 400;
                }

                &:hover {
                    color: $c-white;
                    background: $c-blue-22 !important;
                }

                @include breakpoint-between(mobile-medium, medium-desktop) {
                    width: calc((100% - (4 * 4px)) / 2);
                }

                @include breakpoint-down(mobile-medium) {
                    padding: 9px 4px;
                    font-size: 13px;
                    flex-direction: column;
                }
            }
        }
    }
}