.info-col {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .info-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 13px;
            line-height: 15px;
            font-weight: 400;
            text-transform: uppercase;
            color: $c-grey-4f;
            letter-spacing: 0.52px;
            margin-bottom: 8px;

            @include breakpoint-down(mobile) {
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.4px;
                margin-bottom: 10px;
            }
        }

        .value {
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            color: $c-dark-23;

            @include breakpoint-down(mobile) {
                font-size: 13px;
                line-height: 15px;
            }
        }
    }
}