/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

#KCL_Header + div {
	min-height: calc(100vh - 70px - 363px);
	
	> div {
		min-height: calc(100vh - 70px - 363px);
	}
}

html {
	font-size: 62.5%;
}

body {
	min-width: 320px;
	background: $c-white;
	font-family: $ff-sans-serif;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $c-dark-23;

	@include breakpoint-down(small-desktop) {
		font-size: 16px;
	}
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&[href^="tel"] {
		text-decoration: none;
	}
}

h1,
h2,
h3 {
	margin-bottom: 20px;
	color: $c-dark-23;

	@include breakpoint-down(mobile) {
		margin-bottom: 10px;
	}
}

h4,
h5,
h6 {
	margin-bottom: 10px;
	color: $c-dark-23;
}

h1 {
	font-size: 46px;
	line-height: 56px;
	font-weight: 300;

	@include breakpoint-down(mobile) {
		font-size: 34px;
		line-height: 46px;
	}
}

h2 {
	font-size: 38px;
	line-height: 50px;
	font-weight: 300;

	@include breakpoint-down(mobile) {
		font-size: 28px;
		line-height: 40px;
	}
}

h3 {
	font-size: 30px;
	line-height: 42px;
	font-weight: 300;

	@include breakpoint-down(mobile) {
		font-size: 24px;
		line-height: 36px;
	}
}

h4 {
	font-size: 24px;
	line-height: 36px;
	font-weight: 300;

	@include breakpoint-down(mobile) {
		font-size: 20px;
		line-height: 32px;
	}
}

h5 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 300;

	@include breakpoint-down(mobile) {
		font-size: 18px;
		line-height: 28px;
	}
}

h6 {
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;

	@include breakpoint-down(mobile) {
		font-size: 16px;
		line-height: 26px;
	}
}

p,
dl,
hr,
table,
blockquote {
	margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}

.avatar-and-text {
	display: flex;
	align-items: center;

	.KCL_avatar,
	.KCL_flag,
	.KCL_crypto-logo {
		margin-right: 10px;
	}
}

.jc-center {
	justify-content: center;
	display: flex;
}