.frettavakt-item {
    &:nth-child(2) {
        border-top: 1px solid $c-grey-e9;
    }

    border-bottom: 1px solid $c-grey-e9;
    transition: border-color .3s;

    &:hover {
        background: $c-teal-fc;
    }
    
    &:first-child {
        border-top-color: transparent;
    }
    
    .news-item__head {
        height: 48px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        color: $c-grey-4f;
        font-size: 12px;
        padding: 0 10px;
        transition: background .3s;

        &:hover {
            cursor: pointer;
        }
        
        @include breakpoint-down(tablet) {
            padding: 0 6px;
        }

        @include breakpoint-down(mobile) {
            height: 36px;
        }

        .news-item__trigger {
            overflow: hidden;
            appearance: none;
            border: none;
            border-radius: 0;
            background: transparent;
            // Text
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis; 
            font-size: 16px;
            color: $c-dark-23;
            line-height: 1.7;
            font-weight: 500;
            // Size and pos.
            display: inline-block;
            align-items: center;
            margin-right: 4px;
            padding: 5px 0;

            @include breakpoint-down(mobile) {
                font-size: 14px;
                line-height: 22px;
            }

            .news-item__icon {
                color: $c-blue-45;
                margin-right: 6px;
            }

            .news-item__title {
                width: 100%;

                .news-lock-icon {
                    margin-right: 8px;
                    margin-bottom: 2px;
                    font-size: 13px;

                    @include breakpoint-down(mobile) {
                        margin-right: 6px;
                        margin-bottom: 1px;
                        font-size: 11px;
                    }
                }
            }

            @include breakpoint-down(mobile) {
                font-size: 14px;
            }
        }

        
        .news-item__meta {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            font-size: 14px;
            font-weight: 400;

            @include breakpoint-down(mobile) {
                font-size: 10px;
            }
            
            .notify-wrapper {
                flex-grow: 1;

                .news-item__notify {
                    width: 6px;
                    height: 6px;
                    display: block;
                    border-radius: 50%;
                    background: $c-yellow-f8;
                }
            }
            
            .news-item__time {
                margin-left: 10px;
            }

            span {
                display: inline-block;
                min-width: 50px;

                @include breakpoint-down(mobile) {
                    min-width: 40px;
                    &.news-item__time {
                        margin-left: -2px;
                    }
                }
            }

            @include breakpoint-down(mobile) {
                font-size: 10px;
            }
        }
    }

    .news-item__body {
        padding: {
            top: 20px;
            left: 40px;
            right: 40px;
            bottom: 20px;
        }
        font-size: 16px;
        line-height: 26px;
        color: #000;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid $c-grey-e9;
        border-top-color: $c-blue-d5;
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-flow: column nowrap;

        @include breakpoint-down(tablet) {
            padding: 6px;
        }

        @include breakpoint-down(mobile) {
            font-size: 14px;
            line-height: 22px;
        }

        .news-item__entry {
            max-width: 767px;
            * {
                max-width: 100% !important;
            }

            p {
                max-width: 767px;
                margin: 0px;
            }
            
            img {
                margin: 10px !important;
                margin-right: 0px !important;
                width: fit-content;
                max-width: 33% !important;
                float: right;
                clear: both;
            }

            ul, ol {
                padding-left: 20px;
            }
        }

        .news-item__actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            .news-item__labels {
                display: flex;
                gap: 5px;
            }
        }
    }

    &.is-seen {
        .news-item__trigger {
            color: $c-grey-4f;
            font-weight: 400;
        }
    }

    &.is-highlighted {
        .news-item__head {
            background: $c-yellow-fe;
        }
    }

    &.is-open {
        border-top-color: $c-blue-d5;
        border-bottom: transparent;

        .news-item__head {
            background: rgba($c-teal-f2, 0.6);
        }
        
        .news-item__trigger {
            font-weight: 700;
            color: $c-dark-23;
        }

        & + .news-item {
            border-top-color: transparent;
        }
    }
}