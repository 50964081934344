.KCL_head-section {
    padding-bottom: 10px;

    &:not(.with-ad) {
        padding-top: 50px;
        
        @include breakpoint-down(mobile) {
            padding-top: 30px;
        }
    }

    
    .left-align {
        text-align: left;
    }
    
    .right-align {
        text-align: right;
    }
    
    .center-align {
        text-align: center;
    }
    
    .left-to-center-align {
        text-align: left;
        
        @include breakpoint-down(mobile) {
            text-align: center;
        }
    }
    
    .KCL_ad-rotator {
        margin: 26px auto 50px;
        
        @include breakpoint-down(mobile) {
            margin: 15px auto 15px;
        }
    }
            
    .section__head--title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-wrap: wrap;
        gap: 10px 20px;
        position: relative;
        
        @include breakpoint-down(mobile) {
            margin-bottom: 10px;
        }

        &.center-align {
            justify-content: center;
        }

        &:not(.center-align) {
            justify-content: space-between;
        }

        h1 {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }
    }
    
    .section__head--content {
        display: flex;
        gap: 0 80px;
        
        &.center-align {
            justify-content: center;
            
            .section__head--actions {
                justify-content: center;
                align-items: center;
            }
        }

        &.left-to-center-align {
            justify-content: space-between;

            .section__head--actions {
                @include breakpoint-down(mobile) {
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        @include breakpoint-down(tablet) {
            flex-direction: column;
        }
        
        .section__head--main {
            display: flex;
            flex-direction: column;
            max-width: 890px;

            .section__head--summary {
                margin-bottom: 30px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 20px;
                }
            }

            .section__head--description {
                margin-bottom: 30px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 20px;
                }
            }

            .section__head--actions {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 50px;
                gap: 20px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 30px;
                    gap: 10px;
                }

                .KCL_link {
                    &:not(:last-child) {
                        margin-right: 10px;

                        @include breakpoint-down(mobile) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .section__head--right {
            @include breakpoint-down(tablet) {
                margin-bottom: 40px;
            }
        }
    }
}