.KCL_label {
    font-weight: 500;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;

    &.sm {
        font-size: 8px;
        line-height: 12px;
        padding: 2px 4px;
    }

    &.md {
        font-size: 10px;
        line-height: 12px;
        padding: 2px 4px 1px 4px;
    }

    &.lg {
        font-size: 14px;
        line-height: 22px;
        padding: 2px 8px;
    }

    .full-text {
        display: none;
    }

    a {
        text-decoration: none;
    }

    &:hover {
        cursor: pointer;

        span {
            display: none;
        }

        .full-text {
            display: block;
        }
    }

    &.origin-label {
        color: $c-grey-4f;
        background: $c-grey-f1;

        &:hover {
            color: $c-dark-23;

            a {
                color: $c-dark-23;
            }
        }
    }

    &.disclaimer-label {
        color: $c-dark-23;
        background: $c-blue-d4;
        
        &:hover {
            color: $c-blue-1d;
            cursor: pointer;
        }
    }
    
    &.delay-label {
        color: $c-dark-23;
        background: $c-green-94-l;
    }

    &.closed-label {
        color: $c-dark-23;
        background: $c-red-ffd;
    }

    &.beta-label {
        color: $c-white;
        background: $c-blue-45;
    }

    &.new-label {
        color: $c-blue-1d;
        background: $c-yellow-ff;
    }

    &.yes-label {
        color: $c-dark-23;
        background: $c-yellow-f8;
    }

    &.no-label {
        color: $c-dark-23;
        background: $c-grey-d3;
    }

    &.news-release-label {
        color: $c-dark-23;
        background: $c-blue-6c-light;
    }

    @include breakpoint-down(mobile-medium) {
        font-size: 8px;
    }
}