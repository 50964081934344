.KCL_lock-icon {
    background-color: $c-green-94;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    pointer-events: none;
    margin-right: 10px;

    &.noMargin {
        margin: 0;
    }

    .icon-fa {
        color: $c-blue-1d;
        margin: 0 !important;
    }

    &.sm {
        width: 24px;
        height: 24px;

        .icon-fa {
            font-size: 14px;
        }
    }
    
    &.lg {
        width: 34px;
        height: 34px;

        .icon-fa {
            font-size: 16px;
        }
    }
}

.KCL_link {
    .KCL_lock-icon {
        margin-right: 4px;
    }
}