.KCL_bonds-table,
.KCL_shares-table {
    &.hide-symbol {
        .KCL_table {
            th:first-child,
            td:first-child {
                display: none !important;
            }
        }
    }
}