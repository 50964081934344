.KCL_single-share-page {
    &.main {
        @include breakpoint-up(tablet) {
            .custom-cols {
                > div {
                    > div {
                        &:first-child {
                            width: 66.67% !important;
                        }
                        
                        &:nth-child(2) {
                            width: 33.33% !important;
                        }
                    }
                }
            }
        }
    }
}
