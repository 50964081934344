.KCL_excellent-list {
    .article-overview {
        max-width: 100%;
        padding: 30px 0 60px;

        @include breakpoint-down(mobile) {
            padding-bottom: 40px;
        }
    }

    .current-year {
        @include breakpoint-up(tablet) {
            margin-top: -76px;
        }

        @include breakpoint-down(mobile) {
            max-width: 260px;
        }
    }

    .section--start {
        padding-bottom: 0;

        .section__search {
            margin: 0 auto;
            background: $c-grey-f1;
            border-radius: 6px;
            text-align: center;
            padding-top: 35px;
            padding-bottom: 35px;

            @include breakpoint-down(mobile) {
                padding-left: 15px;
                padding-right: 15px;
            }

            .navigation__link {
                margin-bottom: 25px;
                font-size: 20px;

                @include breakpoint-down(mobile) {
                    font-size: 18px;
                    line-height: 37px;
                }

                span {
                    padding-right: 15px;
                    font-weight: 500;
                    color: $c-grey-4f;
                }
                
                a {
                    text-decoration: none;
                    color: $c-blue-1d;
                    display: inline-flex;
                    justify-content: space-evenly;
                }
            }
        }
    }

    .section--companies {
        padding-top: 0;
        margin-top: 60px;

        .section__body {
            margin: 0 auto;

            .alphabet {
                width: 100%;

                h2 {
                    margin-bottom: 0;
                }
                
                .article__body {
                    // display: table;
                    display: flow-root;
    
                    li {
                        float: left;
                        justify-content: unset;
                        width: 33%;

                        a {
                            padding-top: 5px;
                        }

                        @include breakpoint-down(mobile) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}