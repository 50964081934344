.KCL_currency-intraday-chart {
    .intraday-chart__head {
        display: flex;
        color: $c-dark-23; 
        align-items: center;
        margin-bottom: 5px;
        h4 {
            margin-bottom: 0px
        }
        .currency-symbol {
            margin-left: 10px;
            margin-bottom: 0;
            text-align: left;
        }

        .currency-averageRate {
            margin-left: 20px;
            text-align: left;
        }

        .currency-changePer {
            margin-left: 20px;
            font-size: 15px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: right;
        }
    }
}