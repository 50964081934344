.KCL_Hlutabref {
    .avatar-and-text {
        .KCL_tooltip {
            font-size: 11px;
            margin-left: 2px;
            margin-bottom: 4px;
        }
    }
    .KCL_OMX {
        // Minimize CLS
        min-height: 845px;
        
        .KCL_table {
            .table__body {
                overflow-y: hidden;
            }
        }
    }
}