/* ------------------------------------------------------------ *\
    Form
\* ------------------------------------------------------------ */

form {
    .form__body {
        margin-bottom: 40px;
        max-width: 945px;

        @include breakpoint-down(mobile) {
            margin-bottom: 30px;
        }

        .form__row {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
    
            &:not(:last-child) {
                margin-bottom: 25px;
            }
    
            @include breakpoint-down(mobile) {
                flex-direction: column;
            }

            &.checkbox-row {
                margin-top: -10px;
            }
            
            .form__col {
                flex-grow: 1;
                flex-basis: 0;

                &.actions {
                    flex-grow: unset;
                }

                &.datepicker-col {
                    display: flex;
                    gap: 20px;
                    max-width: fit-content;
                }

                .KCL_input {
                    flex-grow: 1;
                    min-width: 170px;
                }
            }
        }
    }
}

/* ------------------------------------------------------------ *\
    Form Signup
\* ------------------------------------------------------------ */

.form--signup,
.form--contact {
    border-radius: 24px;
    background: $c-grey-f1;
    padding: 60px 50px 80px;
    max-width: 807px;

    @include breakpoint-down(mobile) {
        padding: 40px 20px 80px;
    }

    .form__section {
        &:not(:last-child) {
            margin-bottom: 80px;

            @include breakpoint-down(mobile) {
                margin-bottom: 60px;
            }
        }

        .form__row--alt {
            margin-bottom: 30px;

            @include breakpoint-down(mobile) {
                margin-bottom: 36px;
            }
        }
    }

    .form__group:not(:last-child) {
        margin-bottom: 40px;
    }

    .form__row--alt {
        margin-top: -10px;
    }

    .form__icon {
        margin: 10px 10px 0 6px;
        width: 22px;
    }

    .form__group-details {
        background: #fff;
        border-radius: 6px;
        font-size: 16px;
        padding: 22px 20px;
        
        @include breakpoint-down(mobile) {
            font-size: 14px;
            margin-top: -10px;
        }

        dl {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            @include breakpoint-down(mobile) {
                line-height: 1.57;
                align-items: flex-end;
                margin-bottom: 7px;
            }
        }

        dt {
            padding-right: 30px;
        }

        dd {
            flex-shrink: 0;
        }

        .form__group-total {
            font-weight: 700;
            font-size: 18px;
            padding: 14px 0 12px;

            @include breakpoint-down(mobile) {
                font-size: 16px;
                padding: 7px 0 11px;
            }
        }

        p {
            margin-bottom: 0;
        }

        p ~ p {
            margin-top: 12px;
        }
    }

    .form__actions {
        text-align: center;
    }

    @include breakpoint-down(mobile) {
        .form__body {
            margin-bottom: 50px;
        }
    }
}