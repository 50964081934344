.news-item {
    border-top: 1px solid $c-grey-e9;
    transition: border-color .3s;

    &:hover {
        background: $c-teal-fc;
    }
    
    .news-item__head {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        color: $c-grey-4f;
        font-size: 12px;
        padding: 0 5px;
        transition: background .3s;

        &:hover {
            cursor: pointer;
        }
        
        @include breakpoint-down(tablet) {
            padding: 0 6px;
        }

        .news-item__trigger {
            overflow: hidden;
            appearance: none;
            border: none;
            border-radius: 0;
            background: transparent;
            // Text
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis; 
            font-size: 13px;
            color: $c-dark-23;
            line-height: 1.7;
            font-weight: 500;
            // Size and pos.
            display: inline-block;
            align-items: center;
            margin-right: 4px;
            padding: 5px 0;

            .news-item__icon {
                color: $c-blue-45;
                margin-right: 6px;
            }
            
            .news-item__title {
                width: 100%;

                .news-lock-icon {
                    margin-right: 5px;
                    margin-bottom: 1px;
                    font-size: 10px;
                }
            }
        }

        .news-item__meta {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            
            .notify-wrapper {
                flex-grow: 1;

                .news-item__notify {
                    width: 6px;
                    height: 6px;
                    display: block;
                    border-radius: 50%;
                    background: $c-yellow-f8;
                }
            }

            .news-item__source {
                min-width: 50px;
            }
            
            .news-item__time {
                margin-left: 10px;
            }
        }
    }

    .news-item__body {
        padding: 10px;
        font-size: 13px;
        line-height: 1.61;
        color: #000;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid $c-grey-e9;
        border-top-color: $c-blue-d5;
        border-radius: 0 0 6px 6px;

        display: flex;
        flex-flow: column nowrap;

        @include breakpoint-down(tablet) {
            padding: 6px;
        }

        .news-item__entry {
            * {
                max-width: 100% !important;
            }

            p {
                margin: 0px;
            }

            img {
                margin: 10px !important;
                margin-right: 0px !important;
                width: fit-content;
                max-width: 33% !important;
                float: right;
                clear: both;
            }

            ul, ol {
                padding-left: 20px;
            }

            a {
                padding: 0;
                background: transparent;
                text-decoration: none;
                width: fit-content;
                font-weight: 500;
                transition: color .3s, box-shadow .3s;
                box-shadow: inset 0 -0.5em 0 $c-teal-f2;
                // Initial
                color: $c-blue-1d;
                font-size: 13px;
                line-height: 17px;

                // Hover
                &:hover {
                    color: $c-blue-22;
                    box-shadow: inset 0 calc(-1em - 8px) $c-teal-f2;
                }

                // Focus
                &:focus {
                    color: $c-blue-1d;
                    box-shadow: inset 0 -0.5em $c-blue-d4;

                    // Focus and hover
                    &:hover {
                        color: $c-blue-22;
                        box-shadow: inset 0 calc(-1em - 8px) $c-blue-d4;
                    }
                }
            }

            &.trim-body {
                > :not(:first-child) {
                    display: none;
                }
            }
        }

        .news-item__actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            .news-item__labels {
                display: flex;
                gap: 5px;
            }
        }
    }

    &.is-seen {
        .news-item__trigger {
            color: $c-grey-4f;
            font-weight: 400;
        }
    }

    &.is-highlighted {
        .news-item__head {
            background: $c-yellow-fe;
        }
    }

    &.is-open {
        border-top-color: $c-blue-d5;

        .news-item__head {
            background: rgba($c-teal-f2, 0.6);
        }
        
        .news-item__trigger {
            font-weight: 700;
            color: $c-dark-23;
        }

        & + .news-item {
            border-top-color: transparent;
        }
    }
    
    &:first-child {
        border-top-color: transparent;
    }

    &:last-child {
        border-bottom: 1px solid $c-grey-e9;

        &.is-open {
            border-bottom-color: transparent;
        }
    }
}