.KCL_filter {
    position: relative;
    
    .settingsButton {
        background: none;
        border: none;
        font-size: 16px;
        padding: 5px 7px;
        width: 30px;
        height: 30px;
        color: $c-dark-23;

        .circle {
            border: transparent solid 1px;
            position: absolute;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            right: 3px;
            top: 0px;
            color: $c-red-ff7;
        }

        &:hover {
            color: $c-blue-1d;
        }
    }

    .filter-list {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 5;
        background: $c-white;
        box-shadow: 0px 2px 30px 3px $c-blue-22;
        border-radius: 6px;
        padding: 15px 15px 19px 20px;

        &.is-open {
            display: block;
        }

        .list-wrapper {
            max-height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-top: 20px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: $c-teal-f2;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: $c-blue-1d;
                border-radius: 5px;
    
                &:hover {
                   background: $c-blue-04;
                }
            }

            ul {
                padding-left: 0;
                padding-top: 15px;
                list-style: none;
                
                &.list-grid {
                    column-gap: 5px;
                    column-count: 4;

                    @include breakpoint-down(tablet) {
                        column-count: 3;
                    }

                    @include breakpoint-down(mobile) {
                        column-count: 2;
                    }

                    @include breakpoint-down(mobile-medium) {
                        column-count: 1;
                    }
                }

                &.list-flow {
                    display: flex;
                    flex-flow: row wrap;
                    width: 700px;

                    @include breakpoint-down(tablet) {
                        width: 600px;
                    }

                    @include breakpoint-down(mobile) {
                        width: 400px;
                    }

                    @include breakpoint-down(mobile-medium) {
                        width: 250px;
                    }
                }
            }
            
            li {
                break-inside: avoid;
            }
        }

        .closeButton {
            background: none;
            border: none;
            float: right;
            font-size: 16px;
            line-height: 19px;
        }
    }
}