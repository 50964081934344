.KCL_language-picker {
    position: relative;
    color: $c-grey-65;
    border-radius: 6px;
    font-size: 14px;
    width: 37px;
    margin: {
        left: -5px;
        bottom: 20px;
    }

    .drop-select__trigger {
        background: transparent;
        height: 30px;
        font-size: 14px;
        color: inherit;
        width: 100%;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        border: none;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
    }

    .drop-select__overlay {
        position: absolute;
        max-height: 400px;
        overflow-y: auto;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 5;
        border-radius: 0 0 6px 6px;
        background: #fff;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        border-top: none;
        transition: opacity .3s, visibility .3s;

        @include breakpoint-down(mobile) {
            max-height: 300px;
        }
    }

    .drop-select__inner {
        padding: 8px 0;
        display: flex;
        flex-flow: column nowrap;
    }

    .drop-select__item {
        appearance: none;
        width: 100%;
        border: none;
        border-radius: 0;
        background: transparent;
        padding: 5px;
        cursor: pointer;
        color: $c-dark-23;
        transition: color .3s, background .3s;
        text-align: left;
        line-height: 26px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        &:hover,
        &:focus,
        &.is-selected {
            color: $c-blue-22;
            background: $c-teal-f2;
            font-weight: 700;
        }
    }

    &.is-open .drop-select__open,
    .drop-select__open:hover {
        color: $c-blue-22;
        background: transparent;
    }

    &.is-open {
        .drop-select__trigger {
            color: $c-dark-23;
            border-radius: 6px 6px 0 0;
        }

        .drop-select__overlay {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
}