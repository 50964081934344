.KCL_avatar {
    background-color: $c-white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    pointer-events: none;
    overflow: hidden;

    &.grey {
        background-color: $c-grey-f4;
    }

    &:not(.grey) {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    }
    
    &.sm {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    &.md {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    &.lg {
        width: 80px;
        height: 80px;
        margin-right: 20px;

        @include breakpoint-down(mobile) {
            width: 50px;
            height: 50px;
        }
    }

    .missing-avatar {
        display: none;
    }
}
