.KCL_recaptcha {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 78px;
    margin-bottom: 10px;

    > span {
        font-size: 12px;
    }
}