.KCL_property-page {
    background: $c-grey-f8;

    .shell {
        padding-bottom: 120px;

        @media print {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .info-col-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 43px;
        gap: 20px 50px;
    }

    .property-page-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include breakpoint-down(mobile) {
            flex-direction: column;
        }

        .property-owners {
            width: calc(50% - 10px);

            @include breakpoint-down(mobile) {
                width: 100%;
            }
        }

        .property-report-box {
            width: 100%;
            margin-bottom: 60px;

            .report-section:not(:last-child) {
                margin-bottom: 60px;
            }
        }

        .bonds-heading {
            margin-bottom: 20px;
        }
    }

    .gangverd-head {
        padding-bottom: 26px;
        display: flex;
        justify-content: space-between;

        .gangverd-info {
            display: flex;
            max-width: 950px;
            flex-wrap: wrap;
            justify-content: space-between;

            .address {
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
                margin-bottom: 10px;
                width: 100%;
            }

            .gangverd-info-section {
                .info-title {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 400;
                    margin-right: 10px;
                }

                .info-value {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                }
            }
        }

        .print-and-date {
            text-align: right;
            padding-top: 10px;
        }
    }

    .print-chart {
        display: none;
    }

    @media print {
        .chart {
            display: none;
        }

        .print-chart {
            display: block;
        }
        
        .map-section {
            display: none;
        }
    }
}
