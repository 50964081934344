.KCL_accordion {
    margin-bottom: 30px;
    
    .accordion__trigger {
        appearance: none;
        border: none;
        border-radius: 0;
        border-top: 1px solid $c-grey-e9;
        border-bottom: 1px solid transparent;
        background: $c-white;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 19px 15px;
        position: relative;
        transition: background  .3s, border .3s;

        &:hover {
            background: $c-teal-f2;
        }

        @include breakpoint-down(mobile) {
            padding: 15px 9px;
        }
    }

    .accordion__content {
        padding: 25px 50px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 6px;

        @include breakpoint-down(mobile) {
            padding: 16px 14px;
        }
    }

    .accordion__icon {
       color: $c-blue-22;
       width: 16px;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 16px;
       margin-right: 20px;

       @include breakpoint-down(mobile) {
           margin-right: 10px;
       }
    }

    .accordion__section {
        .accordion__body {
            max-height: 0;
            overflow: hidden;
            transition: max-height .3s;
        }

        &:last-child {
            .accordion__trigger {
                border-bottom-color: $c-grey-e9;
            }
        }

        &.is-active {
            position: relative;
            z-index: 1;
            
            .accordion__trigger {
                background: $c-teal-f2;
                border-color: $c-blue-d5;
            }

            .accordion__body {
                max-height: fit-content;
                overflow: visible;
            }

            .accordion__icon:after {
                opacity: 0;
            }
        }
    }
}