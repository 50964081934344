.KCL_GEMMAQ-license,
.KCL_GEMMAQ-publishing {
    .section--contact {
        padding-top: 50px;

        @include breakpoint-down(mobile) {
            padding-top: 30px;
        }

        .section__head {
            @include breakpoint-down(mobile) {
                margin-top: 0;
                margin-bottom: 60px;

                p {
                    margin-bottom: 15px;
                }
            }

            .list-heading {
                margin: 4px 0 16px;

                @include breakpoint-down(mobile) {
                    margin: 0 0 20px;
                }
            }

            .KCL_check-list {
                margin-bottom: 30px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 15px;
                }
            }
        }
        
        .section__body {
            .form {
                padding-top: 50px;

                @include breakpoint-down(mobile) {
                    padding-top: 30px;
                }

                form {
                    padding-top: 5px;

                    .form__body {
                        h3 + .form__row {
                            margin-bottom: 40px;
                        }
                    }
                }
            }
        }
    }
}
