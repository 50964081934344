#Markadir-pricing {
    h2 {
        @include breakpoint-down(mobile) {
            text-align: center;
        }
    }

    .KCL_slider {
        margin-left: -13px;
        margin-right: -13px;

        .swiper-slide {
            padding: 0 13px;
        }

        @include breakpoint-down(mobile) {
            margin: 0 -15px 13px -15px;

            .swiper-container {
                padding: 0 40px;
            }

            .swiper-slide {
                padding: 0 5px 20px;
            }

            .slider__pagination {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .swiper-pagination-bullet {
                width: 11px;
                height: 11px;
                border: 2px solid $c-blue-45;
                background: #fff;
                opacity: 1;
                margin: 0 5px;
            }

            .swiper-pagination-bullet-active {
                width: 15px;
                height: 15px;
                background: $c-blue-45;
            }
        }

        @include breakpoint-up(tablet) {
            max-height: 407px;

            .swiper-container {
                max-height: 407px;

                .swiper-wrapper {
                    max-height: 407px;
                }
            }
        }
    }

    .KCL_price-card {
        .price-card__content {
            .price-card__price {
                margin-bottom: 0;
            }
            h6.description{
                .KCL_tooltip {
                    margin-left: 5px;
                    
                    .display-text.no-underline {
                        color: $c-blue-45;
                    }
                }
                > a {
                    color: $c-blue-d4;
                }
            }

            span.small-disclaimer {
                color: white;
                font-size: 14px;
                font-weight: 500;
                line-height: 26px;
                margin-bottom: 20px;
                display: inline-block;
            }

            .storePriceContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .storePriceWrapper {
                    display: flex;
                    flex-direction: column;

                    .storePriceWrapper__price {
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 47px;

                        .storePriceWrapper__currency {
                            color: white;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            margin-left: 5px;
                        }

                        @include breakpoint-down(mobile) {
                            font-size: 24px;
                            line-height: 24px;

                            .storePriceWrapper__currency {
                                font-size: 12px;
                            }
                        }
                    }

                    .storePriceWrapper__store {
                        strong {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 26px;

                            @include breakpoint-down(mobile) {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
                div.vertical_line {
                    border-left: 1px solid #ffffff;
                    height: 82px;
                    opacity: 0.5;

                    @include breakpoint-down(mobile) {
                        height: 46px;
                    }
                }
            }

            > p {
                margin-bottom: 0;
            }
        }

        .KCL_check-list {
            margin-top: 20px;
        }

        .price-card__actions {
            margin-top: 30px;
            gap: 20px;
            flex-wrap: wrap;
        }
    }

    .price-card-notice {
        margin-top: 16px;

        > p.paragraph-small--italic {
            text-align: end;
            color: $c-grey-65;
        }

        &.appStoreNoticeText {
            margin-bottom: 10px;
            margin-top: 30px;
        }

        @include breakpoint-down(small-desktop) {
            margin-top: 13px;
        }

        @include breakpoint-down(mobile) {
            padding-left: 0;
        }

        p {
            margin: 0;
        }
    }

    .section--appStoreLinks {
        padding-top: 0px;

        .app-download-links {
            margin-bottom: 25px;

            .google-play {
                margin-right: 20px;

                img {
                    height: 45px;
                }
            }

            .app-store {
                img {
                    height: 45px;
                }
            }
        }
    }

    .price-table-section {
        padding-top: 100px;

        .paragraph--italic {
            color: $c-grey-4f;
            margin-bottom: 30px;

            @include breakpoint-down(mobile) {
                margin-top: -10px;
            }
        }

        .price_list {
            .price_list_item {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $c-grey-e9;
                align-items: center;
                padding: 10px 0;

                p {
                    margin: 0;

                    @include breakpoint-down(mobile) {
                        display: grid;
                    }
                }

                .price .per_month {
                    margin-left: 10px;
                }
            }
        }
    }
}