.dagatal-item {
    border-top: 1px solid $c-grey-e9;
    transition: border-color .3s;

    .dagatal-item__trigger {
        appearance: none;
        border: none;
        border-radius: 0;
        background: transparent;
        // height: 36px;
        font-size: 13px;
        color: $c-dark-23;
        line-height: 1.7;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 5px 0;
    }

    .dagatal-item__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $c-grey-4f;
        font-size: 12px;
        padding: 0 5px;
        transition: background .3s;

        &:hover {
            cursor: pointer;
        }

        @include breakpoint-down(tablet) {
            padding: 0 6px;
        }
        
        button {
            text-align: left;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 70%;
            margin-right: 10px;
        }
    }

    .dagatal-item__meta {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;

        .dagatal-item__label {
            margin-right: 10px;
        }

        span {
            min-width: 50px;
        }

        @include breakpoint-down(large-desktop) {
            span {
                display: flex;
            }

            .dagatal-item__time {
                display: flex;
            }
        }

        @include breakpoint-down(medium-desktop) {
            span {
                display: flex;
            }
        }
    }

    .dagatal-item__body {
        padding: 10px;
        font-size: 13px;
        line-height: 1.61;
        color: #000;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid $c-grey-e9;
        border-top-color: $c-blue-d5;
        border-radius: 0 0 6px 6px;

        display: flex;
        flex-flow: column nowrap;

        @include breakpoint-down(tablet) {
            padding: 6px;
        }

        .dagatal-item__entry {
            display: flex;
            flex-flow: column nowrap;
            p {
                margin: 0px;
            }
            img {
                min-width: 250px;
                width: 33%;
            }
        }
    }

    .dagatal-item__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .icon-fa {
            margin-right: 6px;
        }
    }

    .dagatal-item__notify {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $c-yellow-f8;
        display: flex;
        flex-shrink: 0;
        margin: -3px 0 0 5px;
    }

    &.is-seen {
        .dagatal-item__trigger {
            color: $c-grey-4f;
            font-weight: 400;
        }
    }

    &.is-highlighted {
        .dagatal-item__head {
            background: $c-yellow-fe;
        }
    }

    &.is-open {
        border-top-color: $c-blue-d5;

        .dagatal-item__head {
            background: rgba($c-teal-f2, 0.6);
        }
        
        .dagatal-item__trigger {
            font-weight: 700;
            color: $c-dark-23;
        }

        & + .dagatal-item {
            border-top-color: transparent;
        }
    }

    &:first-child {
        border-top-color: transparent;
    }

    &:last-child {
        border-bottom: 1px solid $c-grey-e9;

        &.is-open {
            border-bottom-color: transparent;
        }
    }
}