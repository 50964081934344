.KCL_pricing-page {
    background: $c-grey-f8;
    
    .KCL_head-section {
        padding-bottom: 30px;
    }

    .system-selection {
        display: flex;
        gap: 30px 26px;
        margin-bottom: 40px;

        .display-box {
            flex-grow: 1;
            flex-basis: 0;

            h3 {
                height: 40px;
            }

            p {
                margin-bottom: 30px;
            }
        }

        @include breakpoint-down(mobile) {
            flex-direction: column;
            margin-bottom: 0;

            .display-box {
                p {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .KCL_table.lg {
        font-size: 16px;
        
        @include breakpoint-down(mobile) {
            font-size: 14px;
            line-height: 1.58;
        }
            
        th {
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            color: $c-grey-4f;
            padding: 27px 0;
        
            @include breakpoint-down(mobile) {
                font-size: 11px;
                padding: 12px 6px;
            }
        }
        
        th:not(:first-child) {
            width: 278px;
        
            @include breakpoint-down(small-desktop) {
                width: 150px;
            }
    
            @include breakpoint-down(mobile) {
                width: 88px;
            }
        }
        
        td {
            height: 51px;
        
            @include breakpoint-down(mobile) {
                height: auto;
                padding: 7px 6px;
            }
        }
        
        td:not(:first-child) {
            .flex {
                justify-content: center;
            }
        }
    }

    h2 {
        text-align: left;
    }

    #Markadir-pricing {
        padding-bottom: 40px;

        @include breakpoint-down(mobile) {
            padding-bottom: 20px;
        }
    }
}
