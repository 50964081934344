.KCL_logo {
    height: 35px;

    @include breakpoint-down(tablet) {
        height: 24px;
    }

    a {
        display: inline-block;

        .icons-container {
            display: flex;
            flex-flow: row nowrap;

            svg {
                height: 35px;

                &.letters {
                    margin-left: 11px;
                }
                
                // Shrink logo on mobile
                @include breakpoint-down(tablet) {
                    height: 24px;

                    &.letters {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}