.KCL_news-watcher {
    background: #F1F2F5;
    border-radius: 6px;
    padding: 20px;

    form .form__body {
        margin-bottom: 0px;

        button.primary {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .form__row:not(:last-child) {
            margin-bottom: 0px;
        }

        div.form__col {
            flex-grow: 2;

            &:nth-child(1) {
                flex-grow: 10;
                width: -webkit-fill-available;

                > div:nth-child(2) {
                    margin-bottom: 10px;
                }
            }

            &:nth-child(3) {
                flex-grow: 3;
            }
        }

        div.form__col:nth-child(4) {
            width: fit-content;
            flex-grow: unset;
            white-space: pre;
        }

        // .instructoryText{
        //     margin-top: 8px;
        //     font-weight: 400;
        //     font-size: 14px;
        //     line-height: 22px;
        //     color: $c-dark-39;
        //     padding-left: 15px;
        // }
    }
}