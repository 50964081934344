.KCL_Okutaeki {
    .KCL_alert {
        margin-bottom: 40px;
    }

    .KCL_tabs.lg {
        .tabs-nav {
            margin-bottom: 0px;
        }

        .tabs__body {
            padding-bottom: 150px;

            @include breakpoint-down(mobile) {
                padding-bottom: 80px;
            }
        }
    }
}

.Okutaekjaskra {
    .section__body {
        display: flex;

        .KCL_vehicle-search-form {
            h3 {
                display: flex;
                align-items: center;
            }
            
            .search-form-description {
                margin-bottom: 50px;
            }

            .example-reports-wrapper {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                .KCL_button {
                    width: fit-content;
                }
            }
        }
        
        @include breakpoint-down(mobile) {
            flex-direction: column;
        }
    }
}
