.KCL_individual-profile {
    background: $c-grey-f8;

    .info-col-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 26px;
        margin-bottom: 40px;
        
        .info-col {
            .info-item {
                height: 50px;

                @include breakpoint-down(tablet) {
                    height: auto;
                }
            }
        }
    }

    .display-box {
        margin-bottom: 30px;

        .gildandiSkraningContainer {
            display: flex;
            justify-content: space-between;
            height: 43px;
            padding: 0px 5px;
            border-bottom: $c-grey-e9 1px solid;
            align-items: center;

            .gildandiSkraning_text {
                font-weight: 700;
                font-size: 15px;
                line-height: 30px;
            }
        }
    }

    .KCL_alert {
        margin-top: 30px;

        &.info {
            margin-bottom: 123px;
        }
    }

    &.viewMembership {
        .KCL_button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            margin: 0px auto 10px 0px;
        }
    }

    &.assets-report {
        .shell {
            padding-bottom: 100px;
        }
    }
}
