.KCL_pie-chart {
    display: flex;

    .list-wrapper {
        margin-left: 25px;

        ul > li {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            white-space: pre;

            .listColor {
                margin-right: 10px;
                border-radius: 2px;
            }

            > span {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    @include breakpoint-down(mobile) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
}

div.pieChart-loading {
    display: flex;
}