.KCL_pagination {
    display: flex;
    justify-content: center;
    margin-top: 35px;

    .pagination_angle {
        color: $c-dark-23;
        cursor: pointer;

        &.disabled {
            color: $c-grey-bd;
            cursor: default;
        }
    }

    .pagination_text {
        color: $c-grey-65;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        margin: auto 20px;
        user-select: none;
    }
}

.pagination-wrapper {
    &.next-page-disabled {
        .KCL_pagination {
            .pagination_angle {
                &.next-page {
                    color: $c-grey-bd;
                    cursor: default;
                }
            }
        }
    }
}

.page-size-selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > span {
        margin-right: 8px;
        text-align: left;

        &.extra-info {
            margin-bottom: 0;
        }
    }
}