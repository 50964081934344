.display-box {
    background: $c-white;
    box-shadow: 0px 2px 4px rgba($c-black, 0.1);
    border-radius: 6px;
    position: relative;
    transition: opacity 0.3 ease-out;

    &.sm {
        padding: 15px;

        .display-box__head {
            margin-bottom: 8px;
        }

        .description {
            margin-top: -8px;
        }

        .display-box__footer {
            margin-top: 10px;
        }
    }
    
    &.lg {
        padding: 22px 20px 25px;

        .display-box__head {
            margin-bottom: 10px;
        }

        .description {
            margin-top: -10px;
        }

        .display-box__footer {
            margin-top: 15px;
        }
    }

    &.xl {
        padding: 50px;

        .display-box__head {
            margin-bottom: 20px;
        }

        .description {
            margin-top: -20px;
        }

        .display-box__footer {
            margin-top: 20px;
        }
    }

    &.xxl {
        padding: 50px 95px;

        .display-box__head {
            margin-bottom: 20px;
        }

        .description {
            margin-top: -20px;
        }

        .display-box__footer {
            margin-top: 30px;
        }
    }

    @include breakpoint-down(mobile) {
        &.stretch {
            border-radius: 0;
        }
        
        &.sm, &.lg {
            padding: 15px;
        }

        &.xl {
            &.stretch {
                padding: 20px 15px 25px;
            }
            &:not(.stretch) {
                padding: 30px 20px;
            }
        }

        &.xxl {
            padding: 20px 15px;
        }
    }

    &.gray-background {
        background: $c-grey-f8;
    }

    &.faded:not(:hover) {
        opacity: 0.4;        
    }

    .display-box__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        h4,
        h5 {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            
            a {
                text-decoration: none;

                &:hover {
                    color: $c-dark-23;
                }
            }
        }

        .KCL_label {
            &:not(.lg):not(.disclaimer-label) {
                margin-left: 6px;
                margin-bottom: 14px;
            }

            &.lg {
                margin-left: 10px;
            }
        }
    }

    .display-box__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.has-datepicker {
        z-index: 1;
    }
}

.shell .display-box,
.mini_shell .display-box {
    &.stretch {
        @include breakpoint-down(mobile) {
            width: 100vw !important;
            margin-left: -15px !important;
            margin-right: -15px !important;
        }
    }
}