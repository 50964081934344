.KCL_table {
    &.sm {
        font-size: 13px;

        th {
            font-size: 11px;
            height: 30px;
        }

        td {
            height: 34px;
            padding: 2px 5px;
        }
    }

    &.lg {
        font-size: 15px;

        th {
            font-size: 13px;
            height: 35px;
        }

        td {
            height: 44px;
            padding: 3px 5px;
        }

        @include breakpoint-down(mobile-medium) {
            font-size: 13px;
    
            th {
                font-size: 11px;
                height: 30px;
            }

            td {
                height: 36px;
            }
        }
    }

    &.is-filterable {
        // table-body cannot have overflow-x=auto and overflow-y=visible.
        // browsers make both dimensions auto if 1 is auto.
        // This is the only way to show dropdown and have horizontal mobile scroll.
        .table__body {
            display: flex;
            flex-flow: column nowrap;
            overflow-x: auto;
            overflow-y: visible;
            min-height: 300px;

            table {
                margin-bottom: 0px;

                tr {
                    // Table head with input styling:
                    th {
                        height: 60px !important;

                        .form-wrapper {
                            // Same height, so text above aligns
                            height: 25px;
                            // Put in row
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: flex-start;
                            // Pad like text above it
                            padding: {
                                left: 5px;
                                right: 5px;
                            }

                            // Align same way as text.
                            &.left {
                                justify-content: flex-start;
                            }

                            &.center {
                                justify-content: center;
                            }

                            &.right {
                                justify-content: flex-end;
                            }

                            // Change the different inputs.
                            input, select {
                                min-width: 100px;
                                height: 25px;
                            }

                            input {
                                max-width: 200px;
                            }

                            select {
                                max-width: 120px;
                            }

                            .number-range-wrapper {
                                display: flex;
                                flex-flow: row nowrap;

                                .KCL_input {
                                    text-transform: capitalize;
                                    width: 50%;
                                    max-width: 50%;
                                    min-width: 50%;

                                    input {
                                        min-width: 50px;
                                        max-width: 100px;
                                    }

                                    &.is-focused {
                                        label {
                                            font-size: 10px;
                                        }
                                    }

                                    label {
                                        font-size: 13px;
                                    }

                                    &:first-child {
                                        input {
                                            border-top-right-radius: 0px;
                                            border-bottom-right-radius: 0px;
                                        }
                                    }

                                    &:nth-child(2) {
                                        input {
                                            border-top-left-radius: 0px;
                                            border-bottom-left-radius: 0px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .table-shadow {
                flex-grow: 1;
                background-color: #f8f8f8;
            }
        }

    }
    
    tr:hover {
        background: $c-teal-fc;
    }

    tr.table__opened-row {
        background-color: $c-teal-f2;
    }

    tr.is-special-row {
        background-color: $c-green-ef;
    }

    tr.extra-border-bottom {
        border-top: 2px solid $c-grey-d3;
    }

    th {
        font-weight: 700;
        text-transform: uppercase;
        color: $c-grey-4f;
        white-space: nowrap;
        position: relative;
        border-bottom: 1px solid $c-grey-d3;

        &:before {
            content: '';
            height: 9px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, #F4F4F5 0%, rgba(244, 244, 245, 0) 27.78%);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }
        
        &.is-sortable {
            &:hover {
                cursor: pointer;
                color: $c-blue-1d;
            }
        }

        button {
            appearance: none;
            border: none;
            font: inherit;
            border-radius: 0;
            background: transparent;
        }

        
        .title-span {
            position: relative;
            display: block;
            padding: 5px;
            
            .text-span {
                display: inline-block;
                position: relative;
                width: min-content;

                .caret-span {
                    position: absolute;
                    $caret-width: 8px;
                    width: $caret-width;
                    height: 12px;
                    top: -10px;
                    left: calc(50% - $caret-width / 2);
                }
            }
        }
    }

    th.align-right + th.align-left {
        padding-left: 5px;
    }

    //TO MAKE THE TABLE ON ABOUT/KELDANAPP SIMILAR TO THE TABLE ON HOME/PRICING
    &.pricingTableHeaderStyle {
        thead {
            th {
                &::before {
                    background: none;
                }
            }

            .title-span {
                .text-span {
                    .price-table-category {
                        font-size: 16px;
                    }
                }
            }
            
        }

        thead:not(:nth-child(1)) {
            th {
                padding-top: 0px !important;
            }

            .title-span {
                padding-bottom: 18px;
            }
        }
    }

    td {
        border-bottom: 1px solid $c-grey-e9;
        position: relative;

        @include breakpoint-down(mobile) {
            white-space: nowrap;
        }

        .table__openSubTable-button {
            color: $c-blue-45;
            display: flex;
            align-items: center;

            .loading-spinner {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 10px;
            }

            .fa-icon {
                margin-right: 10px;

                &:hover {
                    cursor: pointer;
                }
            }

            .fa-circle-minus {
                color: $c-blue-1d;
            }

            .table__openSubTable-button-text {
                font-size: 15px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 10px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        i {
            margin-right: 6px;
        }

        > span {
            @include breakpoint-down(large-desktop) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }

            @include breakpoint-down(mobile-medium) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }

    .section__table__sub-table {
        + tr {
            td {
                height: 81px;
                padding-top: 40px;

                @include breakpoint-down(mobile) {
                    height: 61px;
                    padding-top: 20px;
                }
            }
        }

        td.full-width-td {
            padding: 0 1px 1px 1px;
            border-bottom: 0px;

            .section__table__sub-table__inner {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
                padding: 20px 70px;
                display: flex;
                column-gap: 45px;

                .info-col {
                    .info-item {
                        row-gap: 13px;

                        @include breakpoint-down(mobile) {
                            row-gap: 10px;
                        }
                    }
                }
            }
        }
    }

    td.align-right + td.align-left {
        padding-left: 10px;
    }

    .is-new {
        .flex {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                max-width: 66px;
            }

            > span,
            > a {
                position: relative;
            }
        }

        &.is-success .flex:before {
            background: $c-green-ef;
            opacity: 0.5;
        }

        &.is-error .flex:before {
            background: $c-red-ff;
        }
    }

    .icon-wrap {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $c-grey-f4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        flex-shrink: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    .table__tabs {
        margin-bottom: 12px;

        li {
            white-space: nowrap;
        }
    }

    .field {
        max-width: 120px;

        @include breakpoint-down(mobile) {
            max-width: 80px;
        }
    }

    .field__input {
        text-align: right;
    }

    .table__body {
        max-width: 100%;
        overflow-x: auto;
        padding-bottom: 1px;
    }
}

.table--no-bg-icon {
    .icon-wrap {
        background: transparent;
    }
}

/* ------------------------------------------------------------ *\
    Table Box Expandable
\* ------------------------------------------------------------ */

.KCL_table--expandable {
    .trigger-holder {
        td {
            border-bottom: transparent;
        }

        &:hover {
            background: transparent;
        }
    }

    .table__expand-trigger {
        appearance: none;
        border: none;
        border-radius: 4px;
        background: $c-grey-f1;
        font-size: 13px;
        font-weight: 500;
        height: 36px;
        margin: -1px -5px;
        width: calc(100% + 10px);
        text-align: left;
        display: flex;
        align-items: center;
        transition: background .3s;
        
        i {
            font-size: 16px;
            margin: 0 15px 0 10px;
        }

        &:hover {
            background: $c-blue-d5;
        }
    }
}

/* ------------------------------------------------------------ *\
    Tandem Table
\* ------------------------------------------------------------ */

.KCL_table--tandem {
    &.sm {
        thead:not(:first-child) {
            th {
                height: 40px;

                .title-span {
                    bottom: -6px;
                }
            }
        }
    }

    &.lg {
        thead:not(:first-child) {
            th {
                height: 55px;

                .title-span {
                    bottom: -10px;
                }
            }
        }
    }
    
    tbody:last-child {
        tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }
}