.KCL_small-search {
    position: relative;
    max-width: 946px;

    &.sm {
        .search__submit,
        .search__clear {
            top: 8px;
            font-size: 13px;
        }
    }
    
    .search__submit,
    .search__clear {
        position: absolute;
        left: unset;
        right: 0;
        top: 14px;
        font-size: 16px;
        padding-right: 14px;
        background: none;
        border: 0;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }

    .search__submit {
        display: flex;
        &.disabled {
            color: $c-grey-bd;
        }
    }

    .search__clear {
        display: none;
    }

    .KCL_input {
        &.has-value {
            ~ .search__submit {
                display: none;
            }
            ~ .search__clear {
                display: flex;
            }
            
            &.is-focused {
                ~ .search__clear {
                    color: $c-blue-22;
                }
            }
        }
    }
}