.KCL__Stillingar {
    .KCL_alert {
        margin-bottom: 40px;
    }

    .KCL_tabs.lg {
        .tabs__body {
            padding-bottom: 150px;

            @include breakpoint-down(mobile) {
                padding-bottom: 80px;
            }
        }
    }

    h3 {
        @include breakpoint-down(mobile) {
            margin-bottom: 20px;
        }
    }

    .section__body {
        display: flex;

        @include breakpoint-down(mobile) {
            flex-direction: column;
        }

        .left-section {
            width: 50%;
            padding: {
                top: 10px;
                right: 40px;
            }
            
            &:not(.no-border) {
                border-right: 1px solid $c-grey-d3;
            }

            @include breakpoint-down(mobile) {
                width: 100%;
                padding: {
                    top: 0;
                    right: 0;
                    bottom: 50px;
                }

                &:not(.no-border) {
                    border-right: none;
                    border-bottom: 1px solid $c-grey-d3;
                }
            }
        }

        .right-section {
            width: 50%;
            padding: {
                top: 10px;
                left: 60px;
            }

            @include breakpoint-down(mobile) {
                width: 100%;
                padding: {
                    top: 50px;
                    left: 0;
                    bottom: 0;
                }
            }
        }
    }

    .Askriftarleid {
        .section__body {
            .left-section {
                .buttonAndLinkWrapper {
                    .KCL_button {
                        margin-top: 30px;
                    }

                    .more-users {
                        margin-top: 55px;

                        @include breakpoint-down(mobile) {
                            margin-top: 30px;
                        }

                        .contactLink {
                            margin-top: 5px;
                        }
                    }

                    .KCL_input {
                        margin-top: 30px;
                        width: fit-content;
                    }
                }

                .radios-group {
                    @include breakpoint-down(tablet) {
                        margin-bottom: 40px;
                    }

                    ul {
                        padding-left: 0;

                        li {
                            margin-bottom: 15px;

                            .radio {
                                .radio__label {
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }

                .price-card {
                    max-width: 100%;

                    .price-card__type {
                        display: inline-block;
                        background: #f1f2f5;
                        font-weight: bold;
                        font-size: 15px;
                        padding: 10px;
                        border-radius: 8px;
                        margin-bottom: 15px;
                    }

                    .price-card__price {
                        margin-bottom: 15px;

                        p {
                            font-size: 14px;

                            @include breakpoint-down(mobile) {
                                font-size: 12px;
                            }

                            strong {
                                font-weight: bold;
                                font-size: 40px;
                                margin-right: 5px;

                                @include breakpoint-down(mobile) {
                                    font-size: 30px;
                                }
                            }
                        }
                    }

                    .price-card__content {
                        padding-right: 20px;
                    }
                }
            }

            .right-section {
                p {
                    margin-bottom: 12px;

                    span {
                        display: inline-block;
                        width: 22%;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .Lykilord {
        .section__body {
            .left-section {
                .passwordDisclaimer {
                    margin-top: 60px;
                }

                .keldan__links {
                    display: flex;
                    gap: 20px;
                    justify-content: flex-start;
                }
            }

            .right-section {
                .KCL_alert {
                    width: 70%;
                    float: right;

                    @include breakpoint-down(mobile) {
                        float: left;
                    }
                }
            }
        }
    }

    .Vedbond {
        max-width: 945px;

        .section__body {
            flex-direction: column;
        }

        .KCL_checkbox {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .KCL_recaptcha {
            margin-top: 10px;
        }
    }

    .Greidsluupplysingar {
        .section__body {
            .left-section {
                .KCL_GreidsluupplysingarChangeForm {
                    .form__col {
                        &.zip-and-city {
                            display: flex;

                            > :first-child {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .right-section {
                p {
                    margin-bottom: 12px;

                    span {
                        display: inline-block;
                        width: 22%;

                        @include breakpoint-down(tablet) {
                            width: 30%;
                        }
                    }
                }
            }
        }
    }

    .notendur {
        .section__body {
            flex-direction: column;
        }

        .sub__heading {
            display: flex;
            justify-content: space-between;

            h3 {
                margin-bottom: 0;
            }
        }

        .sub__body {
            .KCL_checkbox {
                margin: 15px auto 30px auto;

                .checkbox__label {
                    font-size: 16px;
                    width: fit-content;

                    @include breakpoint-down(mobile) {
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.KCL_Greidsluupplysingar-form {
    .main-form-row {
        @include breakpoint-down(medium-desktop) {
            flex-flow: column;
            
            .cardnumber-column {
                margin-bottom: 25px;
            }
        }
    }

    .form__row {
        @include breakpoint-down(mobile) {
            flex-flow: column;

            .form__col {
                margin-bottom: 25px;
            }
        }
    }

    .cvc-column {
        display: flex;
        flex-flow: row nowrap !important;

        .KCL_input {
            flex-grow: 1;
        }

        .form__icon {
            flex-basis: 50px;
            flex-shrink: 0 !important;
            padding-top: 10px;
        }
    }
}

.KCL_askriftarleid-modal {
    .modal-body {
        overflow: visible;

        .KCL_alert {
            margin-left: -8px;
        }

        .actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
        }
    }
}

.KCL_notendur-form {
    .radios-group {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 34px;

        ul {
            display: flex;
            gap: 43px;
        }
    }
}