.KCL_single-line-table {
    @include breakpoint-down(mobile) {
        table {
            display: flex;
            width: 100%;
        }

        thead {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
        
        .table__body {
            overflow-y: hidden;
        }

        tbody {
            display: flex;
            flex-direction: column;
            width: 50%;
        }

        tr {
            display: flex;
            flex-direction: column;
        }

        th,
        td {
            height: 36px !important;
            display: flex;
            width: 100%;

            span {
                flex-grow: 1;
                display: flex;
                padding: 5px;
            }
        }

        th {
            //change border color from Table.scss on header row
            border-color: $c-grey-e9 !important;
            display: flex;
            align-items: center;

            span {
                align-items: center;
            }

            &:before {
                //disable gradient from Table.scss on header row
                content: none !important;
            }
        }

        td {
            align-items: center;

            .KCL_price-range {
                margin-left: 0;
            }
        }
    }
}