/* ------------------------------------------------------------ *\
  Header
\* ------------------------------------------------------------ */

.KCL_header {
    height: 70px;

    .header-content {
        // Should always be on top.
        position: fixed;
        // Size.
        height: 70px;
        width: 100%;
        // Color.
        background: $c-blue-gradient;
        // Navbar is a row.
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        // Ensure nav is always on top, especially dropdowns or datepickers in the main content.
        z-index: 5;
        padding: 15px 20px;

        @include breakpoint-down(tablet) {
            padding: 13px 15px;
        }

        &.pride {
            //PRIDE 2.0
            // background: linear-gradient(100deg, #E10C13 3.9%, #F39918 18.92%, #FEF31D 30.02%, #039A40 43.28%, #1C58D1 55.09%, #6F2281 68.84%, rgba(111, 34, 129)100%);
            //PRIDE 1.0
            background: linear-gradient(100deg, rgba(225,12,19,1) 0%, rgba(255,160,0,1) 3%, rgba(251,231,23,1) 5%, rgba(3,154,64,1) 8%, rgba(28,88,209,1) 10%, rgba(111,34,129,1) 13%, rgba(4,24,43,1) 35%, rgba(12,40,95,1) 57%, rgba(41,23,91,1) 87%);
        }

        &.pink-ribbon {
            background: $c-pink-gradient;
        
            .pink-ribbon-image {
                position: absolute;
                right: -20px;
                top: 2px;
                z-index: 9;

                @include breakpoint-down(mobile) {
                    display: none;
                }
            }
            
            .search-wrapper {
                padding-right: 30px;
            }
        }

        &.christmas {
            img {
                display: none;
                position: absolute;
                
                &.snow {
                    top: -12px;
                    left: 246px;
                    clip-path: inset(12px 0 34px 0);
                }

                &.berry-left {
                    top: 5px;
                    left: -30px;
                    z-index: 1;
                }

                &.berry-right {
                    top: 5px;
                    right: -30px;
                    z-index: 1;
                }
            }
            
            @include breakpoint-up(tablet) {
                img {
                    display: block;
                }
            }

            @include breakpoint-between(tablet, small-desktop) {
                img.snow {
                    left: 232px;
                }
            }

            @include breakpoint-up(small-desktop) {
                .search-wrapper {
                    margin-left: 60px;
                }
            }
        }
        
        .search-wrapper {
            flex: auto 1 0;

            @include breakpoint-up(tablet) {
                margin-left: 46px;
            }
        }

        .header-upper-right {
            display: flex;
            gap: 20px;
            align-items: center;

            @include breakpoint-down(tablet) {
                gap: 15px;
            }

            .search-button {
                appearance: none;
                border: none;
                background: transparent;
                color: $c-white;
                font-size: 20px;
                padding: 0 2px;
                transform: scaleX(-1);

                @include breakpoint-up(tablet) {
                    display: none;
                }
            }

            .my-kelda-menu {
                font-size: 20px;
                color: $c-white;
                width: 24px;
                height: 30px;
                text-align: center;

                svg {
                    vertical-align: middle;
                }

                @include breakpoint-up(tablet) {
                    display: none;
                }
            }

            &.is-mobile-nav-menu-open {
                .KCL_main-nav {
                    display: flex;
                }
            }
            &.is-mobile-mykelda-menu-open {
                .KCL_logged-in {
                    display: flex;
                }
            }
        }
    }

    &:not(.is-search-input-focused) {
        @include breakpoint-down(tablet) {
            height: 56px;

            .header-content {
                height: 56px;
                
                .search-button {
                    display: block;
                }

                .search-wrapper {
                    display: none;
                }
            }
        }
    }

    // Header expands in size when focused.
    // To accomodate for the search and the dropdown
    &.is-search-input-focused {
        // Mobile Nav bar. Search jumps down!
        @include breakpoint-down(tablet) {
            height: 115px;

            .header-content {
                height: 115px;
                align-items: flex-start;
                .KCL_logo {
                    margin-top: 3px;
                }

                .header-upper-right {
                    .search-button {
                        display: none;
                    }
                }
                
                // Search wrapper is at bottom.
                .search-wrapper {
                    padding-right: 0px;
                    width: 100%;
                    order: 1;
                    align-self: flex-end;

                    .KCL_search {
                        //pink ribbon
                        // max-width: 97%;
                        max-width: 100%;
                        padding-left: 0px;
                        height: auto;
                        background-color: transparent;

                        &.is-search-response-open {
                            form {
                                .field-control {
                                    border-bottom-left-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                }
                            }
                        }

                        form {
                            display: flex;
                            flex-flow: column nowrap;
                            
                            .search__submit {
                                padding-bottom: 2px;
                                height: 45px;
                            }

                            .field-control {
                                padding-left: 39px;
                                height: 45px;
                                background-color: $c-white;
                                border-radius: 10px;

                                .search-field {
                                    border-radius: 10px;
                                    height: 45px;
                                    padding: {
                                        top: 20px;
                                        bottom: 20px;
                                    }
                                }
                            }

                            .KCL_search-dropdown {
                                display: block;
                                height: 45px;
                                margin-top: 1px;
                                background-color: $c-white;
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;

                                &:before {
                                    display: none;
                                }

                                &.is-dropdown-open {
                                    border-bottom-left-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                }

                                button {
                                    font-size: 14px;
                                    width: 100%;
                                    padding: {
                                        top: 20px;
                                        left: 15px;
                                        right: 15px;
                                    }
                                }

                                .dropdown__holder {
                                    ul {
                                        a {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                            .KCL_search-response {
                                top: 45px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media print {
        display: none;
    }
}
