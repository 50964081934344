.KCL_news {
    .search__box {
        margin-bottom: 8px;
        display: flex;
        flex-flow: row nowrap;

        .KCL_small-search {
            flex-grow: 1;
        }

        .filter-wrapper {
            .KCL_filter {
                margin-left: 5px;
            }

            ul li button {
                text-align: left;
            }
        }
    }

    .display-box__footer {
        .news-list__pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            user-select:none;
            
            .news-list__indicator {
                font-size: 14px;
                color: $c-grey-65;
            }
            
            .pagination {
                margin-left: 7px;
                color: $c-dark-23;
                
                .news-list__arrows {
                    margin-left: 8px;
                    cursor: pointer;
                    background: $c-teal-f2;
                    color: $c-blue-45;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    &.disabled {
                        background: $c-grey-f1;
                        color: $c-grey-d3;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}