
/* ------------------------------------------------------------ *\
    Formula Boxes
\* ------------------------------------------------------------ */

.formula-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;   
    margin-bottom: 30px;

    @include breakpoint-down(mobile) {
        flex-direction: column;
    }
    
    .KCL_formula-box {
        @include breakpoint-down(mobile) {
            margin: 0 0 10px;
            width: 100%;
        }
    }

    .formula-boxes__descriptions {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 23px;
        font-size: 16px;
        width: 100%;

        @include breakpoint-down(mobile) {
            font-size: 14px;
            flex-wrap: wrap;
            margin-top: 13px;
        }

        li {
            margin: 0 11px;
        }
    }
}

/* ------------------------------------------------------------ *\
    Formula Box
\* ------------------------------------------------------------ */

.KCL_formula-box {
    display: flex;
    flex-direction: column;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .formula-box__label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        font-size: 10px;
        background: $c-grey-f1;
        border-radius: 6px;
        padding: 0px 4px;
        margin-bottom: 10px;

        @include breakpoint-down(mobile) {
            margin-bottom: 8px;
            font-size: 8px;
        }
    }

    .formula-box__content {
        background: $c-grey-f1;
        border-radius: 6px;
        min-height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;

        @include breakpoint-down(small-desktop) {
            padding: 0 15px;
        }

        @include breakpoint-down(mobile) {
            flex-wrap: wrap;
            min-height: 75px;
            padding: 24px 15px;
        }

        strong {
            margin-right: 10px;
        }

        .formula-box__formula {
            font-family: "Times New Roman", sans-serif;
            font-style: italic;
            font-size: 22px;

            @include breakpoint-down(mobile) {
                font-size: 18px;
            }
        }

        em {
            font-family: $ff-sans-serif;
            margin: 0 3px;
        }
    }
}