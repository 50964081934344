.KCL_gemmaq-scale {
    @include breakpoint-up(tablet) {
        margin-top: -58px;
        margin-right: -70px;
        min-width: 530px;
    }

    .gemmaq-scale-body {
        display: flex;

        .scale {
            display: flex;

            .colors {
                .color-unit {
                    width: 60px;
                    height: 50px;

                    &:not(:last-child) {
                        margin-bottom: 1px;
                    }
        
                    &#A {
                        background-color: #eb5ca4;
                        border-radius: 5px 5px 0px 0px;
                    }
        
                    &#B {
                        background-color: #fab530;
                    }
        
                    &#C {
                        background-color: #fbd825;
                    }
        
                    &#D {
                        background-color: #55cca1;
                    }
        
                    &#E {
                        background-color: #5a8eee;
                    }
        
                    &#F {
                        background-color: #4353d8;
                        border-radius: 0px 0px 5px 5px;
                    }
                }
            }

            .percentages {
                margin: 0px  30px 0 10px;
                padding: 12px 0;
    
                p {
                    min-width: 60px;

                    &:not(:first-child) {
                        margin: 25px 0 0;
                    }
        
                    @include breakpoint-down(mobile) {
                        width: 50px;
                        margin-top: 5px;
                    }
                }
            }
        }

        .info-box {
            background: #ffffff;
            border-radius: 6px;
            box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.11);
            padding: 25px 30px;
            align-self: center;
            max-width: 360px;
    
            @include breakpoint-down(mobile) {
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 15px;
                margin-right: 12px;
            }
        }
    }
}