.KCL_full-screen-image {
    // Cover entire screen
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    // Gray background.
    background-color: rgba(0, 0, 0, 0.8);
    // Add space for image.
    padding: 50px;
    // // Center image
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: center;
    // align-items: center;
    
    .img-container {
        height: 100%;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
}