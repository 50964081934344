.KCL_mobile-hamburger-menu {
    display: none;
    width: 26px;

    @include breakpoint-down(tablet) {
        display: flex;
    }

    &:hover {
        cursor: pointer;
    }

    .hamburger-icon {
        display: inline-flex;
        width: 26px;
        height: 20px;
        order: 99;
        transform-style: preserve-3d;
    
        span {
            position: absolute;
            transform-origin: 0% 50%;
            border-radius: 100px;
            width: 100%;
            height: 3px;
            background: $c-white;
            display: block;
            transition: transform .3s ease-out, opacity .3s ease-out, color .3s ease-out, background-color .3s ease-out;

            &:nth-child(1) {
                top: 0;
            }
        
            &:nth-child(2) {
                top: 9px;
                width: 20px;
                height: 2px;
            }
        
            &:nth-child(3) {
                top: auto;
                bottom: 0;
            }
        }
    }
}