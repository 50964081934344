/* ------------------------------------------------------------ *\
    Article Overview
\* ------------------------------------------------------------ */

.article-overview {
    max-width: 1354px;
    margin: 0 auto;
    padding: 60px 0 100px;
    counter-reset: sections;

    @include breakpoint-down(mobile) {
        padding: 33px 0 83px;
    }

    h1 {
        margin-bottom: 22px;

        @include breakpoint-down(mobile) {
            margin-bottom: 12px;
        }
    }

    h2 {
        margin-bottom: 20px;

        @include breakpoint-down(mobile) {
            margin-bottom: 8px;
        }
    }

    h3 {
        margin-bottom: 15px;
    }

    .article__head {
        width: calc(100% - 386px);
        padding-right: 82px;
        margin-bottom: 60px;

        @include breakpoint-down(medium-desktop) {
            width: calc(100% - 290px);
            padding-right: 50px;
        }

        @include breakpoint-down(small-desktop) {
            padding-right: 30px;
        }

        @include breakpoint-down(tablet) {
            width: 100%;
            padding-right: 0;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 30px;
        }
    }

    .article__nav {
        width: 386px;
        font-size: 15px;
        color: $c-grey-65;
        order: 2;

        @include breakpoint-down(medium-desktop) {
            width: 290px;
        }

        @include breakpoint-down(tablet) {
            width: 100%;
            margin-bottom: 60px;
        }

        @include breakpoint-down(mobile) {
            font-size: 13px;
        }

        ul {
            list-style: none;
            border-left: 5px solid $c-grey-f1;
            counter-reset: navLinks;
        }

        a {
            text-decoration: none;
            padding: 4px 11px;
            display: flex;

            @include breakpoint-down(mobile) {
                padding: 7px 11px;
            }
        }

        li {
            counter-increment: navLinks;
            position: relative;
            transition: color .3s;

            &:not(:last-child) {
                margin-bottom: 12px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 1px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -5px;
                bottom: 0;
                width: 5px;
                background: $c-teal-6c;
                opacity: 0;
                transition: opacity .3s;
            }

            a:before {
                content: counter(navLinks) ".";
                margin-right: 3px;
            }
        }

        li:hover {
            color: $c-dark-23;

            &:before {
                opacity: 1;
            }
        }

        .is-active {
            font-weight: 700;
            color: $c-dark-23;

            &:before {
                opacity: 1;
            }
        }
    }

    .article__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include breakpoint-down(tablet) {
            display: block;
        }
    }

    .article__content {
        width: calc(100% - 386px);
        padding-right: 82px;

        @include breakpoint-down(medium-desktop) {
            width: calc(100% - 290px);
            padding-right: 50px;
        }

        @include breakpoint-down(small-desktop) {
            padding-right: 30px;
        }

        @include breakpoint-down(tablet) {
            width: 100%;
            padding-right: 0;
        }
    }

    .article__section {
        line-height: 1.56;
        counter-increment: sections;

        &:not(:last-child) {
            margin-bottom: 60px;

            @include breakpoint-down(mobile) {
                margin-bottom: 40px;
            }
        }

        .list-alphabets {
            counter-reset: letters;
            padding-left: 0;

            li {
                display: flex;
                margin-left: 17px;
                
                &:not(:last-child) {
                    margin-bottom: 10px;
    
                    @include breakpoint-down(mobile) {
                        margin-bottom: 8px;
                    }
                }
                
                &:before {
                    font-weight: 900;
                    margin-right: 15px;
                }
            }

            > li {
                counter-increment: letters;

                &:before {
                    content: counter(letters, lower-alpha) ".";
                }

                p {
                    margin-bottom: 10px;
                }

                ol {
                    counter-reset: roman;
                    padding-left: 0;

                    li {
                        counter-increment: roman;

                        &:before {
                            content: counter(roman, lower-roman) ".";
                        }
                    }
                }
            }
        }

        h2 {
            &:before {
                content: counter(sections) ".";
                margin-right: 10px;
            }
        }
    }
}