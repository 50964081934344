.KCL_cookieConsentForm {
    background: $c-dark-04;
    opacity: 0.9;
    border-radius: 6px;
    max-width: 610px;
    padding: 15px 15px 25px 25px;
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    left: 20px;
    right: 20px;

    div.iconContainer {
        color: white;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }

    div.textContainer {
        margin-bottom: 15px;

        span {
            color: $c-white;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
        }
    }

    a {
        text-decoration: none;
        color: $c-teal-6c;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
    }
}