.KCL_home-page {
    /* ------------------------------------------------------------ *\
        Main
    \* ------------------------------------------------------------ */

    &.main {
        overflow: hidden;
        background: $c-grey-f8;
        
        .main__inner-fluid {
            padding: 26px 26px 74px;

            @include breakpoint-down(tablet) {
                padding: 15px 15px 54px;
            }

            @include breakpoint-down(mobile) {
                padding: 15px 0 54px;
            }
        }

        .head-ad {
            .ads1360x180 {
                margin-top: 26px;
                margin-bottom: 0;
            }
            
            .ads300x250 {
                margin-top: 15px;
                margin-bottom: 0;
            }
        }
    }

    /* ------------------------------------------------------------ *\
        Grid Items
    \* ------------------------------------------------------------ */

    .grid-items {
        .grid-item {
            margin-bottom: 0px;

            &.news-frontpage-grid-item, &.sjodir-frontpage-grid-item {
                // Have to add z-index, otherwise Dagatal will be above the Filter Dropdown.
                // Have to add position: sticky because z-index doesn't work with default: `position: static`
                // Same with sjodir for date picker conflict with gjaldmidlar
                position: sticky;
                z-index: 2;
            }
        }
    }

    /* ------------------------------------------------------------ *\
        Grid Item
    \* ------------------------------------------------------------ */

    .grid-item {
        padding: 0 13px;

        @include breakpoint-down(tablet) {
            padding: 0;
        }

        .display-box {
            margin-bottom: 26px;
        }

        > .KCL_ad-rotator {
            margin-top: -13px;
            margin-bottom: 13px;
        }
    }

    .KCL_skuldabref,
    .KCL_gjaldmidlar {
        .display-box__body {
            overflow-x: auto;

            .KCL_table {
                .table__body {
                    overflow-x: unset;
                }
            }
        }
    }
}