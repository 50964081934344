/* ------------------------------------------------------------ *\
  Vendor
\* ------------------------------------------------------------ */

//@import '~vendor-name/vendor.scss/css';
@import "~bootstrap/scss/bootstrap";

@import '~swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';

/* ------------------------------------------------------------ *\
	Generic
\* ------------------------------------------------------------ */

@import 'generic/variables';
@import 'generic/functions';
@import 'generic/mixins';
@import 'generic/reset';
@import 'generic/fonts';
@import 'generic/base';
@import 'generic/helpers';

/* ------------------------------------------------------------ *\
	Components
\* ------------------------------------------------------------ */

@import 'components/_article-overview.scss';
@import 'components/_form.scss';
@import 'components/_funds-datepicker.scss';
@import 'components/_grid-items.scss';
@import 'components/_icons.scss';
@import 'components/_list.scss';
@import 'components/_main.scss';
@import 'components/_radio.scss';
@import 'components/_section.scss';
@import 'components/_shell.scss';
@import 'components/_stat-box.scss';
@import 'components/About.scss';
@import 'components/AboutNewswire.scss';
@import 'components/AboutSubscription.scss';
@import 'components/Accordion.scss';
@import 'components/Advertise.scss';
@import 'components/Alert.scss';
@import 'components/AreidanleikakannanirPage.scss';
@import 'components/Auglysingar.scss';
@import 'components/Avatar.scss';
@import 'components/BlurredCompanyInfoBox.scss';
@import 'components/BondsOrSharesTable.scss';
@import 'components/Button.scss';
@import 'components/Calendar.scss';
@import 'components/CalendarDate.scss';
@import 'components/CalendarItem.scss';
@import 'components/CalendarWeekdays.scss';
@import 'components/Censored.scss';
@import 'components/Charts.scss';
@import 'components/Checkbox.scss';
@import 'components/ColoredNumber.scss';
@import 'components/CompanyAssets.scss';
@import 'components/CompanyCompare.scss';
@import 'components/CompanyCompareChoose.scss';
@import 'components/CompanyFinancialInfo.scss';
@import 'components/CompanyLegal.scss';
@import 'components/CompanyMembers.scss';
@import 'components/CompanyOwners.scss';
@import 'components/CompanyPapers.scss';
@import 'components/CompanyProfile.scss';
@import 'components/CompanyReportsFiles.scss';
@import 'components/CompanyReportsList.scss';
@import 'components/CompanySearch.scss';
@import 'components/CompanySearchesPage.scss';
@import 'components/CompanySearchResponse.scss';
@import 'components/CompanySimilar.scss';
@import 'components/CompanyStatements.scss';
@import 'components/CompanySubPage.scss';
@import 'components/CompanyTitle.scss';
@import 'components/CompanyValuation.scss';
@import 'components/CompanyValuationTable.scss';
@import 'components/ConstructionProfile.scss';
@import 'components/CookieConsentForm.scss';
@import 'components/CryptoLogo.scss';
@import 'components/CurrenciesSingleBase.scss';
@import 'components/CurrencyIntradayChart.scss';
@import 'components/DagatalItem.scss';
@import 'components/DatePicker.scss';
@import 'components/DisplayBox.scss';
@import 'components/DropSelect.scss';
@import 'components/ErrorPage.scss';
@import 'components/ExampleReports.scss';
@import 'components/ExcellentCompanies.scss';
@import 'components/ExcellentList.scss';
@import 'components/Filter.scss';
@import 'components/FilterItem.scss';
@import 'components/FinanceRefPage.scss';
@import 'components/FinancialData.scss';
@import 'components/Flag.scss';
@import 'components/Footer.scss';
@import 'components/ForeignPepModal.scss';
@import 'components/FormulaBox.scss';
@import 'components/Frettavakt.scss';
@import 'components/FrettaVaktinForm.scss';
@import 'components/FrettavaktItem.scss';
@import 'components/Frettir.scss';
@import 'components/FullScreenImage.scss';
@import 'components/FundsPage.scss';
@import 'components/GagnaleitPricingSection.scss';
@import 'components/GangverdMap.scss';
@import 'components/Gemmaq.scss';
@import 'components/GEMMAQContact.scss';
@import 'components/GEMMAQData.scss';
@import 'components/GemmaqScale.scss';
@import 'components/GjaldeyrismarkadurPage.scss';
@import 'components/GjaldmidlarPage.scss';
@import 'components/GrayAccordion.scss';
@import 'components/HafaSamband.scss';
@import 'components/Hagtolur.scss';
@import 'components/HagtolurChart.scss';
@import 'components/Header.scss';
@import 'components/HeadSection.scss';
@import 'components/HeatMap.scss';
@import 'components/HistoryButtonDropDown.scss';
@import 'components/Hlutabref.scss';
@import 'components/HomePage.scss';
@import 'components/IndicesPage.scss';
@import 'components/IndividualProfile.scss';
@import 'components/InfoColumns.scss';
@import 'components/Input.scss';
@import 'components/IntradayChart.scss';
@import 'components/KeldanMarkadirPage.scss';
@import 'components/KeldanMarkadirPricingSection.scss';
@import 'components/KeyFigures.scss';
@import 'components/Label.scss';
@import 'components/Landsbankinn.scss';
@import 'components/LanguagePicker.scss';
@import 'components/LegalSearch.scss';
@import 'components/Link.scss';
@import 'components/ListarAlmennt.scss';
@import 'components/LockIcon.scss';
@import 'components/Login.scss';
@import 'components/Logo.scss';
@import 'components/MarketPages.scss';
@import 'components/Markhopalistar.scss';
@import 'components/MembershipSearch.scss';
@import 'components/MobileHamburgerMenu.scss';
@import 'components/Modal.scss';
@import 'components/Nav.scss';
@import 'components/NavAccess.scss';
@import 'components/NewPassword.scss';
@import 'components/News.scss';
@import 'components/NewsAnnouncementsPage.scss';
@import 'components/NewsByMarkets.scss';
@import 'components/NewsItem.scss';
@import 'components/NewsWatcher.scss';
@import 'components/Okutaeki.scss';
@import 'components/OpeningHours.scss';
@import 'components/Pagination.scss';
@import 'components/PepSearch.scss';
@import 'components/PieChart.scss';
@import 'components/PriceCard.scss';
@import 'components/PriceRange.scss';
@import 'components/PricingPage.scss';
@import 'components/PropertyPage.scss';
@import 'components/PropertyReportsList.scss';
@import 'components/PropertySearches.scss';
@import 'components/Rafmyntir.scss';
@import 'components/Recaptcha.scss';
@import 'components/Reikningar.scss';
@import 'components/ReikningarModal.scss';
@import 'components/ReportsPage.scss';
@import 'components/ScrollSpy.scss';
@import 'components/Search.scss';
@import 'components/SearchConfirmModal.scss';
@import 'components/SearchDropdown.scss';
@import 'components/SearchPage.scss';
@import 'components/SearchResponse.scss';
@import 'components/SearchResults.scss';
@import 'components/Shareholders.scss';
@import 'components/SharesPage.scss';
@import 'components/SignUpForm.scss';
@import 'components/SignUpPage.scss';
@import 'components/SingleBondPage.scss';
@import 'components/SingleLineTable.scss';
@import 'components/SingleNewsPage.scss';
@import 'components/SingleSharePage.scss';
@import 'components/Sjodir.scss';
@import 'components/Slider.scss';
@import 'components/SliderInput.scss';
@import 'components/SmallColumnChart.scss';
@import 'components/SmallSearch.scss';
@import 'components/Stillingar.scss';
@import 'components/SubNavBar.scss';
@import 'components/SubscriptionBanner.scss';
@import 'components/SubscriptionPricingTable.scss';
@import 'components/Table.scss';
@import 'components/Tabs.scss';
@import 'components/TextImageBlock.scss';
@import 'components/Thjodskra.scss';
@import 'components/ToastComp.scss';
@import 'components/Tooltip.scss';
@import 'components/TradablesChanges.scss';
@import 'components/TradablesInformation.scss';
@import 'components/UserDisclaimer.scss';
@import 'components/VaktarinnNews.scss';
@import 'components/VaktarinnPage.scss';
@import 'components/VehicleProfile.scss';
@import 'components/VerticalTable.scss';
@import 'components/VextirSedlabanka.scss';
@import 'components/ViewAssetsReport.scss';
@import 'components/ViewCompanyCompare.scss';
@import 'components/ViewCompanyReport.scss';
@import 'components/ViewMembersReport.scss';
@import 'components/ViewNewsArticle.scss';
@import 'components/ViewPepReport.scss';
@import 'components/ViewReports.scss';
@import 'components/Watch.scss';
@import 'components/WatchButton.scss';
@import 'components/Webservice.scss';

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: medium none; z-index: 2147483647;"] {
	display: none;
}
