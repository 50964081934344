.KCL_company-papers {
    .company-paper-line {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        
        .main-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &.KCL_company-papers_large {
        .page-size-selector {
            margin-bottom: 10px;
        }
        
        .company-paper-line .main-line {
            min-height: 50px;
            border-bottom: 1px solid $c-grey-e9;
        }

        .linkAndInfoWrapper {
            display: flex;

            .linkWrapper {
                min-width: 200px;

                @include breakpoint-down(tablet) {
                    min-width: 120px;
                    margin-right: 10px;
                }
            }

            .dates-line {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;

                @include breakpoint-down(tablet) {
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }
    }
}