.KCL_disclaimer {
    overflow: unset;

    .article-overview {
        padding-top: 10px;
    }

    &.KCL_keldan-markadir-disclaimer {
        .section__body p {
            margin-bottom: 11px;
        }

        .article-overview {
            padding: 0;
            margin-bottom: 130px;
            margin-top: 20px;
        }
    }
}
