.KCL_ViewCompanyMembersReport {
    .vertical-table-wrapper {
        @include breakpoint-up(tablet) {
            margin-top: 30px;
        }

        @include breakpoint-down(tablet) {
            row-gap: 30px;
        }

        div.text-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            .nameAndSocial {
                margin-bottom: 5px;
            }
        }

        .button-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .extra-info {
                margin-bottom: 0;
            }
        }
    }
}