.KCL_market-page {
    background: $c-grey-f8;
    padding-bottom: 60px;

    .custom-cols {
        @include breakpoint-down(mobile) {
            overflow-x: visible !important;
        }
    }

    .middle-ad-section {
        margin: 25px auto;
    }

    .KCL_chart {
        &.display-box.lg {
            .display-box__head {
                @include breakpoint-between (mobile-medium, mobile) {
                    margin-bottom: 0;

                    h4 {
                        margin-bottom: -29px;
                        z-index: 2;
                    }
                }
            }

            &.chart--half-width {
                .display-box__head {
                    @include breakpoint-between(mobile, tablet) {
                        margin-bottom: 0;

                        h4 {
                            margin-bottom: -33px;
                            z-index: 2;
                        }
                    }
                    
                    @include breakpoint-up(medium-desktop) {
                        margin-bottom: 0;

                        h4 {
                            margin-bottom: -33px;
                            z-index: 2;
                        }
                    }
                }
            }

            &:not(.chart--half-width) {
                .display-box__head {
                    @include breakpoint-up (mobile) {
                        margin-bottom: 0;
                        
                        h4 {
                            margin-bottom: -33px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}