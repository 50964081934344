.KCL_excellent-companies {
    .excellentSearchContainer {
        margin-top: 40px;

        .response {
            position: absolute;
        }
    }

    .section--gray {
        padding: 100px 0 80px;

        @include breakpoint-down(mobile) {
            padding: 60px 0;
        }

        .section__head {
            text-align: center;
            padding-bottom: 50px;

            @include breakpoint-down(mobile) {
                padding-bottom: 0;
                margin-bottom: 30px;
            }
        }

        .section__body {
            .slid {
                .KCL_slider {
                    margin-bottom: 100px;

                    .swiper-container {
                        overflow: visible;
                    }

                    .swiper-slide {
                        width: 25%;
                        padding: 0;

                        .display-box {
                            display: inline-block;
                            width: calc(100% - 40px);
            
                            p {
                                margin-bottom: 40px;
                            }
                        }
                    }

                    .slider__actions {
                        .slider__button {
                            top: 100%;
                            margin-top: 30px;
                            margin-left: 0;
                            background: $c-white;
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                        }
        
                        .slider__next {
                            right: unset;
                            margin-left: 80px;
                        }
                    }
                }

                .desktop__version {
                    display: block;

                    @include breakpoint-down(mobile) {
                        display: none;
                    }
                }

                .mobile__version {
                    display: none;

                    @include breakpoint-down(mobile) {
                        display: block;
                    }

                    .display-box {
                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
        
                        p {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }

    .section--contact {
        .section__head {
            margin-top: 45px;

            @include breakpoint-down(mobile) {
                margin-top: 0;
                margin-bottom: 40px;

                p {
                    margin-bottom: 15px;
                }
            }

            .list-heading {
                margin: 4px 0 16px;

                @include breakpoint-down(mobile) {
                    margin: 0 0 20px;
                }
            }

            .price {
                margin-bottom: 0;
            }

            .KCL_check-list {
                margin-bottom: 30px;

                @include breakpoint-down(mobile) {
                    margin-bottom: 15px;
                }
            }
        }
        
        .section__body {
            .form {
                padding-top: 50px;

                @include breakpoint-down(mobile) {
                    padding-top: 30px;
                }

                form {
                    padding-top: 5px;

                    h3 + .form__row {
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}