.KCL_company-members {
    .company-members-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.large {
            height: 50px;
            border-bottom: 1px solid $c-grey-e9;
        }

        &:not(.large):not(:last-child) {
            margin-bottom: 10px;
        }


    }
    .company-members-line-text {
        font-size: 15px;
        line-height: 18px;
    }

    .company-prokuruumbod {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &.censored {
            gap: 5px;
        }
        &.large {
            margin-top: 5px
        }
    }
}