.KCL_frettaVaktinForm {
    form .form__body {
        margin-bottom: 0px;

        button.primary {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .form__row:not(:last-child) {
            margin-bottom: 0px;
        }

        div.form__col {
            flex-grow: 2;

            &:nth-child(1) {
                flex-grow: 10;

                div.KCL_input {
                    margin-bottom: 7px;
                }

                > div:nth-child(2) {
                    margin-bottom: 10px;
                }
            }

            &:nth-child(3) {
                flex-grow: 3;
            }
        }

        div.form__col:nth-child(4) {
            width: fit-content;
            flex-grow: unset;
            white-space: pre;
        }
    }
}