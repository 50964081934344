.KCL_button {
    font-weight: 700;
    text-align: center;
    border-radius: 6px;
    transition: background 0.3s, color .3s, border .3s;
    text-decoration: none;
    display: inline-block;
    white-space: pre;
    width: fit-content;

    &.disabled {
        pointer-events: none;
    }

    &.sm {
        font-size: 14px;
        line-height: 16px;
        padding: 6px 14px;
        max-height: 30px;

        &.with-icon {
            padding: 6px 11px;

            .icon-fa:not(:last-child) {
                margin-right: 7px;
            }
        }
    }

    &.lg {
        font-size: 16px;
        line-height: 19px;
        padding: 12px 23px;

        &.with-icon {
            padding: 12px 13px;

            .icon-fa:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &.primary {
        background: $c-blue-22;
        color: $c-white;
        border: 1px solid $c-blue-22;
        
        &:hover {
            background: $c-blue-1d;
            color: $c-white;
            border-color: $c-blue-1d;
        }

        &:focus {
            outline: 4px solid $c-blue-d4;
        }

        &.disabled {
            background: $c-blue-d4;
            border-color: $c-blue-d4;
        }
    }

    &.buy {
        background: $c-green-94;
        color: $c-blue-1d;
        border: 1px solid $c-green-94;

        &:hover {
            background: $c-green-c2;
            color: $c-blue-1d;
            border-color: $c-green-c2;
        }

        &:focus {
            outline: 4px solid $c-yellow-fef8;
        }

        &.disabled {
            background: $c-green-ee;
            border-color: $c-green-ee;
            color: $c-blue-d4;
        }
    }

    &.secondary {
        background: transparent;
        color: $c-blue-22;
        border: 1px solid $c-blue-22;

        &:hover {
            color: $c-blue-1d;
            border-color: $c-blue-1d;
        }

        &:focus {
            outline: 4px solid $c-blue-d4;
        }

        &.disabled {
            color: $c-blue-d4;
            border-color: $c-blue-d4;
        }
    }

    &.hide-text-in-mobile {
        @include breakpoint-down(tablet) {
            .icon-fa {
                margin-right: 0 !important;
            }
            .button-text {
                display: none !important;
            }
        }
    }

    &.smaller-button-in-mobile {
        @include breakpoint-down(tablet) {
            font-size: 14px;
            line-height: 16px;
            padding: 6px 14px;

            &.with-icon {
                padding: 6px 11px;

                .icon-fa {
                    margin-right: 7px;
                }
            }
        }
    }

    &.example-button:not(.no-margin) {
        margin-bottom: 50px;
    }
    &.no-margin {
        margin: 0;
    }
}