.KCL_logged-in {
    .nav__main {
        padding-right: 0;
        border-right: none;

        @include breakpoint-up(tablet) {
            padding-left: 5px;
        }

        .nav__main-list {
            > button {
                @include breakpoint-between(tablet, small-desktop) {
                    .username  {
                        display: none;
                    }

                    .user-icon {
                        margin-right: 0;
                    }
                }
            }
            .nav__sub {
                right: -8px;
            }
        }

        .user-icon {
            font-size: 16px;
            margin-right: 10px;
        }
    }
}

.KCL_logged-out {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 0;
    gap: 20px;

    @include breakpoint-down(tablet) {
        gap: 15px;
    }

    li {
        // Text properties
        color: $c-white;
        font-size: 16px;
        display: flex;

        &:first-child {
            @include breakpoint-down(tablet) {
                // Decrease distance when dense button and no text
                margin-right: 0px;
            }
        }

        a {
            // Fix font size conflict
            font-size: 16px;
            // Fix links
            text-decoration: none;
            // Align icon and text
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            // Link change color on hover.
            &:not(.KCL_button):hover {
                color: $c-teal-6c;
            }

            // INNSKRA
            .user-icon {
                // Add distance between icon and text
                margin-right: 10px;

                @include breakpoint-down(tablet) {
                    font-size: 20px;
                    width: 24px;
                    text-align: center;
                }

                @include breakpoint-down(small-desktop) {
                    margin-right: 0;
                }
            }

            // Remove "innskrá" text on smaller screens
            .text-span {
                @include breakpoint-down(small-desktop) {
                    display: none;
                }
            }

            // NYSKRA
            &.KCL_button {
                // Remove built-in button margin.
                margin: 0px;
                // Change built-in button padding.
                padding: {
                    top: 10px;
                    left: 25px;
                    right: 25px;
                    bottom: 10px;
                }

                // Change built-in outlines. The dark background messes it up.
                &:focus {
                    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
                    outline: 0;
                }

                // On smaller screens, use a denser button:
                @include breakpoint-down(small-desktop) {
                    font-size: 14px;
                    line-height: 16px;
                    padding: {
                        top: 6px;
                        left: 14px;
                        right: 14px;
                        bottom: 6px;
                    }
                }

                .short-text {
                    display: none;

                    @include breakpoint-down(mobile) {
                        display: block;
                    }
                }

                .full-text {
                    @include breakpoint-down(mobile) {
                        display: none;
                    }
                }
            }
        }
    }
}
