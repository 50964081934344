.KCL_company-valuation {
    &.locked {
        min-height: 380px;
    }

    .grey-box {
        background: $c-grey-f1;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding: {
            top: 19px;
            right: 5px;
            bottom: 15px;
            left: 5px;
        }
        min-height: 93px;

        &.large-box {
            width: 100%;
            margin-bottom: 3px;
        }

        .grey-box__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;

            .title {
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: $c-dark-39;
            }

            .value {
                font-weight: 700;
                color: $c-dark-23;
                margin-bottom: 5px;

                .value-number {
                    font-size: 40px;
                    line-height: 47px;
                }

                .value-unit {
                    font-size: 20px;
                }
            }
        }
    }
}

.valuation-calculations {
    .final-value-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3px 5px;
        margin-bottom: 42px;

        .value {
            color: $c-red-db;
        }
    }
}

.KCL_company-page.valuation-page {
    .head-wrapper {
        background: $c-white;
        
        .shell {
            padding-bottom: 0;
            
            .KCL_head-section {
                .section__head--content {
                    gap: 30px 26px;
                    
                    .section__head--main {
                        flex: 1 1 50%;
                    }
                    
                    .section__head--right {
                        flex: 1 1 50%;
                        
                        .KCL_alert {
                            @include breakpoint-up(tablet) {
                                margin-top: -76px;
                                margin-bottom: 50px;
                            }
                        }
                    }
                }
            }
        }
    }

    .main-content {
        padding-top: 60px;

        @include breakpoint-down(mobile) {
            padding-top: 40px;
        }
    }

    .grid-items {
        contain: content;
        display: flex;
        flex-direction: column;
        gap: 34px;
    }

    .summary-box {
        display: flex;
        flex-direction: row;
        gap: 30px 26px;

        @include breakpoint-down(tablet) {
            flex-direction: column;
        }

        h2, h3 {
            .bold-name {
                font-weight: 500;
            }
        }

        .description {
            flex: 1 1 50%;
        }

        .grey-box {
            flex: 1 1 50%;
            background: $c-grey-f1;
            border-radius: 6px;
            padding: 40px 30px 30px;
            text-align: center;

            .value {
                font-weight: 700;
                width: fit-content;
                margin: {
                    right: auto;
                    left: auto;
                }
                transition: background 2s;

                .value-number {
                    font-size: 60px;
                }

                .value-unit {
                    font-size: 18px;
                    margin-left: 5px;
                }
            }
            
            .title {
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .KCL_drop-select {
                margin-top: 40px;
            }
            
            @include breakpoint-down(mobile) {
                .value {
                    .value-number {
                        font-size: 36px;
                    }
                
                    .value-unit {
                        font-size: 14px;
                    }
                }

                .title {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        &.final-result-box {
            .details {
                display: none;
                margin-top: 10px;

                &.show {
                    display: block;
                }

                p {
                    margin-bottom: 20px;
                }

                img {
                    margin: {
                        top: 10px;
                        left: 33px;
                    }
                    
                    @include breakpoint-down(tablet) {
                        display: block;
                        margin: {
                            left: auto;
                            right: auto;
                        }
                    }
                }
            }

            .results {
                flex: 1 1 50%;

                .table-wrapper {
                    margin-top: 10px;
                }
            }
        }
    }

    .model-box {
        .extra-info {
            margin: {
                top: -10px;
                bottom: 30px;
            }
        }

        h6 .icon-fa {
            margin-left: 8px;
        }

        .variables {
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            margin-bottom: 50px;
            gap: 30px 50px;

            @include breakpoint-down(tablet) {
                flex-direction: column;
            }

            .variables--section {
                flex: 1 1 50%;
                margin-bottom: 10px;
                padding-right: 15px;
                position: relative;

                .variables-description {
                    margin-bottom: 33px;

                    @include breakpoint-down(mobile) {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }

            .invalid-params-message {
                display: none;
                font-size: 12px;
                color: $c-red-db;
                position: absolute;
                right: 20px;
                margin-top: 5px;

                &.show {
                    display: block;
                }
            }
        }

        .calculations {
            .calculations--line {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                gap: 26px;

                > * {
                    flex: 1 1;
                }

                &:first-child {
                    margin-bottom: 26px;

                    > * {
                        flex-basis: 25%;

                        @include breakpoint-between(mobile, medium-desktop) {
                            flex-basis: 45%;

                            &.head-wrapper:nth-child(5) {
                                order: 3;
                                flex-basis: 100%;
                            }
                        }
                        @include breakpoint-down(mobile) {
                            flex-basis: 100%;
                        }
                    }
                }

                &:last-child {
                    > * {
                        flex-basis: 45%;

                        @include breakpoint-down(mobile) {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }
    }

    .highlighted {
        background: $c-yellow-ff;
    }

    .ebitda-multiplier-link {
        margin-top: 25px;

        @include breakpoint-down(mobile) {
            margin-top: 20px;
        }
    }
}