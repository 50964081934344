.KCL_price-card {
    /* ------------------------------------------------------------ *\
        Price Card
    \* ------------------------------------------------------------ */

    border-radius: 24px;
    padding: 50px 50px 60px;
    position: relative;
    font-size: 16px;
    background: $c-white;

    &.keldanMarkadirPriceCard {
        .price-card__type{
            background: $c-white;
            margin-bottom: 30px;
        }
        .price-card__content h6 {
            font-weight: 500;
            margin: 0px;
        }
        &.price-card--base {
            background: $c-blue-d4;
        }
        &.price-card--primary {
            background: $c-blue-0d;
            .price-card__content .storePriceContainer {
                margin-top: 0px;
                margin-bottom: 20px;
                }
        }
        &.price-card--secondary {
            background: linear-gradient(158deg, #04182B 4.6%, #0C285F 24.18%, #29175B 47.88%); 
        }
    }
    
    @include breakpoint-down(large-desktop) {
        padding: 50px 30px;
    }

    @include breakpoint-down(small-desktop) {
        padding: 40px 15px;
    }

    @include breakpoint-down(mobile) {
        padding: 25px 25px 40px;
        font-size: 14px;

        &.mobile-offset {
            padding-top: 35px;
        }
    }

    .price-card__badge {
        background: $c-yellow-f8;
        color: $c-dark-23;
        font-weight: 700;
        padding: 0 10px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        white-space: nowrap;

        @include breakpoint-down(mobile) {
            font-size: 14px;
            height: 24px;
        }
    }

    .price-card__type {
        font-size: 15px;
        font-weight: 700;
        background: $c-grey-f1;
        border-radius: 8px;
        padding: 0 10px;
        min-height: 30px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 14px;

        @include breakpoint-down(mobile) {
            font-size: 13px;
        }
    }

    .price-card__price-text {
        font-size: 14px;
        margin-bottom: 8px;

        strong {
            font-size: 30px;
            font-weight: 700;
            display: block;
            margin-top: 8px;
        }
    }

    .price-card__price {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;

        @include breakpoint-down(mobile) {
            font-size: 12px;
        }

        strong {
            font-size: 40px;
            margin-right: 8px;
            
            @include breakpoint-down(mobile) {
                font-size: 30px;
            }
        }

        p {
            margin-top: 7px;
        }
    }

    .price-card__content {
        > h6 {
            margin: 14px 0 22px;
            font-weight: 700;

            @include breakpoint-down(mobile) {
                font-size: 16px;
                margin: 11px 0 15px;
            }
        }

        > p {
            margin-bottom: 30px;

            @include breakpoint-down(mobile) {
                line-height: 1.57;
                margin-bottom: 24px;
            }
        }

        dl {
            dt {
                font-weight: 400;
            }
        }
    }

    .price-card__actions {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        @include breakpoint-down(mobile) {
            margin-top: 25px;
        }
    }

    .price-card__foot {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            margin:0 -10px 32px 0;

            @include breakpoint-down(large-desktop) {
                margin-right: 0;
            }
        }

        img {
            height: 44px;
            width: auto;

            @include breakpoint-down(small-desktop) {
                width: 100%;
                height: auto;
            }
        }

        a:not(:last-child) {
            margin-right: 10px;
        }
    }

    .price-card__details {
        dl {
            margin: 0 0 5px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 4px 0;
        }

        p {
            color: $c-grey-4f;

            &:last-child {
                margin-top: 35px;

                @include breakpoint-down(mobile) {
                    margin-top: 21px;
                }
            }
        }
    }

    .price-card__foot-buttons {
        @include breakpoint-down(small-desktop) {
            display: flex;
            align-items: center;
        }
    }

    .price-card__ribbon {
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        z-index: 1;
        width: 100%;
        overflow: hidden;

        .price-card__ribbon-content {
            background: $c-green-c8;
            color: $c-blue-1d;
            position: absolute;
            top: 20px;
            right: -34px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.06em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 10px;
            transform: rotate(37deg);
            width: 160px;
        }
    }

    .price-card__info {
        line-height: 1.63;

        .paragraph-small {
            color: inherit;
        }
    }

    /* ------------------------------------------------------------ *\
        Price Card Overview
    \* ------------------------------------------------------------ */

    &.price-card--overview {
        .price-card__type {
            background: $c-blue-d5;
        }
    }

    /* ------------------------------------------------------------ *\
        Price Card Primary
    \* ------------------------------------------------------------ */

    &.price-card--primary {
        background: $c-blue-1d;
        color: #fff;

        .price-card__type {
            background: #fff;
            color: $c-dark-23;
        }

        .KCL_check-list {
            .check-list-item {
                color: $c-white;
            }
        }
    }

    /* ------------------------------------------------------------ *\
        Price Card Secondary
    \* ------------------------------------------------------------ */

    &.price-card--secondary {
        background: $c-dark-07;
        color: #fff;

        .price-card__type {
            background: #fff;
            color: $c-dark-23;

        }

        .KCL_check-list {
            .check-list-item {
                color: $c-white;
            }
        }
    }

    /* ------------------------------------------------------------ *\
        Price Card Tertiary
    \* ------------------------------------------------------------ */

    &.price-card--tertiary {
        background: $c-blue-d4;

        .price-card__type {
            background: #fff;
            color: $c-dark-23;
        }
    }

    /* ------------------------------------------------------------ *\
        Price Card Signup
    \* ------------------------------------------------------------ */

    &.price-card--signup {
        padding: 50px 50px 60px;

        @include breakpoint-down(mobile) {
            padding: 40px 20px;
        }

        .price-card__price {
            margin-bottom: 12px;

            p {
                margin-top: 10px;
            }
        }

        .price-card__badge {
            font-size: 20px;
            background: #fff;
            height: 40px;
            padding: 0 25px;
            z-index: 2;

            @include breakpoint-down(mobile) {
                font-size: 14px;
                height: 24px;
            }
        }
    }
}

.price-card {
    margin: 0 13px;
    width: 100%;
    max-width: calc(33.33% + 26px);

    @include breakpoint-down(large-desktop) {
        margin: 0 15px;
        max-width: calc(33.33% + 30px);
    }

    @include breakpoint-down(small-desktop) {
        margin: 0 10px;
        max-width: calc(33.33% + 20px);
    }

    @include breakpoint-down(tablet) {
        margin: 0;
        max-width: 100%;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.price-cards__slider {
    .swiper-container {
        overflow: visible;
    }
    
    @include breakpoint-down(mobile) {
        margin: 0 0 -63px;

        .swiper-container {
            padding: 0 40px;
        }

        .swiper-slide {
            padding: 15px 9px 90px;
        }
    }
}