/* ------------------------------------------------------------ *\
    Stat box
\* ------------------------------------------------------------ */

.stat-box {
    background: $c-grey-f1;
    border-radius: 6px;
    font-size: 11px;
    line-height: 1.18;
    display: flex;
    flex-direction: column;
    padding: 12px 5px;
    min-height: 90px;

    span {
        color: $c-dark-39;
        text-align: center;
    }

    strong {
        font-weight: 500;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 8px;
    }

    .stat-box__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $c-dark-23;
    }

    .stat-box__foot {
        margin-top: auto;
        font-style: italic;
        color: $c-grey-65;       
        text-align: center;
    }
}