.KCL_company-statements {
    .company-statement-line {
        display: flex;
        justify-content: space-between;
        
        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &.large {
            height: 50px;
            margin-bottom: 0;
            border-bottom: 1px solid $c-grey-e9;
            align-items: center;
        }
    }
}