.KCL_ViewCompanyAssetsReport,
.KCL_ViewIndividualAssetsReport {
    .middle-h4 {
        margin: {
            top: 60px;
            bottom: 10px;
        }
    }

    .current-owned-wrapper {
        display: flex;
        flex-flow: row wrap;

        .owned-item {
            height: 50px;
            width: 25%;
            // Leave a lil space to the right for border gap.
            padding-right: 25px;

            .border-wrapper {
                // Center text vertically
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                // Expand to size of parent.
                height: 100%;
                // Font
                font-size: 15px;
                line-height: 18px;
                // Border
                border-bottom: 1px solid $c-grey-e9;
            }
        }
    }

    .property-card {
        // Spacing between cards
        margin-top: 40px;

        &.first {
            margin-top: 4px;
        }

        .title {
            margin-bottom: 10px;
        }

        .information {
            // Outer spacing
            margin-bottom: 30px;
            // Align in row
            display: flex;
            flex-flow: row nowrap;
            
            .description {
                margin-right: 30px;
            }

            span {
                margin-left: 10px;
            }
        }
    }
}