.KCL_vehicle-profile {
    background-color: $c-grey-f8;

    .shell {
        padding-bottom: 120px;
    }

    .vehicle-report-box {
        margin-bottom: 60px;

        .table-section {
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }
    }
}