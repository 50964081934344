.KCL_sub-navbar {
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $c-dark-04;
    position: fixed;
    width: 100%;
    z-index: 2;

    + .shell {
        padding-top: 50px;
    }

    .list-category {
        a {
            padding: 15px 0;
        }
    }

    > :last-child {
        margin-right: 40px;
    }

    ul {
        padding: 0;
        list-style: none;
        margin-bottom: 0px;
        
        &.itemList {
            margin-left: 15px;

            > :not(:last-child)::after {
                content: "";
                position: absolute;
                top: 15px;
                right: 0;
                width: 1px;
                height: 20px;
                background: rgba(255, 255, 255, 0.3);
            }
        }

        li {
            display: inline-block;
            position: relative;
            line-height: 21px;
            text-align: left;
            
            a {
                display: block;
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .dropdown {
            min-width: 100%;
            display: none;
            position: absolute;
            z-index: 999;
            left: -20px;
            top: 44px;
            background: #fff;
            box-shadow: 0px 2px 30px rgba(34, 53, 223, 0.5);
            border-radius: 6px;
            padding: 22px 0;

            li:not(.list) {
                display: block;

                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: $c-blue-1d;
                    padding: 9px 40px;
                    white-space: pre;
                }
        
                &:hover {
                    background: #F2F5FF;

                    a {
                        color: $c-blue-1d;
                    }
                }
            }

            &.mobile {
                padding: 15px 0;
                top: 27px;
                width: max-content;

                li a {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16.41px;
                    padding: 10px 190px 10px 18px;
                    position: relative;

                    .KCL_label {
                        position: absolute;
                        top: 5px;
                        left: 75px;
                    }
                }

                li.list {
                    width: 100%;

                    > span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        margin-left: 20px;
                        color: #4F5059;
                    }

                    ul.list-items {
                        width: 100%;
                        margin-top: 3px;

                        li a {
                            padding-left: 34px;
                        }
                    }
                }
            }
        }

        .list-category {
            display: block;
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
            color: white;
            padding: 15.5px 20px;
            transition: color .3s;

            .arrow-icon {
                transition: transform .3s;
            }

            &:hover,
            &:focus {
                color: $c-blue-6c;

                .arrow-icon {
                    transform: rotate(180deg);
                }
            }

            &#mobile {
                font-size: 14px;
                line-height: 16px;
                padding: 0;
            }

            .KCL_label {
                position: absolute;
                right: -12px;
                top: 12px;
            }
        }
    }

    ul.focused {
        display: block;
    }

    ul li:hover ul.dropdown {
        display: block;	/* Display the dropdown */
    }

    //toggle hiding mobile menu
    .mobileMenu {
        display: none;
    }

    @include breakpoint-down(tablet) {
        .mobileMenu {
            margin-left: 15px;
            display: block;
        }
        
        .itemList {
            display: none; 
        }
    }
}
