.KCL_listar-Almennt {
    .section--gray {
        padding: 100px 0px 70px;
    }
    
    .section__head--content {
        justify-content: space-between;

        .section__head--right {
            @include breakpoint-up(small-desktop) {
                width: -webkit-fill-available;
                max-width: 666px;
            }
        }

        .stat-boxes {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 4px;

            @include breakpoint-down(mobile) {
                gap: 2px;
            }

            .stat-box {
                padding: 20px 5px 10px;
                width: calc(20% - 4px);

                @include breakpoint-down(small-desktop) {
                    width: auto;
                    flex-grow: 1;
                    max-width: 50%;
                    flex-basis: 30%;
                }

                .stat-box__content {
                    justify-content: space-between;
                    height: -webkit-stretch;

                    .title {
                        color: $c-grey-4f;
                        text-align: center;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;

                        @include breakpoint-down(tablet) {
                            font-size: 10px;
                            line-height: 12px;
                        }

                        @include breakpoint-up(small-desktop) {
                            height: 30px;
                        }
                    }

                    .value {
                        color: $c-dark-23;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 35px;

                        @include breakpoint-down(tablet) {
                            font-size: 24px;
                            line-height: 28px;
                        }
                    }

                    .unit {
                        font-size: 10px;
                        line-height: 12px;
                        font-weight: 700;
                    }
                }
            }
        }
    
        .list-purchased-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .KCL_button {
                margin-right: 0px;
                margin-top: 30px;
            }

            .italic-text {
                margin-top: 15px;
            }
        }
    }
    
    .table-disclaimer {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
    }
}