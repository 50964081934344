.KCL_financial-data {
    .KCL_financial-data-chart {
        display: flex;
        flex-direction: column;
    
        .highcharts-root {
            .highcharts-tooltip-box {
                background: $c-white;
                padding: 13px 20px 19px 16px;
                border-radius: 6px;
                opacity: 0.8;
            }
    
            .chart-tooltip {
                .tooltip-date {
                    font-size: 12px;
                    font-weight: 400;
                }
    
                .tooltip-value {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}