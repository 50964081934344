.KCL_company-owners {
    .owners-boxes-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        &.odd-owners {
            .company-owner-box:first-child {
                flex-basis: 100%;
            }
        }

        .company-owner-box {
            background: $c-grey-f1;
            border-radius: 6px;
            padding: 19px;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 2 1 calc(50% - 2px);
            margin: 1px;
            justify-content: space-around;

            @include breakpoint-down(mobile) {
                flex-basis: 100%;
            }

            .owner-name {
                font-size: 20px;
                line-height: 23px;
                font-weight: 700;
                margin-bottom: 5px;
                text-align: center;
            }

            .owner-info {
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: $c-grey-4f;
                margin-bottom: 5px;
                text-align: center;
            }

            .owner-percentage {
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: $c-grey-4f;
                text-align: center;
            }
        }
    }
}