.KCL_subscription-pricing-table {
    font-size: 16px;

    @include breakpoint-down(mobile) {
        font-size: 13px;
    }

    th {
        text-align: center;
        font-size: 13px;
        text-transform: uppercase;
        color: $c-grey-4f;
        padding: 46px 0 1px;

        @include breakpoint-down(mobile) {
            font-size: 11px;
            padding: 19px 10px 0;
            vertical-align: top;
            line-height: 1.2;
        }
    }

    th:not(:first-child) {
        width: 278px;

        @include breakpoint-down(small-desktop) {
            width: 150px;
        }

        @include breakpoint-down(mobile) {
            width: 88px;
        }
    }

    th:last-child {
        border-radius: 24px 24px 0 0;

        @include breakpoint-down(mobile) {
            border-radius: 14px 14px 0 0;
        }
    }

    td {
        height: 51px;
        border-bottom: 1px solid $c-grey-e9;

        @include breakpoint-down(mobile) {
            line-height: 15px;
            height: auto;
            padding-top: 11px;
            padding-bottom: 11px;
        }

        strong {
            font-size: 18px;

            @include breakpoint-down(mobile) {
                font-size: 14px;
            }
        }
    }

    td:last-child {
        font-weight: 700;
        border-bottom-color: $c-green-c8;
    }

    td:first-child {
        strong {
            @include breakpoint-down(mobile) {
                font-size: 16px;
            }
        }
    }

    tbody tr:first-child td {
        border-bottom: none;
    }

    tbody tr:not(:first-child) td:first-child {
        padding-left: 25px;

        @include breakpoint-down(small-desktop) {
            padding-left: 10px;
        }

        @include breakpoint-down(mobile) {
            padding-left: 15px;
        }
    }

    tbody + tbody tr:first-child td:first-child {
        padding: 33px 0 22px;

        @include breakpoint-down(small-desktop) {
            padding: 22px 0;
        }

        @include breakpoint-down(mobile) {
            padding: 24px 0 10px;
        }
    }

    tfoot {
        td {
            padding: 0;
            height: 15px;
            border: none;
            line-height: 1;

            @include breakpoint-down(mobile) {
                height: 18px;
            }
        }
        
        td:last-child {
            border-radius: 0 0 24px 24px;

            @include breakpoint-down(mobile) {
                border-radius: 0 0 14px 14px;
            }
        }
    }

    .highlight {
        background: $c-green-ef;
    }

    .fa-xmark {
        font-size: 20px;
        color: $c-red-ff7;
    }
}