.KCL_historyButtonDropDown {
    position: absolute;
    top: 0;
    right: 0;

    a {
        text-decoration: none;
    }

    .dropDownContainer {
        position: absolute;
        right: 0;
        width: 150%;
        max-width: 368px;
        max-height: 500px;
        background: #FFFFFF;
        margin-top: -1px;
        box-shadow: 0px 2px 30px 3px rgba(34, 53, 223, 0.5);
        border-radius: 6px;
        overflow: hidden;
        z-index: 1;

        .show-all_btn {
            padding: 15px;
            display: flex;
            justify-content: center;
    
            .KCL_button {
                width: 100%;
                //override mobile styling from original button
                padding: 6px 14px !important;
            }
        }

        @include breakpoint-down(tablet) {
            width: 600%;
        }
    
        @include breakpoint-down(mobile-small) {
            max-width: 280px;
        }
    }

    .box {
        overflow: hidden;

        .all_data {
            overflow-y: auto;
            max-height: 439px;
            background: white;
            border-radius: 6px;
            z-index: 1;

            //SCROLLBAR 
            /* Firefox */
            scrollbar-color: $c-blue-1d $c-blue-d4; /* thumb and track colors */
            scrollbar-width: 15px; /* or auto, depending on your preference */

            /* Chrome and Edge */
            &::-webkit-scrollbar {
                width: 15px; /* Adjust as needed */
            }

            /* Thumb */
            &::-webkit-scrollbar-thumb {
                background: $c-blue-1d;
                border-radius: 9px; 
            }

            /* Thumb Track */
            &::-webkit-scrollbar-track {
                background: $c-blue-d4;
            }

            .box-item {
                padding: 10px 15px;
                text-align: left;

                .box-item__title {
                    margin-bottom: 2px;
                }
                
                .box-item__searchTerm {
                    margin-bottom: 7px;
                }

                &:hover {
                    background-color: $c-teal-f2;
        
                    .box-item__title {
                        color: $c-blue-22;
                    }
                    
                    .box-item__searchTerm {
                        color: $c-blue-22;
                    }
        
                    .box-item__reference {
                        color: $c-blue-22;
                    }
                }
            }

            a:first-child {
                .box-item {
                    padding-top: 25px;
                }
            }
        }
    }
}