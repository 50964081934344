.KCL_legal-search {
    .shell {
        padding-bottom: 100px;
    }

    .filterWrapper {
        margin-top: 10px;
        margin-bottom: 30px;

        .KCL_filter-item {
            margin: 5px 5px 0 0;
        }
    }
}