//Variables
$font-size-base             : 18px;
$font-weight-base           : 400;
$line-height-base           : 1.2;

$ff-sans-serif              : "Roboto", Helvetica, Arial, sans-serif;
$ff-serif                   : Georgia, "Times New Roman", Times, serif;

$t-duration                 : 0.4s;

$shell-max-width            : 1640px;
$mini_shell-max-width       : 1360px;
$shell-gutter               : 15px;

$c-black                    : #000;
$c-white                    : #fff;
$c-base                     : #055590;

$c-blue-04									: #040DE333;
$c-blue-0d									: #0D2350;
$c-blue-1d									: #1D3097;
$c-blue-22									: #2235DF;
$c-blue-22-light							: #2235DF33;
$c-blue-45									: #4569EE;
$c-blue-6c									: #6CD3FF;
$c-blue-6c-light							: #6CD3FF66;
$c-blue-d4									: #D4DEFF;
$c-blue-d5									: #D5DEFF;
$c-grey-4f									: #4F5059;
$c-grey-65									: #65666E;
$c-grey-bd									: #BDBEC1;
$c-grey-d3									: #D3D3D6;
$c-grey-db									: #DBDBDB;
$c-grey-e9									: #E9E9EA;
$c-grey-f1									: #F1F2F5;
$c-grey-f4									: #F4F4F5;
$c-grey-f8									: #F8F8FA;
$c-teal-6c									: #6CD3FF;
$c-teal-f2									: #F2F5FF;
$c-teal-fc									: #FCFDFF;
$c-dark-00									: #0000000A;
$c-dark-04									: #04182A;
$c-dark-07									: #071D3C;
$c-dark-23									: #232530;
$c-dark-39									: #393A45;
$c-green-00									: #005713;
$c-green-03									: #038600;
$c-green-48									: #48A85D;
$c-green-94									: #94EFB3;
$c-green-94-l								: #94EFB380;
$c-green-c2									: #C2FFD7;
$c-green-c8									: #C8E5CE;
$c-green-ef									: #EFF7F0;
$c-green-f7									: #F7FBF7;
$c-green-48									: #48A85D;
$c-green-ee									: #EEFDF3;
$c-red-a7									: #A70027;
$c-red-db									: #DB0033;
$c-red-ffd									: #FFD6E0;
$c-red-ff									: #FFEFF3;
$c-red-ff7									: #FF7697;
$c-yellow-ec								: #EC9718;
$c-yellow-f8								: #F8C513;
$c-yellow-fef8								: #FEF8B9;
$c-yellow-fe								: #FEFBDC;
$c-yellow-ff								: #FFF06B;
$c-purple-f7								: #F7F2FE;
$c-orange-f5								: #F57B23;

$c-blue-gradient							: linear-gradient(97deg, #04182B 14.15%, #0C285F 42.56%, #29175B 76.97%);
$c-pink-gradient							: linear-gradient(155deg, #04182B 14.15%, #EF5F92 250px, #0C285F 400px, #29175B 76.97%);

$responsive-ios             : 812px;

$breakpoints: (
	mobile-small: 374px,
	mobile-smedium: 474px,
	mobile-medium: 575px,
	mobile: 767px,
	tablet: 1023px,
	small-desktop: 1199px,
	medium-desktop: 1439px,
	medium-large-desktop: 1540px,
	large-desktop: 1640px,
	very-large-desktop: 1780px
);
