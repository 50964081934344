.KCL_gjaldeyrismarkadur-page {
    .shell {
        .KCL_tooltip {
            margin: 0 5px;
        }

        .alert-information-section {
            @include breakpoint-down(mobile) {
                margin: 0 15px;
            }

            .KCL_alert {
                .paragraph-small--bold {
                    margin-bottom: 5px;
                }
            }
        }

        .market-depth-tables {
            > :first-child {
                margin-bottom: 26px;

                @include breakpoint-down(tablet) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
