.KCL_shares-page {
    &.main {
        .KCL_chart {
            &.display-box.lg {
                .display-box__head {
                    @include breakpoint-between(mobile-medium, mobile) {
                        margin-bottom: 0;

                        h4 {
                            margin-bottom: -29px;
                            z-index: 2;
                        }
                    }

                    @include breakpoint-between(mobile, tablet) {
                        margin-bottom: 0;

                        h4 {
                            margin-bottom: -33px;
                            z-index: 2;
                        }
                    }
                    
                    @include breakpoint-up(medium-desktop) {
                        margin-bottom: 0;

                        h4 {
                            margin-bottom: -33px;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
}