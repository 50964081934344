.KCL_calendarWeekdays {
    &.calendar-row {
        max-height: 35px;
        min-height: 35px !important;
        height: 35px;

        @include breakpoint-down(mobile) {
            display: none;
        }
    }
    .calendar-cell.weekday_cell {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
        background: $c-grey-f1;
        .weekday {
            color: $c-dark-23;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.52px;
            text-transform: uppercase; 
        }
        &:not(:first-child) {
            border-left: none;
        }
        &:hover {
            z-index: 0;
            border-color: $c-grey-e9;
        }
    }

}