
/* ------------------------------------------------------------ *\
    Drop Select
\* ------------------------------------------------------------ */
.KCL_drop-select-error-message {
    font-size: 12px;
    color: $c-red-db;
    line-height: 1.25;
    display: block;
    padding: 10px 0 0 15px;
}
.KCL_drop-select {
    position: relative;
    color: $c-grey-65;
    border-radius: 6px;
    font-size: 16px;
    
    &.has-error {
        .drop-select__label {
            color: $c-red-db;
        }
        i {
            color: $c-red-db;
        }
        &:hover .drop-select__trigger {
            border-color: $c-red-db;
        }
         .drop-select__trigger {
            border-color: $c-red-db;
        }
    }
    &.xs {
        width: 100%;
        min-width: 100px;

        .drop-select__trigger {
            // Make more compact for table head.
            height: 25px;
            padding: {
                top: 0px;
                left: 12px;
                right: 12px;
                bottom: 0px;
            }
        }

        .drop-select__overlay {
            font-size: 13px;

            .drop-select__inner {
                padding-top: 0px;
                max-height: 240px;
                overflow-y: auto;

                .drop-select__item {
                    padding: 5px;
                    line-height: 20px;
                    white-space: break-spaces;
                }
            }

            //SCROLLBAR 
            /* Firefox */
            scrollbar-width: 5px; /* or auto, depending on your preference */
    
            /* Chrome and Edge */
            &::-webkit-scrollbar {
                width: 5px;
            }
    
            /* Thumb */
            &::-webkit-scrollbar-track {
                border-radius: 5px;
            }
    
            /* Thumb Track */
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
            }
        }
    }

    &.sm {
        .drop-select__overlay {
            //SCROLLBAR 
            /* Firefox */
            scrollbar-width: 5px; /* or auto, depending on your preference */
    
            /* Chrome and Edge */
            &::-webkit-scrollbar {
                width: 5px;
            }
    
            /* Thumb */
            &::-webkit-scrollbar-track {
                border-radius: 5px;
            }
    
            /* Thumb Track */
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
            }
        }
    }

    &.lg {
        &.page-size-select {
            width: 145px;
        }

        .drop-select__trigger {
            height: 45px;
            font-size: 16px;
        }

        &.has-value .drop-select__label {
            font-size: 11px;
            margin-top: -28px;
            
            &:before {
                background: #fff;
            }
        }

        .drop-select__overlay {
            //SCROLLBAR 
            /* Firefox */
            scrollbar-width: 15px; /* or auto, depending on your preference */
    
            /* Chrome and Edge */
            &::-webkit-scrollbar {
                width: 15px;
            }
    
            /* Thumb */
            &::-webkit-scrollbar-track {
                border-radius: 9px;
            }
    
            /* Thumb Track */
            &::-webkit-scrollbar-thumb {
                border-radius: 9px;
            }
        }
    }

    .drop-select__trigger {
        background: #fff;
        padding: 5px 15px;
        height: 30px;
        font-size: 13px;
        color: inherit;
        width: 100%;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        border-radius: 6px;
        border: 1px solid $c-grey-bd;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: color .3s, background .3s, border .3s, border-radius .3s;

        @include breakpoint-down(mobile) {
            padding: 5px 10px;
        }
        

        > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            margin-right: 5px;
        }

        i {
            margin-left: auto;
        }
    }

    .drop-select__label {
		font-size: 16px;
		position: absolute;
		top: 50%;
		left: 12px;
        line-height: 1;
		margin-top: -8px;
		pointer-events: none;
		color: inherit;
		padding: 0 3px;
        font-weight: 400;
		transition: font-size .3s, margin .3s;

        span {
			position: relative;
			z-index: 2;
		}

		&:before {
			content: '';
			height: 1px;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			background: transparent;
			z-index: 1;
		}
	}

    .drop-select__overlay {
        position: absolute;
        max-height: 400px;
        overflow-y: auto;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 5;
        box-shadow: 0px 4px 4px rgba(4, 13, 227, 0.2);
        border-radius: 0 0 6px 6px;
        background: #fff;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        border: 1px solid $c-grey-bd;
        border-top: none;
        transition: opacity .3s, visibility .3s, border .3s;

        @include breakpoint-down(mobile) {
            max-height: 300px;
        }

        //SCROLLBAR 
        /* Firefox */
        scrollbar-color: $c-blue-1d $c-blue-d4; /* thumb and track colors */

        /* Thumb */
        &::-webkit-scrollbar-track {
            background: $c-teal-f2;
        }

        /* Thumb Track */
        &::-webkit-scrollbar-thumb {
            background: $c-blue-1d;

            &:hover {
                background: $c-blue-04;
            }
        }
    }

    .drop-select__inner {
        padding: 8px 0;
        display: flex;
        flex-flow: column nowrap;
    }

    .drop-select__item {
        appearance: none;
        width: 100%;
        border: none;
        border-radius: 0;
        background: transparent;
        padding: 7px 15px;
        align-items: center;
        cursor: pointer;
        color: $c-dark-23;
        transition: background .3s;
        text-align: left;
        line-height: 26px;

        &:hover,
        &:focus,
        &.is-selected {
            color: $c-blue-22;
            background: $c-teal-f2;
            font-weight: 700;
        }
    }

    &.is-open .drop-select__open,
    .drop-select__open:hover {
        color: $c-blue-22;
        background: transparent;
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(4, 13, 227, 0.2);

        .drop-select__trigger {
            border-color: $c-blue-22;
        }

        .drop-select__overlay {
            border-color: $c-blue-22;
        }
    }

    &.is-open {
        box-shadow: 0px 4px 4px rgba(4, 13, 227, 0.2);

        &:not(.has-value) {
            .drop-select__label {
                color: $c-dark-23 !important;
            }
        }

        .drop-select__trigger {
            color: $c-dark-23;
            background: #fff;
            border-radius: 6px 6px 0 0;
            // border-bottom-color: transparent !important;
            border-bottom: none;
        }

        .drop-select__overlay {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
    
    &.has-value .drop-select__label {
		font-size: 11px;
		margin-top: -20px;

        &:before {
            background: #fff;
        }
	}

    &.disabled {
        cursor: default;

        span, i, .drop-select__label {
            color: $c-grey-bd;
        }

        .drop-select__trigger {
            border: 1px solid $c-grey-bd;
            background: $c-grey-f1;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

/* ------------------------------------------------------------ *\
    Drop Select Checks
\* ------------------------------------------------------------ */

.drop-select--checks {
    .list-checks {
        list-style: none;
        padding-left: 0;
        li {
            padding: 10px 15px;
            color: $c-dark-23;

            @include breakpoint-down(mobile) {
                padding: 8px 10px;
            }
        }
    }

    .drop-select__inner {
        padding: 0;
    }

    .drop-select__label {
        left: 0;
        padding-left: 15px;
        padding-right: 30px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include breakpoint-down(mobile) {
        .checkbox .checkbox__label {
            font-size: 14px;
            line-height: 1.25;
        }
    }
}

/* ------------------------------------------------------------ *\
    Drop Select Search
\* ------------------------------------------------------------ */

.drop-select--search {
    .drop-select__trigger {
        &::placeholder {
            color: $c-dark-23;
        }
        
        &:focus {
            &::placeholder {
                color: $c-grey-bd;
            }
        }
    }
    
    i {
        position: absolute;
        right: 10px;
        top: 13px;
        cursor: pointer;
        background: white;
        padding: 0 2px;
    }

    &.disabled {
        i {
            background: $c-grey-f1;
        }
    }

    .drop-select__overlay {
        mark {
            background-color:$c-yellow-ff;
            padding: 0 1px;
  
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 162.5% */ 

            mark {
                padding: 0px ;
            }
        }
    }
}