.KCL_flag {
    width: 25px;
    height: 25px;
    
    span.grayBackground {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $c-grey-f4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    img {
        border-radius: 50%;
        pointer-events: none;
    }
    
}