.KCL_company-assets {
    .KCL_label {
        text-transform: uppercase;
    }
}

.KCL_company-assets-tab {
    .buy-current-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 3px;

        &.large {
            height: 50px;
            border-bottom: 1px solid $c-grey-e9;
        }

        @include breakpoint-down(mobile) {
            font-size: 13px;
        }
    }

    .KCL_alert, .Loading {
        margin-top: 10px;
    }

    .owned-report-values {
        font-size: 15px;
        line-height: 18px;

        @include breakpoint-down(mobile) {
            font-size: 13px;
        }

        .owned-report-head-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 38px;

            @include breakpoint-down(mobile) {
                margin-bottom: 10px;
            }
        }

        .owned-row {
            margin-bottom: 8px;

            @include breakpoint-down(mobile) {
                margin-bottom: 19px;
            }
        }
    }

    &:not(.large) {
        .owned-report-values {
            margin-bottom: -33px;

            @include breakpoint-down(mobile) {
                margin-bottom: -31px;
            }
        }
    }
}