.KCL_slider {
    position: relative;

    .slider__button {
        appearance: none;
        border: none;
        border-radius: 50%;
        background: $c-teal-f2;
        color: $c-blue-45;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        position: absolute;
        top: 50%;
        margin-top: -35px;
        z-index: 1;
        transition: box-shadow .3s;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        }

        @include breakpoint-down(tablet) {
            width: 50px;
            height: 50px;
            margin-top: -25px;
        }
    }

    .slider__prev {
        left: 0;
    }

    .slider__next {
        right: 0;
    }

    /* ------------------------------------------------------------ *\
        Slider Screenshots
    \* ------------------------------------------------------------ */

    &.slider-screenshots {
        padding: 26px 85px 0;
        max-width: 1550px;
        margin: 0 auto;

        @include breakpoint-down(large-desktop) {
            padding: 26px 70px 0;
        }

        @include breakpoint-down(tablet) {
            padding: 25px 60px 0;
        }

        @include breakpoint-down(mobile) {
            padding: 5px 0 0;
            margin: 0 -15px -13px;
            
            .slider__actions {
                display: none;
            }

            .swiper-container {
                padding: 0 90px;
            }
        }

        .swiper-slide {
            padding: 13px;

            @include breakpoint-down(small-desktop) {
                padding: 13px 5px;
            }

            @include breakpoint-down(mobile) {
                padding: 13px 9px;
            }
        }

        .slide-image {
            border-radius: 6px;
            background: #C4C4C4;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            cursor: pointer;

            &:hover {
                box-shadow: 0px 2px 13px 3px rgba(34, 53, 223, 0.5);
            }

            img {
                width: 100%;    
            }
        }
    }
}