.KCL_vextir-sedlabanka {
    .stat-boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px;

        .stat-box {
            width: calc(20% - 4px);
            min-height: 110px;
            
            @include breakpoint-down(mobile) {
                width: auto;
                flex-grow: 1;
                max-width: 50%;
                flex-basis: calc(33.33% - 4px);
            }
        }
    }
}