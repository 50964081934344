.KCL_report--example {
    background: none !important;
    
    .modal-header {
        margin-bottom: 10px;
        
        .example-label {
            padding: 8px 20px;
            color: $c-dark-23;
            background: $c-yellow-ff;
            border-radius: 50px;
            margin-bottom: 20px;
        }
    }

    .modal-body {
        padding-right: 2px !important;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: $c-teal-f2;
            border-radius: 5px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: $c-blue-1d;
            border-radius: 5px;

            &:hover {
                background: $c-blue-04;
            }
        }

        .KCL_alert.info {
            margin-bottom: 0 !important;
        }

        .radios-group {
            ul {
                display: flex;
                column-gap: 10px;

                li {
                    margin-bottom: 10px;
                }

                @include breakpoint-down(mobile) {
                    flex-direction: column;
                }
            }
        }
    }

    .KCL_view-company-compare {
        @include breakpoint-down(medium-desktop) {
            div.graph.pieChart-container {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr); 
                gap: 40px;
            }
        }
    }

    &.KCL_property-page {
        .property-page-body .property-report-box {
            margin-bottom: 0;
        }

        .KCL_alert.info {
            margin-top: 60px;
        }
    }
}