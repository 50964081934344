.KCL_Auglysingar {
    padding-bottom: 100px;

    @include breakpoint-down(mobile) {
        padding-bottom: 60px;
    }

    .section__body {
        display: flex;
        flex-direction: column;
        gap: 100px;
        align-items: flex-start;
        h2:not(:first-child) {
            margin-top: 100px;
        }

        .adsItem {
            width: 100%;
            display: flex;
            gap: 45px;

            .left-section {
                flex: 2;
                flex-basis: 806px;
                max-width: 806px;

                h6.description{
                    margin-bottom: 20px;
                }
            }

            img.ad-img{
                flex: 1;
                align-self: flex-start;
            }

            @include breakpoint-down(tablet) {
                flex-direction: column;
                gap: 30px;

                .left-section {
                    flex-basis: 100%;
                    max-width: 100%;
                }
                .right-section {
                    margin: 0 auto;
                }
            }
        }
    }
}
