.KCL_tradables-changes {
    .change-item {
        border-radius: 6px;
        font-size: 15px;
        margin-bottom: 3px;
        padding: 13px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 700;
            color: $c-dark-23;
        }

        .numbers {
            display: flex;

            .nom {
                width: 80px;
            }
        }

        &.pos {
            background: $c-green-c8;

            .KCL_colored-number.pos {
                color: $c-green-00;
            }
        }

        &.neg {
            background: $c-red-ff;
        }
        
        &.neutral {
            background: $c-grey-f1;
        }
    }
}