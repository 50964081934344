.KCL_sign-up-form {
    &.user-exists,
    &.error-message {
        background: none;
        padding: 0;

        .KCL_alert {
            margin-bottom: 40px;
        }

        .signup-error__actions {
            display: flex;
            justify-content: space-between;
            
            .user-exists__actions {
                .KCL_link {
                    &:first-child {
                        margin-right: 30px;
                    }
                }
            }
        }
    }

    .form__section {
        .form__col--cvc {
            display: flex;

            .KCL_input {
                width: -webkit-stretch;
            }
        }

        .password-info-and-generator {
            display: flex;
            justify-content: space-between;

            @include breakpoint-down(tablet) {
                flex-direction: column;
            }

            .password-info {
                display: flex;
                max-width: 80%;

                .icon-fa {
                    margin-right: 8px;
                    margin-top: 5px;
                }

                @include breakpoint-down(tablet) {
                    max-width: none;
                }
            }

            .KCL_button {
                align-self: flex-start;

                @include breakpoint-down(tablet) {
                    margin-left: 22px;
                    margin-top: 10px;
                }
            }
        }
    }

    .form__group--consent {
        background: #fff;
        border-radius: 6px;
        padding: 17px 20px;

        @include breakpoint-down(mobile) {
            padding: 22px 20px 27px;
        }

        .field-error-message {
            padding-left: 0;
        }

        .KCL_checkbox {
            .checkbox__label {
                .KCL_link {
                    @include breakpoint-down(mobile) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .form__group--discount {
        .KCL_alert {
            margin-top: 10px;
        }
    }

    .form__actions {
        position: relative;

        .KCL_link {
            position: absolute;
            left: -11px;
            top: 16px;
        }
    }

    .form__additional-info {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 30px 40px;
        margin: -13px 0 25px;

        @include breakpoint-down(tablet) {
            padding: 20px;
        }

        p {
            margin-bottom: 20px;
        }

        .KCL_link {
            &:not(:last-child) {
                margin-right: 30px;
            }

            .icon-fa {
                font-size: 18px;
                color: $c-blue-45;
            }
        }
    }

    .confirmation-step,
    .final-step {
        .form__section {
            margin-bottom: 40px;
            margin-top: 30px;

            dl {
                font-size: 16px;
                line-height: 26px;

                dt {
                    font-weight: 400;
                    display: inline-block;
                    width: 20%;
                }
                
                dd {
                    display: inline-block;
                    margin-right: 16px;

                    &:first-child {
                        margin-left: 16px;
                    }
                }
            }
        }
    }

    .addon-prices {
        .addon-item {
            float: right;
        }
    }

    .sign-up-step--2 {
        p {
            line-height: 28px;
        }
    }
}