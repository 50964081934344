.KCL_about-page {
    .section--gray {
        .section__body {
            gap: 20px 26px;

            .display-box {
                width: 50%;
            }
        }
    }
}