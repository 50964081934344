/* ------------------------------------------------------------ *\
    Events Calendar
\* ------------------------------------------------------------ */

.KCL_calendar-page {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 0 100px;

    @include breakpoint-down(tablet) {
        padding: 0 0 60px;
    }

    .KCL_ad-rotator.ads1360x180 {
        margin-top: -34px;
        margin-bottom: 50px;
    }

    .events-calendar__header {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint-down(tablet) {
            flex-wrap: wrap;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 26px;
        }
    }

    .events-calendar__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .calendar-cell {
            padding: 10px 10px 20px 10px;
            position: relative;
            overflow: hidden;
            max-height: 100%;
            background: #fff;
            border: 1px solid $c-grey-e9;
            transition: background 0.3s, zIndex .3s;

            &:not(:last-child) {
                margin-right: -1px;
            }

            @include breakpoint-down(mobile) {
                border: none;
                outline: solid 1px $c-grey-e9;
                padding: 10px 10px 20px;
                &:hover {z-index: 0 !important;}
                // &.has-events .cell__date { margin-bottom: 7px}
            }
        }

        .calendar-cell:hover {
            border-color: $c-blue-d5;
            z-index: 1;

            @include breakpoint-down(mobile) {
                background: #fff;
            }
        }

        //blue bubble over date if it's the same date as today
        .selected {
            .cell__date {
                width: 40px;
                height: 40px;
                background: $c-blue-45;
                color: #fff;
                border-radius: 50%;
                justify-content: center;

                @include breakpoint-down(tablet) {
                    width: 30px;
                    height: 30px;
                }
                
                @include breakpoint-down(mobile) {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                }
            }
        }
        //date is disabled if itðs not in the same month as the selected month
        .disabled {
            pointer-events: none;
            .cell__date {
                color: $c-grey-bd;
            }
            @include breakpoint-down(mobile) {
                display: none;
            }

            span,
            .list-events {
                opacity: 0;
                visibility: hidden;
            }
        }

        .calendar-col {
            width: calc(100% / 7);
            flex: 1 1 calc(100% / 7);

            @include breakpoint-down(mobile) {
                width: 100%;
                flex: 0 0 100%;
            }
        }

        .calendar-row {
            flex: 1 1 100%;
            min-height: 120px;
            margin-bottom: -1px;

            @include breakpoint-down(mobile) {
                flex: 0 0 auto;
                min-height: 0;
                margin: 0;
                overflow: visible;
            }
        }

        .cell__date {
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background .3s, color .3s;
            width: 40px;
            height: 40px;
            @include breakpoint-down(tablet) {
                width: 30px;
                height: 30px;
                margin-left: 5px;
            }
        }
    }

    .calendar-row {
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        @include breakpoint-down(mobile) {
            flex-direction: column;
        }
    }

    .list-events {
        list-style: none;
        margin-top: 7px;

        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            @include breakpoint-down(tablet) {
                margin: 0 5px 15px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }

        }
    }

    .events-calendar__month {
        display: flex;
        align-items: center;

        @include breakpoint-down(tablet) {
            width: 100%;
            margin-bottom: 22px;
        }
    }

    .events-calendar__month-name {
        color: $c-dark-23;
        margin-left: 23px;
        text-transform: capitalize;

        @include breakpoint-down(tablet) {
            font-size: 40px;
        }

        @include breakpoint-down(mobile) {
            font-size: 34px;
            margin-left: 6px;
        }
    }

    .events-calendar__btn {
        font-size: 20px;
        appearance: none;
        border: none;
        border-radius: 0;
        background: transparent;
        width: 40px;

        @include breakpoint-down(mobile) {
            width: 30px;
            font-size: 18px;
        }

        &:hover {
            color: $c-blue-45;
        }
    }

    .drop-select--year {
        min-width: 115px;

        @include breakpoint-down(mobile) {
            min-width: 120px;
        }
    }

    .drop-select--month {
        min-width: 165px;
        margin-left: 20px;
        text-transform: capitalize;

        @include breakpoint-down(mobile) {
            flex-grow: 1;
        }
    }

    .events-calendar__date-changer {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include breakpoint-down(mobile) {
            margin-left: 0;
            width: 100%;
        }
    }
}