.KCL_reportsPage {
    .section_upper {
        padding-top: 0px;
        padding-bottom: 0px;
        
        .section_upper-body {
            .KCL_small-search {
                width: 100%;
                max-width: 945px;
            }

            .KCL_search-results {
                margin-top: 100px;
            }
        }
    }

    .section_lower {
        padding-top: 0px;
        padding-bottom: 100px;
    }

    .section--primary {
        padding-top: 0px;
    }

    .section__body {
        margin-top: 60px;

        .content-space-between {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > h2 {
                margin-bottom: 0px;
            }

            > p {
                margin-bottom: 0px;
            }

            &:nth-child(2) {
                margin-top: 10px;
                margin-bottom: 40px;
            }
        }
    }

    .list-title {
        margin-top: 50px
    }

    ul {
        margin-top: 30px;
        max-width: 1254px;
        columns: 3;

        @include breakpoint-down(tablet) {
            columns: 2;
        }

        @include breakpoint-down(mobile-medium) {
            columns: 1;
        }
    }
}
