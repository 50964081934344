.KCL_blurred-company-info-box {
    background: $c-grey-f1;
    border-radius: 6px;
    padding: {
        top: 10px;
        bottom: 14px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        margin-bottom: 5px;
        max-width: 95%;
        text-align: center;
    }

    .buttons {
        display: flex;
        gap: 15px;
    }
}