.KCL_colored-number {
    width: 100%;
    height: 100%;
    display: flex;
    margin-right: 0px;
    max-width: 100% !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;

    &.display-inline {
        display: inline;
    }

    &.pos {
        color: $c-green-03;
    }

    &.neg {
        color: $c-red-db;
    }

    &.neutral {
        color: $c-dark-23;
    }

    .arrow {
        margin-right: 6px;
    }

    &.constant-background-pos {
        background-color: $c-green-f7;
    }

    &.constant-background-neg {
        background-color: $c-red-ff;
    }

    &.background-pos {
        animation-name: positiveChange;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;

    }

    &.background-neg {
        animation-name: negativeChange;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}

@keyframes positiveChange {
    0% {
        background-color: $c-green-f7;
    }
    75% {
        background-color: $c-green-f7;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes negativeChange {
    0% {
        background-color: $c-red-ff;
    }
    75% {
        background-color: $c-red-ff;
    }
    100% {
        background-color: transparent;
    }
}

.align-left {
    .KCL_colored-number {
        justify-content: flex-start;
    }
}
