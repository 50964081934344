.toastContainer {
    position: fixed;
    top: 130px;
    right: 10px;
    z-index: 99;

    .toast {
        border-radius: 6px;
        
        .KCL_alert {
            margin: 0;
        }
    }
}