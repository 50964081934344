.KCL_company-search-response {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    font-size: 16px;
    color: $c-dark-23;
    box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1), -10px 10px 20px -10px rgba(0, 0, 0, 0.1);
    // Border radius on bottom to match the top of the Search component.
    border-radius: 0 0 10px 10px;
    outline: 1px solid $c-grey-bd;
  
    @include breakpoint-down(small-desktop) {
        font-size: 13px;
    }
  
    &.is-open {
        display: block;
        z-index: 1;
    }
  
    // container element of results, that has scrollbar.
    .search-response__scroll {
        max-height: 388px;
        overflow-y: auto;
        padding-bottom: 10px;
        
        //SCROLLBAR 
        /* Firefox */
        scrollbar-color: $c-blue-1d $c-blue-d4; /* thumb and track colors */
        scrollbar-width: 15px; /* or auto, depending on your preference */

        /* Chrome and Edge */
        &::-webkit-scrollbar {
            width: 15px; /* Adjust as needed */
        }

        /* Thumb */
        &::-webkit-scrollbar-thumb {
            background: $c-blue-1d;
            border-radius: 9px; 
        }

        /* Thumb Track */
        &::-webkit-scrollbar-track {
            background: $c-blue-d4;
            border-radius: 9px;
        }
    
        // Heading of each search category
        .search-response__heading {
            padding: 10px 60px;
            background-color: $c-grey-f1;
            border-top: 1px solid $c-grey-e9;
            font-size: 13px;
            color: $c-grey-4f;
            text-transform: uppercase;
            font-weight: 700;
        
            @include breakpoint-down(small-desktop) {
                padding: 10px 38px;
                font-size: 11px;
            }
    
            &.error {
                background: $c-red-ff;
                border-color: $c-red-ffd;

                .error-msg {
                    text-transform: none;
                    font-weight: 400;
                    margin-left: 10px;
            
                    .icon {
                        color: $c-red-db;
                        margin-right: 5px;
                    }
                }
            }
        }
    
        // Each search item element under a specific category.
        .search-response__text {
            padding: 0px;
            cursor: pointer;
            border-top: 1px solid $c-grey-e9;

            &:hover {
                border-color: $c-blue-d4;

                & + .search-response__text {
                    border-color: $c-blue-d4; /* Change the border color of adjacent siblings when hovering */
                }
            }

            button {
                background: none;
                border: none;
                color: #000;
                text-align: left;

                padding: 10px 60px;
                width: 100%;
                height: 100%;
                display: inline-block;
                text-decoration: none;
                transition: background .3s, color .3s, border .3s;
        
                @include breakpoint-down(small-desktop) {
                    padding: 10px 38px;
                }
        
                // 
                mark {
                    background-color:$c-yellow-ff;
                    padding: 0 1px;
        
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px; /* 162.5% */

                    mark {
                        padding: 0px ;
                    }
                }
        
                &:focus,
                &:hover {
                    background: $c-teal-f2;
                    color: $c-blue-1d;
                    border-color: $c-blue-d5;
                    z-index: 1;
                
                    mark {
                        color: inherit
                    }
                }
            }
    
            a {
                padding: 10px 60px;
                width: 100%;
                height: 100%;
                display: inline-block;
                text-decoration: none;
                transition: background .3s, color .3s, border .3s;
        
                @include breakpoint-down(small-desktop) {
                    padding: 10px 38px;
                }
        
                mark {
                    background-color:$c-yellow-ff;
                    padding: 0 1px;

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px; /* 162.5% */

                    mark {
                        padding: 0px ;
                    }
                }
        
                &:focus,
                &:hover {
                    background: $c-teal-f2;
                    color: $c-blue-1d;
                    border-color: $c-blue-d5;
                    z-index: 1;
                
                    mark {
                        color: inherit
                    }
                }
            }
        }
    }
}  