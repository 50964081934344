/* ------------------------------------------------------------ *\
    Radio
\* ------------------------------------------------------------ */

.radio {
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    input {
        appearance: none;
        border: none;
        border-radius: 0;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: hidden;
    }

    .radio__label {
        position: relative;
        display: flex;
        align-items: center;
        user-select: none;
        color: $c-dark-23;
        transition: background .3s, color .3s;
    }

    .radio__icon {
        position: relative;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        border: 1px solid $c-grey-bd;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 22px;
            line-height: 0;
            opacity: 0;
            transition: opacity .3s;
        }
    }

    input:checked ~ .radio__label {
        color: $c-blue-22;
    }

    &.is-disabled {
        pointer-events: none;

        .radio__label {
            color: $c-grey-bd;

            .radio__icon {
                background: $c-grey-f1;
            }
        }

        input:checked ~ .radio__label {
            color: $c-blue-d4;
        }
    }

    input:checked ~ .radio__label .radio__icon span {
        opacity: 1;
    }
}

/* ------------------------------------------------------------ *\
    Radios Group
\* ------------------------------------------------------------ */

.radios-group {
    ul {
        list-style: none;
        column-gap: 5px;

        li {
            break-inside: avoid;
            margin-bottom: 5px;
        }
    }
}