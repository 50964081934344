.text-image-block-list {
    display: flex;

    .KCL_text-image-block {
        flex-grow: 1;
        flex-basis: 0;
        margin: 0;

        &.no-image {
            .text-image-block__body {
                align-self: flex-start;
            }
        }
    }    

    @include breakpoint-down(tablet) {
        margin: 0 -15px;
    }

    @include breakpoint-down(mobile) {
        flex-direction: column;
        padding-top: 8px;
    }
}

/* ------------------------------------------------------------ *\
    Text image block
\* ------------------------------------------------------------ */

.KCL_text-image-block {
    display: flex;
    padding-bottom: 100px;
    margin: 0 -13px;

    @include breakpoint-down(large-desktop) {
        margin: 0;
    }

    @include breakpoint-down(tablet) {
        margin: 0 -15px;
    }

    @include breakpoint-down(mobile) {
        padding-bottom: 60px;
        flex-direction: column;
    }

    .text-image-block__media-holder,
    .text-image-block__body {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 13px;

        @include breakpoint-down(large-desktop) {
            padding: 0 15px;
        }
    }

    .text-image-block__media-holder {
        @include breakpoint-down(mobile) {
            padding: 0;
            margin-bottom: 30px;
        }
    }

    .text-image-block__media {
        border-radius: 6px;
        padding-top: 55.65%;

        @include breakpoint-down(mobile) {
            border-radius: 0;
        }
    }

    .text-image-block__body {
        align-self: center;
    }

    .text-image-block__content {
        max-width: 616px;
        margin: 0 53px;

        @include breakpoint-down(tablet) {
            margin: 0;
        }
    }

    .text-image-block__entry {
        line-height: 1.56;

        &:not(:last-child) {
            margin-bottom: 30px;

            @include breakpoint-down(mobile) {
                margin-bottom: 22px;
            }
        }

        p:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .text-image-block__buttons {
        > a:not(:last-child),
        > .KCL_button:not(:last-child) {
            margin-right: 20px;
        }
    }

    &.image-right {
        .text-image-block__media-holder {
            order: 5;

            @include breakpoint-down(mobile) {
                order: -1;
            }
        }

        .text-image-block__content {
            margin-left: auto;
        }
    }

    .KCL_button {
        margin-left: 0;
    }

    .image-fit {
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-position: center;
    
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.section__body {
    .shell {
        .KCL_text-image-block:first-child {
            @include breakpoint-down(mobile) {
                padding-top: 0;
            }
        }
    }
}