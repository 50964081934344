.KCL_company-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;
    min-height: 103.78px;

    .info-col {
        border-left: 1px solid $c-grey-e9;
        padding-left: 20px;
        padding-right: 5px;
        margin-bottom: 25px;

        @include breakpoint-down(medium-desktop) {
            margin-right: 0;
            flex-basis: 0%;
        }

        @include breakpoint-down(small-desktop) {
            flex-basis: 25%;
        }

        @include breakpoint-between(mobile, small-desktop) {
            &:nth-child(4n+1) {
                border-left: none;
                padding-left: 0;
            }
        }

        @include breakpoint-down(mobile) {
            flex-basis: 50%;

            &:nth-child(2n) {
                border-left: none;
                padding-left: 0;
            }
        }

        &:first-child {
            flex-direction: row;
            gap: 0;
            border: none;
            align-items: center;

            @include breakpoint-up(small-desktop) {
                padding-left: 0;
            }
            @include breakpoint-down(mobile) {
                flex-basis: 100%;
                padding-left: 0;
            }
        }

        .info-item {
            height: 50px;
            
            @include breakpoint-down(tablet) {
                height: auto;
            }

            .value {
                &.companyName {
                    font-size: 24px;
                    line-height: 28px;
                }

                &.isatCategory {
                    max-width: 200px;
                }
            }
        }
    }

    .KCL_company-awards {
        max-width: 200px;
        
        @include breakpoint-down(small-desktop) {
            display: flex;
            justify-content: center;
            width: 100%;
            max-width: none;
        }

        img {
            max-height: 103.78px;
        }
    }

    &.mini {
        justify-content: unset;
        min-height: auto;
        margin-top: 20px;

        .info-col {
            border-left: none;
            margin-bottom: 15px;

            .info-item .value.isatCategory {
                max-width: none;
            }
        }
    }
}
