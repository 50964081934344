.KCL_errorPage {
    min-height: 600px;
    height: 100%;
    //pink-ribbon
    // background: $c-pink-gradient;
    background: $c-blue-gradient;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 2;
    color: white;
    padding: 5%;
    overflow-x: hidden;

    @include breakpoint-down(tablet) {
        flex-direction: column-reverse;
    }

    h1, .summary-paragraph, .status-code {
        color: $c-white;
    }

    .errorPage-leftBox {
        flex: 1;

        .errorPage-message {
            > p:first-child {
                margin-bottom: 10px;
            }

            > p:last-child {
                margin-bottom: 50px;
            }
        }
    }

    .errorPage-rightBox {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        margin-top: 80px;

        @include breakpoint-down(tablet) {
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;
        }

        .status-code {
            font-size: 300px;
            font-weight: 700;
            position: absolute;

            @include breakpoint-down(tablet) {
                font-size: 150px;
            }

            &.errorPage-errorStatusCode1 {
                opacity: 0.9;
            }

            &.errorPage-errorStatusCode2 {
                margin-left: 60px;
                margin-top: -35px;
                opacity: 0.3;
            }

            &.errorPage-errorStatusCode3 {
                margin-left: 120px;
                margin-top: -70px;
                opacity: 0.2;
            }
        }
    }
}