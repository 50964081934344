.KCL_pep-search {
    overflow: unset;
    padding-bottom: 100px;

    .descriptionWrapper {
        margin-bottom: 40px;
        max-width: 946px;
    }

    h6 {
        .icon-fa {
            margin-left: 8px;
        }
    }
}

#pepResults.results {
    margin-bottom: 40px;

    .noResultText {
        margin: 0px;
        padding: 16px 0px 16px 5px;
        border-bottom: 1px solid $c-grey-e9;
    }
}