.KCL_slider-input {
    display: inline-block;
    padding-top: 12px;
    width: -webkit-fill-available;

    input {
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 15px;
            height: 38px;
            border-radius: 6px;
            background: $c-blue-1d;
            cursor: default;
        }

        &::-moz-range-thumb {
            width: 15px;
            height: 38px;
            border-radius: 6px;
            background: $c-blue-1d;
            cursor: default;
        }

        border-radius: 6px;
        height: 15px;
        width: 100%;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
    }

    .end-values {
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        color: $c-grey-65;
        padding: 5px 5px 0;

        .low-value {
            float: left;
        }
        
        .high-value {
            float: right;
        }
    }

    &.sm {
        .end-values {
            font-size: 11px;
        }
    }
}

.KCL_slider-variable-unit {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 3;

    @include breakpoint-between(tablet, small-desktop) {
        flex-wrap: wrap;
    }

    @include breakpoint-down(mobile-medium) {
        flex-wrap: wrap;
    }
    
    .input-and-button {
        display: flex;
        flex-flow: row nowrap;

        @include breakpoint-between(tablet, small-desktop) {
            width: 100%;
            justify-content: flex-end;
            margin-top: 18px;
        }
    
        @include breakpoint-down(mobile-medium) {
            width: 100%;
            justify-content: flex-end;
            margin-top: 18px;
        }
        
        .slider-input {
            font-size: 15px;
            margin-left: 30px;
            margin-right: 20px;
            display: inline-flex;
            width: 150px;
            align-items: center;
            position: relative;
    
            .percent-symbol {
                position: absolute;
                right: 15px;
                color: $c-grey-65;
            }
        }
    }
    
}