.KCL_gemmaq-page {
    .section__body {
        .tabs__head {
            @include breakpoint-down(mobile) {
                padding-right: 0;
            }
        }

        .tabs__body {
            padding-bottom: 480px;

            @include breakpoint-down(mobile) {
                padding-bottom: 100px;
            }

            .publishing-message {
                display: flex;
                justify-content: center;
                border: 1px solid $c-grey-d3;
                border-radius: 6px;
                padding: 20px;
                align-items: center;
                background: $c-white;
                margin: 5px auto;
                
                @include breakpoint-down(mobile) {
                    flex-direction: column;
                }

                h4 {
                    margin-right: 30px;
                }
            }
        }

        .KCL_table {
            width: 50%;

            @include breakpoint-down(mobile) {
                width: 100%;
            }

            table {
                th:first-child, td:first-child:not(.chart-small) {
                    @include breakpoint-down(mobile) {
                        width: 180px !important;
                    }
                }

                thead {
                    th {
                        @include breakpoint-down(mobile) {
                            &:nth-child(2) {
                                padding-right: 5px;
                            }
                        }
                    }
                }

                tbody {
                    tr.average-row {
                        td {
                            font-weight: 700;
                        }
                    }

                    td.hideME {
                        display: none;
                    }

                    tr.clickable-row {
                        cursor: pointer;

                        td.chart {
                            position: absolute;
                            border-top: none;
                            border-right: none;
                            margin-top: -3px;
                            padding-left: 0;
                            width: calc(50% - 15px);
                            max-width: 820px;

                            @include breakpoint-down(mobile) {
                                display: none;
                            }
                        }

                        .padding-cell {
                            width: 26px;

                            @include breakpoint-down(mobile) {
                                display: none;
                            }
                        }
                    }

                    tr.rowSelected {
                        background: $c-white;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

                        td {
                            @include breakpoint-up(mobile) {
                                border-bottom: none !important;
                            }

                            &:first-child {
                                border-top-left-radius: 6px;

                                @include breakpoint-up(mobile) {
                                    border-bottom-left-radius: 6px;
                                }
                            }

                            @include breakpoint-down(mobile) {
                                &:nth-child(3) {
                                    border-top-right-radius: 6px;
                                }
                            }
                        }

                        .KCL_GEMMAQ-data {
                            width: 100%;
                            background: $c-white;
                            border-top-left-radius: 0;
                            box-shadow: 0px 2px 0 rgb(0 0 0 / 10%);

                            .display-box__head {
                                display: block;
                                text-align: center;

                                h4 {
                                    display: block;
                                }
                            }

                            .display-box__body {
                                text-align: center;

                                .KCL_GEMMAQ-chart {
                                    text-align: center;
                                    width: 100%;

                                    .highcharts-container {
                                        width: 100% !important;
                                        text-align: center !important;
                                    }
                                }

                                .buttons {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 15px;

                                    @include breakpoint-down(medium-desktop) {
                                        margin-top: 20px;
                                    }

                                    @include breakpoint-down(mobile) {
                                        margin-top: 40px;
                                    }
                                }

                                .entry__previous-date {
                                    margin-top: 30px;
                                    text-align: center;
                                    color: $c-grey-4f;
                                    font-size: 12px;

                                    .entry {
                                        padding-right: 15px;
                                        border-right: 1px solid $c-grey-d3;
                                    }

                                    .previous {
                                        padding-left: 15px;
                                    }
                                }
                            }
                        }
                    }

                    .chartInMinimumView {
                        .chart-small {
                            padding: 0;
                            border: none;
                            max-width: 1vw;

                            @include breakpoint-up(mobile) {
                                display: none;
                            }
                        }
                    }

                    tr.near__rowSelected {
                        td {
                            border-bottom: none;
                        }
                    }

                    tr:last-child {
                        td.chart {
                            margin-top: -440px;
                        }
                    }
                }
            }
        }
    }
}
