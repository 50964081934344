.KCL_areidanleikakannanir-page {
    .section--contact {
        form {
            max-width: 560px;
            padding-top: 5px;
        }

        .KCL_price-card {
            max-width: 530px;
            margin-top: 40px;

            .price-card__content {
                margin-top: 12px;
            }

            .price-card__details {
                div.content-wrapper {
                    padding-left: 10px;
                }

                dl {
                    column-gap: 30px;
                }
                
                dd {
                    white-space: pre; 
                }
            }
        }
    }
}