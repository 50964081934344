.KCL_price-range {
    margin: 7px 10px 5px;
    min-width: 80px;

    input {
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 6px;
            height: 16px;
            border-radius: 6px;
            background: $c-grey-65;
            cursor: default;
        }

        &::-moz-range-thumb {
            width: 6px;
            height: 16px;
            border-radius: 6px;
            background: $c-grey-65;
            cursor: default;
        }

        border-radius: 6px;
        height: 6px;
        width: 100%;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
    }

    .end-values {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;

        .low-value {
            float: left;
        }
        
        .high-value {
            float: right;
        }

        @include breakpoint-down(mobile) {
            font-size: 10px;
            line-height: 12px;
        }
    }

    &.sm {
        .end-values {
            font-size: 11px;
        }
    }
}