.KCL_news-by-markets {
    .news-row {
        border-bottom: 1px solid $c-grey-e9;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;
        padding-bottom: 5px;

        .name-and-time {
            font-size: 13px;
            color: $c-grey-4f;
            padding-left: 10px;

            .source {
                margin-right: 5px;
                white-space: nowrap;
            }
        }

        @include breakpoint-down(mobile-medium) {
            flex-direction: column;
            height: auto;
            align-items: flex-start;
            padding-top: 10px;
            padding-bottom: 7px;

            .KCL_link {
                max-width: 100%;
            }

            .name-and-time {
                padding-left: 0px;
                font-size: 11px;
                margin-top: 5px;
                display: flex;
                flex-direction: row-reverse;

                .time {
                    padding-right: 20px;
                }
            }
        }
    }
}