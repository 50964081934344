/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: calc( #{$shell-max-width} + #{$shell-gutter} * 2 );
	padding-left: $shell-gutter;
	padding-right: $shell-gutter;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
.mini_shell {
	max-width: calc( #{$mini_shell-max-width} + #{$shell-gutter} * 2 );
	padding-left: $shell-gutter;
	padding-right: $shell-gutter;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
