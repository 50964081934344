.KCL_hagtolur-chart {
    display: flex;
    flex-direction: column;

    .info-bubble {
        display: none;
        
        &.is-shown {
            display: block;
            width: fit-content;
            align-self: flex-end;
            margin-bottom: -60px;
            z-index: 10;

            .KCL_alert {
                width: fit-content;

                @include breakpoint-down(mobile) {
                    margin-bottom: 50px;
                }
            }
        }
    }

    .highcharts-root {
        .highcharts-tooltip-box {
            background: $c-white;
            padding: 13px 20px 19px 16px;
            border-radius: 6px;
            opacity: 0.8;
        }

        .chart-tooltip {
            .tooltip-date {
                font-size: 12px;
                font-weight: 400;
            }

            .tooltip-value {
                font-size: 14px;
                font-weight: 500;
            }
        }

        .highcharts-reset-zoom {
            .highcharts-button-box {
                border: 1px solid $c-blue-22;
                stroke: $c-blue-22;
                fill: $c-white;
            }

            text {
                fill: $c-blue-22 !important;
                font-size: 16px;
                //overwriting inline style from Highcharts 
                font-weight: 700 !important;
            }

            &:hover {
                .highcharts-button-box {
                    stroke: $c-blue-1d;
                }

                text {
                    fill: $c-blue-1d !important;
                }
            }

            &:focus {
                .highcharts-button-box {
                    outline: 4px solid $c-blue-d4;
                }
            }
        }
    }
}