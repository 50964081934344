.KCL_company-compare {
    .company-compare-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.large {
            height: 50px;
            border-bottom: 1px solid $c-grey-e9;
        }
        
        .company-compare-line-date {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: $c-dark-23;
        }
    }

    .example-row {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
    }
}