.KCL_shareholders {
    .shareholders-legend {
        margin-top: 30px;
        
        .legend-item {
            .color {
                width: 8px;
                height: 18px;
                border-radius: 2px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-left: 7px;
            }
            
            .text {
                font-size: 14px;
                color: $c-dark-23;
            }
        }
    }
}