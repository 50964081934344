.KCL_vaktarinn-page {
    .section__head--title {
        object {
            margin-top: -16px;

            @include breakpoint-down(mobile) {
                margin-top: -10px;
            }
        }
    }

    .section--contact {
        form {
            max-width: 560px;
            padding-top: 5px;
        }

        .KCL_price-card {
            max-width: 438px;
            margin-top: 40px;

            .price-card__content {
                margin-top: 12px;
            }
        }
    }
    
    .form__body {
        margin-bottom: 45px;
    }
}