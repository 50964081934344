/* ------------------------------------------------------------ *\
    Grid Items
\* ------------------------------------------------------------ */

.grid-items {
    margin: 0 -13px;

    @include breakpoint-down(tablet) {
        margin: 0;
    }

    .grid-item {
        margin-bottom: 26px;

        @include breakpoint-down(tablet) {
            margin-bottom: 15px;
        }
    }

    .grid-item--1of2 {
        width: 50%;
    }

    .grid-item--2of3 {
        width: 66.66%;
    }

    .grid-item--1of3 {
        width: 33.33%;
    }

    .grid-item--3of4 {
        width: 75%;
    }

    .grid-item--1of4 {
        width: 25%;
    }

    .grid-item--table-full {
        @include breakpoint-down(small-desktop) {
            width: 100%;
        }
    }
}

.grid-items--flex {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
        display: flex;

        @include breakpoint-down(tablet) {
            margin-bottom: 15px;
        }
    }

    .grid-items {
        width: calc(100% + 26px);
    }

    .display-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        height: fit-content;
    }
}

/* ------------------------------------------------------------ *\
    Grid Item
\* ------------------------------------------------------------ */

.grid-item {
    padding: 0 13px;

    @include breakpoint-down(tablet) {
        padding: 0;
    }

    .ad {
        margin-bottom: 26px;

        @include breakpoint-down(tablet) {
            margin-bottom: 15px;
        }
    }

    .display-box + .ad {
        margin-top: 26px;

        @include breakpoint-down(tablet) {
            margin-top: 15px;
        }
    }

    .grid-item {
        margin-bottom: 26px;

        @include breakpoint-down(tablet) {
            margin-bottom: 15px;
        }
    }
}