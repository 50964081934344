.KCL_tradables-information {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;

    @include breakpoint-down(tablet) {
        margin-left: 10px;
    }

    @include breakpoint-down(mobile) {
        margin-bottom: 0;
    }

    .info-col {
        position: relative;
        padding: 3px 50px 3px 0;
        margin-bottom: 20px;
        
        @include breakpoint-down(mobile) {
            padding: 3px 8px;
            margin-bottom: 26px;
        }

        &:not(:last-child) {
            margin-right: 15px;

            @include breakpoint-down(mobile) {
                width: 33.33%;
                margin-right: 0;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 2px;
                background: #fff;
                border-left: 1px solid $c-grey-e9;
            }
        }

        &:nth-child(3n) {
            @include breakpoint-down(mobile) {
                &:before {
                    content: none;
                }
            }
        }

        .info-item {
            height: 42px;
        }
    }

    &.tradables-information--bonds {
        .info-col {
            @include breakpoint-up(tablet) {
                width: calc(16.67% - 15px);
            }
            
            @include breakpoint-between(mobile, tablet) {
                width: calc(25% - 15px);
            }
        }
    }
}