.KCL_tabs {
    @include breakpoint-down(mobile) {
        .tabs-nav::-webkit-scrollbar {
            display: none;
        }
    }

    .tabs-nav {
        white-space: nowrap;
        border-bottom: 1px solid $c-grey-d3;
        min-width: fit-content;

        ul {
            display: flex;
            list-style: none;
            border-bottom: none;
            font-weight: 400;
            padding: 0;
        }
    
        li {
            margin-bottom: -1px;
        }
    
        button {
            position: relative;
            appearance: none;
            border: none;
            border-radius: 0;
            background: transparent;
            color: $c-grey-65;
    
            &:hover {
                color: $c-blue-1d;
            }

            &:focus {
                &:after, .tab-underline {
                    background: $c-blue-d4;
                }
            }
    
            .tab-underline {
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                background: transparent;
                width: 100%;
            }
    
            @include breakpoint-down(mobile) {
                font-size: 13px;
            }
        }

        .label-and-twinkle {
            display: flex;
            flex-flow: row nowrap;
        }

        .tab-twinkle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $c-yellow-f8;
            margin: {
                left: 2px;
                top: 5px;
            }
        }
    
        .is-active {
            button {
                font-weight: 700;
                color: $c-dark-23;
    
                &:after, .tab-underline {
                    background: $c-teal-6c;
                }

                &:focus {
                    &:after, .tab-underline {
                        background: $c-blue-d4;
                    }
                }
            }
    
            &:hover button {
                color: $c-blue-1d;
            }
        }
    }
    
    .tab {
        display: none;
    }

    .is-active {
        display: block;
    }

    &.sm {
        .tabs-nav {
            padding-bottom: 1px;

            ul {
                font-size: 15px;
                line-height: 18px;
            }

            li:not(:last-child) {
                margin-right: 20px;
        
                @include breakpoint-down(mobile) {
                    margin-right: 15px;
                }
            }

            button {
                padding-bottom: 9px;

                &::after,  .tab-underline {
                    height: 5px;
                }
            }
        }

        .tabs__body {
            padding-top: 15px;
        }
    }

    &.lg {
        .tabs__head {
            overflow-x: auto;
            overflow-y: hidden;
    
            //SCROLLBAR
            scrollbar-width: thin;
            scrollbar-color: $c-blue-1d $c-blue-d4; /* thumb and track colors */
    
            &::-webkit-scrollbar {
                height: 5px;
            }
    
            /* Thumb */
            &::-webkit-scrollbar-track {
                border-radius: 5px;
                background: $c-teal-f2;
            }
    
            /* Thumb Track */
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: $c-blue-1d;
    
                &:hover {
                    background: $c-blue-04;
                }
            }
        }

        .tabs-nav {
            padding-bottom: 1px;

            ul {
                font-size: 18px;
                line-height: 21px;
            }

            li:not(:last-child) {
                margin-right: 30px;
        
                @include breakpoint-down(mobile) {
                    margin-right: 15px;
                }
            }

            button {
                padding-bottom: 12px;

                &::after, .tab-underline {
                    height: 6px;
                }
            }
        }

        .tabs__body {
            padding-top: 40px;

            @include breakpoint-down(mobile) {
                padding-top: 20px;
            }
        }
    }
}