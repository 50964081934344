#Gagnaleit-pricing {
    padding-bottom: 40px;

    @include breakpoint-down(mobile) {
        padding-bottom: 20px;
    }

    .section__head {
        padding-bottom: 30px;

        h2 {
            margin-bottom: -17px;

            @include breakpoint-down(mobile) {
                text-align: center;
                margin-bottom: 0;
            }
        }
    }

    .swiper-wrapper {
        align-items: center;

        .swiper-slide {
            padding: 0px 13px;

            @include breakpoint-down(mobile) {
                padding: 0 5px 20px;
            }
        }
    }

    .subscription-plans {
        margin-bottom: 30px;

        @include breakpoint-down(mobile) {
            margin: 0 -15px 75px;
        }
    }

    .subscription-plans-table {
        font-size: 16px;
        margin-top: -20px;
        margin-bottom: 100px;
        
        @include breakpoint-down(mobile) {
            font-size: 13px;
            margin-bottom: 60px;
        }
            
        th {
            text-align: center;
            font-size: 13px;
            text-transform: uppercase;
            color: $c-grey-4f;
            padding: 14px 5px 12px;
            border-bottom: none;

            &::before {
                display: none;
            }
        
            @include breakpoint-down(mobile) {
                font-size: 11px;
                padding: 11px 0 0;
            }
        }
        
        th:not(:first-child) {
            width: 278px;
        
            @include breakpoint-down(small-desktop) {
                width: 150px;
            }
    
            @include breakpoint-down(mobile) {
                width: 88px;
            }
        }
        
        td {
            height: 51px;
            border-bottom: 1px solid $c-grey-e9;
        
            @include breakpoint-down(mobile) {
                height: 37px;
                padding: 7px 6px;
            }
        }
        
        td:not(:first-child) {
            .flex {
                justify-content: center;
            }
        }
    }

    .section__footnote {
        margin-top: 15px;

        .paragraph-small--italic {
            color: $c-grey-65;

            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}