.KCL_company-profile {
    background-color: $c-grey-f8;

    .grid-items {
        .grid-item {
            float: left;
            margin-bottom: 0;

            &.grid-item--1of3 {
                float: right;
            }

            .display-box {
                margin-bottom: 26px;
            }

            @include breakpoint-down(small-desktop) {
                &.grid-item--2of3 {
                    width: 60%;

                    .grid-item--1of2 {
                        width: 100%;
                    }
                }

                &.grid-item--1of3 {
                    width: 40%;
                }
            }

            @include breakpoint-down(tablet) {
                &.grid-item--2of3 {
                    width: 100%;
                }
                
                &.grid-item--1of3 {
                    width: 100%;
                }
            }
        }
    }
}