.KCL_company-watch-button {
    height: fit-content;
    width: fit-content;
    border: 1px solid $c-yellow-ff;
    color: $c-yellow-ff;
    background-color: $c-dark-04;

    span {
        white-space: pre;
    }

    &.watching {
        background-color: $c-yellow-ff;
        color: $c-dark-23;
    }

    &.disabled {
        border: 1px solid $c-grey-bd;
        color: $c-grey-bd;
        cursor: default;
        padding: 4px 12px;

        > span {
            display: flex;
            align-items: center;

            > .KCL_lock-icon {
                margin-right: 5px;
            }
        }
    }

    .rotate {
        animation: rotation 2s infinite linear;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}