// The text element that triggers the tooltip.
.KCL_tooltip {
    width: auto;
    transition: color .3s, border-color .3s;

    .display-text {
        color: $c-blue-1d;
        
        &:not(.no-underline) {
            border-bottom: 2px dashed $c-blue-d4;
        }
    }

    &:hover {
        .display-text {
            color: $c-blue-22;
            border-color: $c-blue-45;
        }
    }
}

// The tooltip box itself.
.KCL_react-tooltip {
    opacity: 1 !important;
    max-width: 370px;
        
    .invisible-content {
        max-width: 370px;
        font-size: 14px;
        line-height: 22px;
    }
    
    .tooltip-content-wrapper {
        max-width: 370px;
        font-size: 14px;
        line-height: 22px;
    }

    @include breakpoint-down(mobile) {
        max-width: 345px;

        .invisible-content {
            max-width: 345px;
        }

        .tooltip-content-wrapper {
            max-width: 345px;
        }
    }

    .tooltip-inner {
        position: relative;
        background-color: transparent;
        width: max-content;
        max-width: 370px;

        @include breakpoint-down(mobile) {
            max-width: 345px;
        }

        .invisible-content {
            // Setting all kinds of styles so that the invisible content emulates the size of the real content.
            padding: 15px;
            width: max-content;
            text-align: left;
            visibility: hidden;
        }

        .tooltip-content-wrapper {
            position: absolute;
            bottom: 15px;
            // Size overrides.
            width: max-content;
            padding: 15px;
            // Color overrides.
            background-color: $c-white;
            color: $c-dark-23;
            // Other styles overrides.
            border-radius: 6px;
            box-shadow: 0px 1px 15px 3px rgba(34, 53, 223, 0.3);
            // Font and text overrides.
            text-align: left;
            // Put content over arrow.
            z-index: 4;
            // Animations:
            animation: fade-tooltip .2s ease-out;
            transition: opacity .2s ease-out;
            opacity: 0; // OPACITY IS GIVEN AT BOTTOM OF FILE
        }
    }

    .tooltip-arrow {
        z-index: 10;

        &::before {
            top: -15px !important;
            left: -0.7rem !important;
            border-width: 1.4rem 1.4rem 0 !important;
            border-top-color: $c-white !important;
        }
    }

    // ANIMATIONS AND TRANSITIONS
    &.show {
        .tooltip-inner {
            .tooltip-content-wrapper {
                opacity: 1;
            }
        }
    }
}

@keyframes fade-tooltip {
    0% { opacity: 0; }
    // 50% { opacity: .3 }
    100% { opacity: 1; }
}

.KCL_tooltipTemp.KCL_react-tooltip{
    .tooltip-arrow {
        display: none !important;
    }
}