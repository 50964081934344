.KCL_membership-search {
    .shell {
        padding-bottom: 100px;
    }

    form {
        .form__body {
            .form__row {
                &.nameSearchWrapper {
                    max-width: 430px;
                }

                .searchInput {
                    &:not(.smallInput) {
                        flex-grow: 1;
                    }

                    &.smallInput {
                        max-width: 324px;
                    }
                }

                .searchButton {
                    .KCL_button {
                        margin: 0;
                    }
                }

                @include breakpoint-down(mobile) {
                    .searchInput {
                        &.smallInput {
                            max-width: 100%;
                        }
                    }

                    &.nameSearchWrapper {
                        max-width: 100%;
                    }
                }
            }
        }

    }
}