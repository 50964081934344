.KCL_company-compare-choose {
    display: flex;
    flex-direction: column;
    gap: 60px;

    .display-box {
        .display-box__head {
            @include breakpoint-down(tablet) {
                flex-direction: column;
                align-items: flex-start;

                .display-box__head-aside {
                    width: 100%;
                }
            }

            .aside-component {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .KCL_compare-search {
                    min-width: 390px;
                    margin-right: 70px;

                    @include breakpoint-down(mobile-medium) {
                        min-width: 200px;
                        margin-right: 10px;
                    }
                    @include breakpoint-between(mobile-medium, tablet) {
                        margin-right: 20px;
                    }
                }

                .aside-infoWrapper {
                    display: flex;
                    align-items: center;

                    @include breakpoint-down(mobile-medium) {
                        max-width: 150px;
                    }

                    p.italic-text {
                        margin: 0;
                        margin-right: 20px;
                    }
                }
            }
        }

        .icon {
            font-weight: 900;
            font-size: 16px;
            line-height: 16px;
        }
    }
}