.KCL_chart {
    display: flex;
    flex-direction: column;
    padding-left: 0 !important;

    .no-data-info {
        margin: 50px 50px 40px 50px;
    }

    .highcharts-root {
        .highcharts-tooltip-box {
            background: $c-white;
            padding: 13px 20px 19px 16px;
            border-radius: 6px;
            opacity: 0.8;
        }

        .chart-tooltip {
            .tooltip-date {
                font-size: 12px;
                font-weight: 400;
            }

            .tooltip-value {
                font-size: 14px;
                font-weight: 500;
            }
        }

        .highcharts-reset-zoom {
            .highcharts-button-box {
                border: 1px solid $c-blue-22;
                stroke: $c-blue-22;
                fill: $c-white;
            }

            text {
                fill: $c-blue-22 !important;
                font-size: 16px;
                font-weight: 700;
            }

            &:hover {
                .highcharts-button-box {
                    stroke: $c-blue-1d;
                }

                text {
                    fill: $c-blue-1d !important;
                }
            }

            &:focus {
                .highcharts-button-box {
                    outline: 4px solid $c-blue-d4;
                }
            }
        }

        .highcharts-range-selector-buttons {
            .highcharts-button {
                .highcharts-button-box {
                    fill: $c-teal-f2;
                }
                text {
                    fill: $c-blue-1d !important;
                    font-size: 14px;
                    font-weight: 400;

                    @include breakpoint-down(mobile) {
                        font-size: 11px;
                    }
                }
                &:hover {
                    .highcharts-button-box {
                        fill: $c-blue-d4;
                    }
                }
                &.highcharts-button-pressed {
                    .highcharts-button-box {
                        fill: $c-blue-d4;
                    }
                    text {
                        font-weight: 500 !important;
                    }
                }
            }
        }

        .highcharts-navigator {
            .highcharts-navigator-handle {
                fill: $c-blue-1d;
                stroke: none;
            }
            .highcharts-navigator-mask-outside {
                stroke: $c-grey-e9;
                stroke-width: 0.5px;
            }
        }
    }

    .chart {
        select {
            option:disabled {
                display: none;
            }
        }
    }

    a.label-wrap {
        text-decoration: none;
        float: right;
    }

    .display-box__head {
        margin-left: 20px;

        @include breakpoint-down(mobile) {
            margin-left: 15px;
        }
    }

    &.chart--bonds {
        @include breakpoint-down(mobile) {
            padding-right: 0 !important;
        }
    }
}

.chart-display-box {
    padding-left: 0 !important;

    .display-box__head {
        margin-left: 20px;

        @include breakpoint-down(mobile) {
            margin-left: 15px;
        }
    }

    .display-box__body {
        .KCL_tabs {
            .tabs__head {
                margin-left: 20px;

                @include breakpoint-down(mobile) {
                    margin-left: 15px;
                }
            }
        }
    }
}