.KCL_modal {
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.shaded-inside {
        &.has-close-button {
            .modal-dialog {
                padding-right: 95px;

                @include breakpoint-down(mobile) {
                    // Shrink padding.
                    padding-right: 65px;
                }
            }
        }

        .modal-dialog {
            // Reduce padding with shading inside.
            padding: 50px;

            @include breakpoint-down(mobile) {
                // Shrink padding.
                padding: 25px;
            }

            .modal-content {
                .modal-header {
                    .btn-close {
                        position: absolute;
                        top: -5px;
                        right: -50px;

                        @include breakpoint-down(mobile) {
                            // Bring closer
                            top: 0px;
                            right: -35px;
                        }
                    }
                }

                .modal-body {
                    border-radius: 24px;
                    background-color: $c-grey-f1;
                    padding: 50px;

                    @include breakpoint-down(mobile) {
                        // Shrink padding and curve.
                        padding: 25px;
                        border-radius: 12px;
                    }
                }
            }
        }
    }

    &:not(.KCL_report--example) {
        .modal-dialog {
            max-width: 1130px !important;
            width: 60% !important;

            @include breakpoint-down(mobile) {
                // Shrink padding.
                width: 95% !important;
            }
        }
    }

    &.KCL_report--example {
        .modal-dialog {
            max-width: 1640px !important;
            width: 90% !important;
            max-height: 90%;

            @include breakpoint-down(mobile) {
                // Shrink padding.
                width: 95% !important;
            }
        }
    }

    &.login-confirm-modal {
        .form__body {
            max-width: 860px;
            margin-bottom: 25px;

            .form__col:last-child {
                flex-grow: unset;
            }
        }
    }

    .modal-dialog {
        // Size
        height: auto;
        // Spacing
        padding: {
            top: 40px;
            right: 60px;
            bottom: 80px;
            left: 80px;
        }
        // Visual style:
        background: $c-white;
        box-shadow: 0px 2px 30px 3px $c-blue-22;
        border-radius: 24px;
        // Font:
        color: $c-dark-23;
        font-size: 18px;
        line-height: 28px;

        @include breakpoint-down(mobile) {
            // Shrink padding.
            padding: 30px;
            width: 95% !important;
            font-size: 16px;
            line-height: 24px;
        }

        .modal-content {
            border: none;
            overflow: visible;

            .modal-header {
                padding: 20px 0px 0px;
                border: none;
                margin-bottom: 15px;

                &.no-header {
                    height: 0px;
                    padding: 0;
                    margin: 0;
                }

                .modal-title {
                    align-self: flex-end;
                    margin-top: 20px;
                }

                .btn-close {
                    width: 5px;
                    height: 5px;
                    opacity: 1;
                    color: $c-dark-23;
                    box-shadow: none;
                    margin: 0;
                    align-self: flex-start;

                    &:hover {
                        cursor: pointer;
                        opacity: .75;
                    }

                    @include breakpoint-down(mobile) {
                        // Move close modal (X) closer inside.
                        top: 5px;
                        right: 5px;
                    }
                }

                @include breakpoint-down(mobile) {
                    padding: 0;
                    margin-bottom: 10px;

                    .modal-title {
                        margin-top: 10px;
                    }
                }
            }

            .modal-body {
                padding: 0px;
                max-height: calc(90vh - 200px);

                .infoSection {
                    margin-bottom: 30px;
                }

                .VerdskraLink {
                    margin-left: 4px;
                }

                form {
                    width: calc(100% - 10px);

                    .form__actions {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;

                        &.text__center {
                            justify-content: center;
                        }

                        .KCL_link {
                            height: fit-content;
                        }
                    }
                }
            }
        }
    }
}