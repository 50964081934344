.KCL_search {
    position: relative;
    height: 39px;
    margin: 0px;
    max-width: 450px;
    padding-left: 39px;
    background-color: #fff;
    border-radius: 10px;

    &:focus-within, &:hover {
        box-shadow: 0px 2px 10px 2px rgba(34, 53, 223, 0.5);
    }

    &:hover form ::placeholder{
        color: $c-dark-23;
    }

    // Searchinput shrinks in small navbars.
    @include breakpoint-down(tablet) {
        height: 35px;
    }

    &.is-search-response-open {
        // Remove the bottom border-radius since the input field is expanded.
        border-radius: 10px 10px 0px 0px;
    }

    &.is-dropdown-open {
        // Remove the bottom border-radius since the input field is expanded.
        border-radius: 10px 10px 0px 10px;
    }

    // The container of:
    // - magnifying glass icon button
    // - search field itself
    // - and the dropdown for search type
    form {
        display: flex;
        justify-content: space-between;

        ::placeholder {
            color: $c-grey-65;
            opacity: 1;
        }

        // Input field wrapper
        .field-control {
            flex: 1 1 auto;

            // The input field it self.
            .search-field {
                // Border radius
                border-radius: 10px;
                // Size
                height: 39px;
                width: 100%;
                // Font size.
                font-size: 16px;
                line-height: 19px;
                // Style
                border: 0;
                color: $c-dark-23;

                &::placeholder {
                    text-overflow: ellipsis;
                }

                &:focus {
                    &::placeholder {
                        color: $c-grey-bd;
                    }
                }

                @include breakpoint-down(mobile) {
                    border-radius: 0 10px 10px 0;
                }

                @include breakpoint-down(tablet) {
                    height: 35px;
                    font-size: 13px;
                }
            }
        }

        // Search button (magnifying glass icon)
        .search__submit {
            // Align with form.
            position: absolute;
            left: 0;
            top: 0;
            // Size of button.
            width: 39px;
            height: 100%;
            background: none;
            border: 0;
            cursor: pointer;
            // Center the magnifying glass icon.
            display: flex;
            align-items: center;
            justify-content: center;
        
            span {
                // Fix center alignments
                padding-left: 5px;
                
                // Size of magnifying glass icon.
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}
